
// import '../styling/home.css'
import React, { useEffect, useState, useRef } from 'react';
import FilterOption from './FilterOption';
import * as constants from '../../../constants';
import CollapsibleFilter from './CollapsibleFilter';
import AppliedFilter from './AppliedFilters';
import RangeFilter from './FilterTypes/RangeFilter';
import MultipleFilter from './FilterTypes/MultipleFilter';
import TextFilter from './FilterTypes/TextFilter';
import DateRangeFilter from './FilterTypes/DateRangeFilter';
import RadioFilter from './FilterTypes/RadioFilter';
import DropdownFilter from './FilterTypes/DropdownFilter';
import LocationFilter from './FilterTypes/LocationFilter';
import CompanyTypeFilter from './FilterTypes/CompanyTypeFilter';
import SICFilter from './FilterTypes/SICFilter';
import RangeFilterDropdown from './FilterTypes/RangeFilterDropdown';
import CompanyNameFilter from './FilterTypes/CompanyNameFilter';
import MultipleTextFilter from './FilterTypes/MultipleTextFilter';
import { Mixpanel } from '../../Mixpanel/Mixpanel';
import { auth } from '../../..';

interface AdvancedSearchProps {
    appliedFilters: any,
    createExcel: any,
    search: any,
    companyJSONList: any,
    removeAppliedFilter: any,
    setAppliedFilter: any,
    applyFilter: any,
    approx?: any,
    setApprox?: any,
    addressChoice: any,
    setAddressChoice: any,
    removeAll: any,
    hideExportedP: any,
    hideExportedChanged: any,
    plan: any,
}

const AdvancedSearch: React.FC<AdvancedSearchProps> = ({ appliedFilters, createExcel, search, companyJSONList, removeAppliedFilter, setAppliedFilter, applyFilter, approx, setApprox, addressChoice, setAddressChoice, removeAll, hideExportedP, hideExportedChanged, plan }) => {

    let filterOptions: any = constants.FILTER_OPTIONS

    const [selectedFO, setSelectedFO] = useState(filterOptions[0].options[0])

    const [showKeyFilters, setShowKeyFilters] = useState(true)
    const [showSAF, setShowSAF] = useState(false)
    const [showCompanyF, setShowCompanyF] = useState(false)
    const [showFinancialsF, setShowFinancialsF] = useState(false)
    const [showPeopleF, setShowPeopleF] = useState(false)
    const [showSavedF, setShowSavedF] = useState(false)


    const [openFilter, setOpenFilter] = useState(null)

    const [appliedLength, setAppliedLength] = useState(0)

    const appliedRef = useRef<any>()

    useEffect(() => {
        if (appliedFilters.length > appliedLength) {
            appliedRef.current?.scrollIntoView({ behavior: 'smooth' });
        }

        if (appliedFilters.length !== appliedLength) {
            setAppliedLength(appliedFilters.length)
        }

    }, [appliedFilters.length, appliedLength]);

    // #region CONSTANTS & STATE VARIABLES


    // #endregion


    // #region SHOW COMPONENTS
    const addFilters = () => {
        let compArray: any = []

        compArray.push(<FilterOption filter={filterOptions[0]} open={showKeyFilters} opened={foOpened} selection={selectedFO} selectClick={foSelected} key={0} />)

        if (plan.includes("plus")) { compArray.push(<FilterOption filter={filterOptions[1]} open={showSAF} opened={foOpened} selection={selectedFO} selectClick={foSelected} key={1} />) }
        compArray.push(<FilterOption filter={filterOptions[2]} open={showCompanyF} opened={foOpened} selection={selectedFO} selectClick={foSelected} key={2} />)
        compArray.push(<FilterOption filter={filterOptions[3]} open={showFinancialsF} opened={foOpened} selection={selectedFO} selectClick={foSelected} key={3} />)
        compArray.push(<FilterOption filter={filterOptions[4]} open={showPeopleF} opened={foOpened} selection={selectedFO} selectClick={foSelected} key={4} />)


        let user = JSON.parse(localStorage.getItem("user") || '{}')
        let savedFilters = user.savedFilters
        filterOptions[5].options[0].values = savedFilters
        compArray.push(<FilterOption filter={filterOptions[5]} open={showSavedF} opened={foOpened} selection={selectedFO} selectClick={foSelected} key={5} />)

        return compArray
    }


    const showFilters = () => {

        let compArray: any = []


        if (selectedFO.options !== undefined) {
            selectedFO.options.forEach((option: any) => {

                if (option.id !== "0A1") {
                    compArray.push(<CollapsibleFilter
                        filter={option}
                        addedFilters={appliedFilters}
                        open={openFilter !== null && openFilter === option.id}
                        openClicked={cfOpened}
                        apply={applyFilter}
                        approx={approx}
                        setApprox={setApprox}
                        plan={plan}
                        key={option.id}
                    />)
                }



            });

        }
        else {
            compArray.push(getCorrectFilterType(selectedFO))
        }

        return compArray



    }

    const getCorrectFilterType = (filter: any) => {


        if (filter.type === "range") {
            if (filter.isRevenue) {

                return <RangeFilter filter={filter} addedFilters={appliedFilters} apply={applyFilter} approx={approx} setApprox={setApprox} key={filter.id} />
            }
            else {
                return <RangeFilterDropdown filter={filter} addedFilters={appliedFilters} apply={applyFilter} key={filter.id} />
            }

        }

        if (filter.type === "multiple") {
            if (filter.subType === "companyType") {
                return <CompanyTypeFilter filter={filter} addedFilters={appliedFilters} apply={applyFilter} key={filter.id} />
            }
            else if (filter.subType === "sic") {
                return <SICFilter filter={filter} addedFilters={appliedFilters} isic={filter.id === '2C2'} apply={applyFilter} clear={() => removeAppliedFilter(filter, "")} key={filter.id} />
            }

            else if (filter.subType === "companyName") {
                return <CompanyNameFilter filter={filter} addedFilters={appliedFilters} apply={applyFilter} addressChoice={addressChoice} setAddressChoice={setAddressChoice} clear={() => removeAppliedFilter(filter, "")} key={filter.id} />
            }
            else if (filter.subType === "location") {
                return <LocationFilter filter={filter} addedFilters={appliedFilters} apply={applyFilter} addressChoice={addressChoice} setAddressChoice={setAddressChoice} clear={() => removeAppliedFilter(filter, "")} key={filter.id} />
            }
            else if (filter.subType === "text") {
                return <MultipleTextFilter filter={filter} addedFilters={appliedFilters} apply={applyFilter} clear={() => removeAppliedFilter(filter, "")} key={filter.id} />
            }
            else {
                return <MultipleFilter filter={filter} addedFilters={appliedFilters} apply={applyFilter} savedFilters={(filters: any) => setAppliedFilter(filters)} plan={plan} key={filter.id} />
            }

        }

        if (filter.type === "text") {
            return <TextFilter filter={filter} addedFilters={appliedFilters} apply={applyFilter} key={filter.id} />
        }

        if (filter.type === "dateRange") {
            return <DateRangeFilter filter={filter} addedFilters={appliedFilters} apply={applyFilter} key={filter.id} />
        }

        if (filter.type === "radio") {
            return <RadioFilter filter={filter} addedFilters={appliedFilters} apply={applyFilter} key={filter.id} />
        }

        if (filter.type === "dropdown") {
            return <DropdownFilter filter={filter} addedFilters={appliedFilters} apply={applyFilter} key={filter.id} />
        }



    }

    const showAppliedFilters = () => {

        let compArray: any = []

        appliedFilters.forEach((filter: any) => {
            Mixpanel.track("FeatureUsage", {
                $name: auth.currentUser?.displayName,
                $email: auth.currentUser?.email,
                //"Plan": auth.currentUser?.plan,
                "User Type": "Buyer",
                "User ID": auth.currentUser?.uid,
                "Date": new Date().toISOString(),
                "Feature": `Applied ${filter.title} Filter`,
            });

            compArray.push(<AppliedFilter filter={filter} remove={removeAppliedFilter} key={filter.id} />)
        });

        compArray.push(<span ref={appliedRef} key={0} />)

        return compArray

    }
    // #endregion


    // #region WEB REQUESTS

    // #endregion


    // #region BUTTONS CLICKED
    const foOpened = (id: any, open: any) => {


        setShowKeyFilters(false)
        setShowSAF(false)
        setShowCompanyF(false)
        setShowFinancialsF(false)
        setShowPeopleF(false)
        setShowSavedF(false)

        if (id === 0) { setShowKeyFilters(open) }
        if (id === 1) { setShowSAF(open) }
        if (id === 2) { setShowCompanyF(open) }
        if (id === 3) { setShowFinancialsF(open) }
        if (id === 4) { setShowPeopleF(open) }
        if (id === 5) { setShowSavedF(open) }
    }

    const foSelected = (filter: any) => {
        setSelectedFO(filter)
    }

    const cfOpened = (id: any, open: any) => {

        setOpenFilter(null)
        if (open) { setOpenFilter(id) }
    }

    // #endregion


    // #region OTHER

    // #endregion

    return (
        <div className="searchBubble advancedSearch">

            <div className='leftSectionAS'>
                <span className='text-xs semibold gray-700'>Filters</span>
                {addFilters()}
            </div>

            <div className='mainSectionAS'>
                {selectedFO !== null && <div className='foList'>
                    <span className='foListTitle text-sm semibold gray-700'>Filter by {selectedFO.title}</span>
                    <div className='width100 column height100 gap12 jc-between'>
                        <div className='width100 column gap12'>
                            {showFilters()}
                        </div>
                    </div>

                </div>}
            </div>

            <div className='rightSectionAS'>

                {appliedFilters.length === 0 && <div className='noFilters'>
                    <span className='text-sm regular gray-600'>No filters applied</span>
                </div>}
                {appliedFilters.length > 0 &&
                    <div className='loginForgotRow paddingRight20'><button onClick={removeAll} className='text-sm medium primary-700'>Clear all</button></div>
                }
                {appliedFilters.length > 0 && <div className='appliedFiltersColumn'>
                    {showAppliedFilters()}
                </div>}

                <div className='loginForgotRow margin24 paddingRight20'><button onClick={search} className='text-sm medium gray-700'>Done</button></div>

            </div>

        </div>
    )

}

export default AdvancedSearch;