import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CreateCampaignResponseDto } from "./types";

interface CampaignState {
  currentStep: number;
  selectedCollectionIds: string[];
  selectAllForUncheck: boolean;
  campaignType?: string;
  campaignName: string;
  createCampaignResponse?: CreateCampaignResponseDto;
  emailTemplateData: EmailTemplate[];
  mailBoxStates: any;
}

interface EmailTemplate {
  body: string;
  name: string;
  sendAfterDays: number;
  subject: string;
}

interface MailboxState {
  burnerDomains: string[];
  mailboxes: string[];
  replyTo: string;
  preferredDomain: string;
  domain?: string;
}

const initialState: CampaignState = {
  currentStep: 1,
  selectedCollectionIds: [],
  selectAllForUncheck: false,
  campaignType: "email",
  campaignName: "New Campaign",
  createCampaignResponse: undefined,
  emailTemplateData: [],
  mailBoxStates: {
    burnerDomains: [],
    mailboxes: [],
    replyTo: "",
    preferredDomain: "",
  },
};

export const campaignSlice = createSlice({
  name: "campaign",
  initialState: initialState,
  reducers: {
    nextStep: (state) => {
      if (state.currentStep < 6) state.currentStep += 1;
    },
    prevStep: (state) => {
      if (state.currentStep > 1) state.currentStep -= 1;
    },
    resetStep: (state) => {
      state.currentStep = 1;
    },
    setCampaignType: (state, action: PayloadAction<string>) => {
      state.campaignType = action.payload;
    },
    setCampaignName: (state, action: PayloadAction<string>) => {
      state.campaignName = action.payload;
    },
    setSelectedCollectionIds: (state, action: PayloadAction<string[]>) => {
      state.selectedCollectionIds = action.payload;
    },
    toggleCollectionId: (state, action: PayloadAction<string>) => {
      const index = state.selectedCollectionIds.indexOf(action.payload);
      if (index >= 0) {
        state.selectedCollectionIds.splice(index, 1);
      } else {
        state.selectedCollectionIds.push(action.payload);
      }
    },
    setSelectAllClickedForUncheck: (state, action: PayloadAction<boolean>) => {
      state.selectAllForUncheck = action.payload;
    },
    setCreateCampaignResponse: (
      state,
      action: PayloadAction<CreateCampaignResponseDto>
    ) => {
      state.createCampaignResponse = action.payload;
    },
    setEmailTemplates: (state, action: PayloadAction<EmailTemplate[]>) => {
      state.emailTemplateData = action.payload;
    },
    updateMailboxData: (
      state,
      action: PayloadAction<Partial<MailboxState>>
    ) => {
      const { domain, ...restOfPayload } = action.payload;
      state.mailBoxStates = {
        ...state.mailBoxStates,
        ...restOfPayload,
      };
    },
    resetCampaignState: () => initialState,
  },
});

export const {
  nextStep,
  prevStep,
  setSelectedCollectionIds,
  toggleCollectionId,
  resetStep,
  setSelectAllClickedForUncheck,
  setCampaignType,
  setCampaignName,
  setCreateCampaignResponse,
  setEmailTemplates,
  updateMailboxData,
  resetCampaignState,
} = campaignSlice.actions;

export default campaignSlice.reducer;

export const selectedCollection = (state: { campaign: CampaignState }) =>
  state.campaign.selectedCollectionIds;

export const selectSelectAllForUncheck = (state: { campaign: CampaignState }) =>
  state.campaign.selectAllForUncheck;

export const selectCampaignType = (state: { campaign: CampaignState }) =>
  state.campaign.campaignType;

export const selectCampaignName = (state: { campaign: CampaignState }) =>
  state.campaign.campaignName;

export const selectCurrentStep = (state: { campaign: CampaignState }) =>
  state.campaign.currentStep;

export const selectCreateCampaignResponse = (state: {
  campaign: CampaignState;
}) => state.campaign.createCampaignResponse;

export const selectEmailTemplate = (state: { campaign: CampaignState }) =>
  state.campaign.emailTemplateData;

export const selectMailboxData = (state: { campaign: CampaignState }) =>
  state.campaign.mailBoxStates;
