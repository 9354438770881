import { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedCollectionIds,
  toggleCollectionId,
  selectedCollection,
  setSelectAllClickedForUncheck,
} from "../../campaignSlice";
import { selectCollections } from "components/collection/collectionSlice";
import { ColumnDef, SortingState } from "@tanstack/react-table";

export const useSelectCollection = () => {
  const dispatch = useDispatch();
  const collectionData = useSelector(selectCollections);
  const selectedCollectionIds = useSelector(selectedCollection);
  //const selectAllForUncheck = useSelector(selectSelectAllForUncheck);

  const [isAllSelected, setIsAllSelected] = useState(false);
  const [sorting, setSorting] = useState<SortingState>([]);

  // Handle collection data and syncing selected collection ids
  // useEffect(() => {
  //   if (collectionData?.length > 0) {
  //     if (selectedCollectionIds?.length === 0 && !selectAllForUncheck) {
  //       const allIds = collectionData.map((item: any) => item.id);
  //       dispatch(setSelectedCollectionIds(allIds));
  //       setIsAllSelected(true);
  //     } else {
  //       const validSelectedIds = selectedCollectionIds?.filter((id) =>
  //         collectionData.some((item) => item.id === id)
  //       );
  //       if (validSelectedIds?.length !== selectedCollectionIds?.length) {
  //         dispatch(setSelectedCollectionIds(validSelectedIds));
  //       }
  //       setIsAllSelected(validSelectedIds?.length === collectionData?.length);
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [collectionData, selectedCollectionIds, dispatch]);

  const toggleRowSelection = (rowId: string) => {
    dispatch(toggleCollectionId(rowId));
    const updatedSelectedIds = selectedCollectionIds?.includes(rowId)
      ? selectedCollectionIds?.filter((id) => id !== rowId)
      : [...selectedCollectionIds, rowId];
    setIsAllSelected(updatedSelectedIds?.length === collectionData?.length);
  };

  const toggleSelectAll = (checked: boolean) => {
    const allIds = collectionData?.map((item: any) => item.id);
    if (checked) {
      dispatch(setSelectedCollectionIds(allIds));
    } else {
      dispatch(setSelectAllClickedForUncheck(true));
      dispatch(setSelectedCollectionIds([]));
    }
    setIsAllSelected(checked);
  };

  const columns: ColumnDef<any>[] = useMemo(
    () => [
      {
        header: "Collection",
        accessorKey: "collection",
      },
      {
        header: "Date",
        accessorKey: "exportedOn",
      },
    ],
    []
  );

  const data = useMemo(
    () =>
      collectionData.map((item) => ({
        id: item.id,
        collection: item.name,
        date: item.exportedOn,
      })),
    [collectionData]
  );

  return {
    isAllSelected,
    selectedCollectionIds,
    toggleSelectAll,
    toggleRowSelection,
    sorting,
    setSorting,
    columns,
    data,
  };
};
