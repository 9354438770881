import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface collection {
  companyCount: number;
  description: string;
  exportedOn: string;
  name: string;
  id: string;
}

interface CollectionState {
  collections: collection[];
  loading: boolean;
  error: string | null;
}

const initialState: CollectionState = {
  collections: [],
  loading: false,
  error: null,
};

export const collectionSlice = createSlice({
  name: "collection",
  initialState,
  reducers: {
    setCollections: (state, action: PayloadAction<collection[]>) => {
      state.collections = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
  },
});

export const selectCollections = (state: { collection: CollectionState }) =>
  state.collection.collections;

export const { setCollections, setLoading, setError } = collectionSlice.actions;

export default collectionSlice.reducer;
