import { SVGProps } from 'react';

interface SvgCheckboxCircleIconProps extends SVGProps<SVGSVGElement> {
    fill?: string;
}

const SvgCheckboxCircleIcon = ({ fill = '#E31B53', ...props }: SvgCheckboxCircleIconProps) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" fill={fill} />
        <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" stroke={fill} />
        <path d="M11.3332 5.5L6.74984 10.0833L4.6665 8" stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);
export default SvgCheckboxCircleIcon;
