export const getFinancialStr = (
  number: any,
  estimated?: any,
  hideTilde?: any
) => {
  var str = "";

  if (number !== null && number !== undefined && number !== "-") {
    if ((number + "").includes(" - ")) {
      let split = (number + "").split(" - ");
      str +=
        getFinancialStr(split[0], false, true) +
        " - " +
        getFinancialStr(split[1], false, true);
    } else if (number >= 1000000 || number <= -1000000) {
      str += "£";
      var millions: any = (number / 1000000).toFixed(2);
      if (number % 1000000 === 0) {
        millions = number / 1000000;
      } else if (number % 100000 === 0 || estimated) {
        millions = (number / 1000000).toFixed(1);
      }
      str += millions + "M";
    } else if (number >= 1000 || number <= -1000) {
      str += "£";
      var thousands: any = number / 1000;
      if (thousands < 10 && number % 1000 !== 0) {
        thousands = thousands.toFixed(1);
      } else {
        thousands = Math.floor(thousands);
      }
      str += thousands + "K";
    } else {
      str += "£";
      str += number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    if (estimated && !hideTilde) {
      str = "~" + str;
    }

    return str;
  } else {
    return "-";
  }
};

export const formatPercentage = (percentage: any) => {
  var newPercentage: any = +percentage;
  if (Math.abs(newPercentage) >= 1) {
    newPercentage = newPercentage.toFixed(1);
  } else {
    newPercentage = newPercentage.toFixed(2);
  }
  return newPercentage;
};

export const formatDate = (dateString: string): string => {
  const date = new Date(dateString);

  const day = String(date.getDate()).padStart(2, "0"); // Ensure two digits
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed, so add 1
  const year = date.getFullYear();

  return `${day}.${month}.${year}`;
};
