import { useEffect, useState } from "react"
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "components/shadcn/ui/dialog"
import { Input } from "components/shadcn/ui/input"
import { Button } from "components/shadcn/ui/button"
import { EditIcon } from "lucide-react"
import SvgCloseIcon from "./CloseIcon"
import lang from "lang"

interface CampaignModalProps {
    isOpen: boolean,
    value: string,
    onClose: () => void,
    onSave: (name: string) => void
}

export default function CampaignModal({ isOpen, value, onClose, onSave }: CampaignModalProps) {
    const { campaign: campaignCopy } = lang;
    const [campaignName, setCampaignName] = useState(value || "");

    useEffect(() => {
        setCampaignName(value);
    }, [value]);


    const handleSave = () => {
        onSave(campaignName);
        setCampaignName("");
        onClose();
    }

    return (
        <div className="flex z-999 rounded-[32px]">
            <Dialog open={isOpen} onOpenChange={onClose}>


                <DialogContent className="sm:max-w-md">
                    <div className="flex flex-row align-center justify-between">
                        <EditIcon />
                        <button onClick={onClose}><SvgCloseIcon /></button>
                    </div>
                    <DialogHeader className="flex flex-row items-center justify-between">
                        <DialogTitle className="text-lg text-gray-950">{campaignCopy.editNameHeader}</DialogTitle>

                    </DialogHeader>
                    <div className="py-6">
                        <Input
                            value={campaignName}
                            onChange={(e) => setCampaignName(e.target.value)}
                            placeholder="New campaign"
                            className="w-full"
                        />
                    </div>
                    <div className="flex justify-end gap-4">
                        <Button variant="outline" onClick={onClose}>
                            {campaignCopy.cancel}
                        </Button>
                        <Button onClick={handleSave} variant="primary" className="bg-[#E6007A] hover:bg-[#E6007A]/90 text-white">
                            {campaignCopy.save}
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </div >

    )
}

