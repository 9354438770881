import '../../styling/saved.css'
import React, { useEffect, useState } from 'react';
import { Button } from 'components/shadcn/ui/button';
import { useNavigate } from 'react-router-dom';
import { CampaignsTable } from './campaignTable';
import { selectCollections } from 'components/collection/collectionSlice';
import SvgFolderIcon from 'components/common/iconComponents/FolderIcon';
import { useSelector } from 'react-redux';
import lang from 'lang';
import { getCampaignList } from './services';
import Loader from 'components/common/loader';

interface CampaignProps { }
const Campaign: React.FC<CampaignProps> = () => {
    const { campaign: campaignCopy } = lang;

    const navigate = useNavigate();
    const collections = useSelector(selectCollections);

    const [campaignList, setCampaignList] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleSearchNavigation = () => {
        navigate(`../`, { replace: true });
    };

    useEffect(() => {
        if (collections.length === 0) return;
        const fetchCampaignList = async () => {
            setLoading(true);
            const fetchedCampaignList = await getCampaignList();
            if (fetchedCampaignList.length === 0 && collections.length) {
                navigate(`../campaignLanding`, { replace: true });
                setLoading(false);
            }
            else {
                setCampaignList(fetchedCampaignList);
                setLoading(false);
            }
        };
        fetchCampaignList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="fullScreen">
            {loading && <Loader />}
            <div className="container max-w-[80%]">
                <div className="savedFiltersScreen">
                    <div className="sfTitles flex flex-row gap-5 justify-between">
                        <div className="display-sm semibold text-gray-900">Campaigns</div>

                        {collections.length > 0 && (
                            <Button variant="primary" onClick={() => navigate(`../campaignLanding`, { replace: true })}>
                                <div className="text-white font-inter text-sm font-semibold leading-5 text-left underline-from-font">
                                    {campaignCopy.newCampaign}
                                </div>
                            </Button>
                        )}
                    </div>

                    {
                        !loading && (
                            collections.length > 0 && campaignList.length > 0 ? (
                                <CampaignsTable campaignList={campaignList} />
                            ) : (
                                <div className="w-full flex flex-col gap-8 items-center min-h-[50vh] justify-center">
                                    <SvgFolderIcon />
                                    <div className="flex flex-col gap-2">
                                        <p className="text-lg font-semibold font-[InstrumentSans] text-center leading-[28px]">
                                            {campaignCopy.noCollectionsYet}
                                        </p>
                                        <p className="text-sm font-normal font-[Inter] text-center leading-[20px] decoration-none decoration-[from-font]">
                                            {campaignCopy.noCollectionsSubText}
                                        </p>
                                    </div>
                                    <Button variant="primary" onClick={handleSearchNavigation}>
                                        <div className="font-inter text-sm font-semibold leading-5 text-left text-white">
                                            {campaignCopy.goToSearch}
                                        </div>
                                    </Button>
                                </div>
                            )
                        )
                    }

                </div>
            </div>
        </div>
    );


}

export default Campaign;