// src/utils/convertResponse.ts

import { getSICTitle, formatDate } from 'components/Search/Basic/SearchBar';
import { SIC_CODES } from 'components/Search/Advanced/FilterTypes/siccodes';
import { getFinancialStr } from 'helpers';

export const convertResponse = (list: any) => {
    let currentYear = new Date().getFullYear();

    function getCountryGB(country: string) {
        if (country === "" || country === null || country === undefined
            || country === "England" || country === "Scotland" || country === "Wales"
            || country === "Northern Ireland" || country === "United Kingdom") {
            return "GB"
        }
        else {
            return country
        }
    }

    let tableList: any = [];
    let companyList: any = [];
    let PSCTableList: any = [];

    let PSCList: any = [];
    let contactList: any = [];
    let financialsList: any = [];

    let compNum = "";
    let coregList: any = [];

    let index = 0;

    list.forEach((item: any) => {
        if (index < 5000) {
            if (item.companyNumber !== null) {
                let turnOverStatus = "Reported";
                if (!coregList.includes(item.companyNumber)) {
                    compNum = item.companyNumber;
                    coregList.push(compNum);

                    if (item.shareholderType === "none") {
                        item.shareholderType = "N/A";
                    }

                    if (item.revenue === null) {
                        let tradeDebtors = item.tradeDebtors;
                        if (!item.tradeDebtors) {
                            tradeDebtors = item.currentAssets * 0.75;
                            item.TradeDebtors = tradeDebtors;
                        }

                        let lowRange = Math.floor(tradeDebtors * 6 / 100000) * 100000;
                        let highRange = Math.floor(tradeDebtors * 10 / 100000) * 100000;

                        item.turnover = lowRange + " - " + highRange;
                        item.estimated = true;
                    }

                    if (item.revenue === null) {
                        var tradeDebtors = item.tradeDebtors
                        if (!item.tradeDebtors) {
                            tradeDebtors = item.currentAssets * .75
                            item.tradeDebtors = tradeDebtors
                        }

                        let lowRange = Math.floor(tradeDebtors * 6 / 100000) * 100000
                        let highRange = Math.floor(tradeDebtors * 10 / 100000) * 100000

                        item.turnover = lowRange + " - " + highRange
                        turnOverStatus = "Estimated"
                    }
                    else {
                        item.turnover = item.revenue;
                    }

                    if (!item.netProfitPercentage && item.netProfit) {
                        item.netProfitPercentage = ((item.netProfit / item.turnover) * 100).toFixed(2);
                        if ((item.turnover + "").includes(" - ")) {
                            let split = item.turnover.split(" - ");
                            let middle = (+split[0] + +split[1]) / 2;
                            item.netProfitPercentage = ((item.netProfit / middle) * 100).toFixed(2);
                        }
                    }
                    if (item.netProfitPercentage === null) {
                        item.netProfitPercentage = "Not Available";
                    }

                    if (item.netProfitPercentage && parseFloat(item.netProfitPercentage) > 80) {
                        item.netProfitPercentage = null;
                        item.netProfit = null;
                        item.netProfitEstimate = false;
                    }

                    if (item.email === "undefined" || item.email === "null") {
                        item.email = "";
                    }

                    tableList.push({
                        name: item.companyName.replace("LIMITED", "LTD"),
                        number: item.companyNumber || item.CompNum2,
                        sic: item.SIC,
                        sicName: getSICTitle(item.SIC, SIC_CODES)?.title,
                        registeredAddress: item.registeredAddress,
                        tradingAddress: item.tradingAddress,
                        postTown: item.regAddressPostTown,
                        county: item.regAddressCounty,
                        country: item.regAddressCountry,
                        website: item.website,
                        incorporation: item.incorporationDate,
                        filingDate: item.filingDate || "",
                        year: item.financialYear || "",
                        type: item.companyType,
                        status: item.companyStatus,
                        amountPSCs: item.amountPSCs,
                        debtRatio: (item.debtRatio / 100).toFixed(2),
                        //revenue: getFinancialStr(item.revenue),
                        netProfitPercentage: (Number(item.netProfitPercentage) || 0).toFixed(2) + "%",
                        fanpRatio: item.FTARevRatio,
                        turnover: item.turnover ? getFinancialStr(item.turnover) : null,
                        turnoverStatus: turnOverStatus,
                        netProfit: item.netProfit,
                        netProfitEstimate: item.netProfitEstimate ? "Estimated" : "Reported",
                        currentAssets: item.currentAssets,
                        fixedAssets: item.fixedAssets,
                        totalAssets: item.totalAssets,
                        currentLiabilities: item.currentLiabilities,
                        longTermLiabilities: item.longTermLiabilities,
                        netAssets: item.netAssets,
                        updated: item.companyUpdated,
                        emailCount: 0,
                        validEmailCount: 0,
                        numEmployees: item.numEmployees,
                        premium: item.Premium === 1
                    });

                    companyList.push({
                        name: item.companyName,
                        number: item.companyNumber || item.CompNum2,
                        sic: item.SIC,
                        sicName: getSICTitle(item.SIC, SIC_CODES)?.title,
                        registeredAddress: item.registeredAddress,
                        tradingAddress: item.tradingAddress,
                        website: item.website,
                        incorporation: formatDate(item.incorporationDate),
                        type: item.companyType,
                        status: item.companyStatus,
                        amountPSCs: item.amountPSCs,
                        revenue: getFinancialStr(item.revenue),
                        debtRatio: (item.debtRatio / 100).toFixed(2),
                        netProfitPercentage: (Number(item.netProfitPercentage) || 0).toFixed(2) + "%",
                        fanpRatio: item.FTARevRatio,
                        turnover: item.turnover ? getFinancialStr(item.turnover) : null,
                        turnoverStatus: turnOverStatus,
                        netProfit: item.netProfit ? getFinancialStr(item.netProfit) : item.netAssets ? getFinancialStr(item.netAssets) : null,
                        netProfitEstimate: item.netProfitEstimate ? "Estimated" : "Reported",
                        currentAssets: item.currentAssets ? getFinancialStr(item.currentAssets) : null,
                        fixedAssets: item.fixedAssets ? getFinancialStr(item.fixedAssets) : null,
                        totalAssets: item.totalAssets ? getFinancialStr(item.totalAssets) : null,
                        currentLiabilities: item.currentLiabilities ? getFinancialStr(item.currentLiabilities) : null,
                        longTermLiabilities: item.longTermLiabilities ? getFinancialStr(item.longTermLiabilities) : null,
                        netAssets: item.netAssets ? getFinancialStr(item.netAssets) : null,
                        emailCount: 0,
                        validEmailCount: 0,
                        numEmployees: item.numEmployees,
                        premium: item.Premium === 1
                    });

                    financialsList.push({
                        compNumber: item.companyNumber,
                        compName: item.companyName,
                        sic: item.SIC,
                        sicName: getSICTitle(item.SIC, SIC_CODES)?.title,
                        registeredAddress: item.registeredAddress,
                        tradingAddress: item.tradingAddress,
                        website: item.website,
                        incorporation: item.incorporationDate,
                        type: item.companyType,
                        status: item.companyStatus,
                        filingDate: item.filingDate,
                        year: item.financialYear,
                        debtRatio: (item.debtRatio / 100).toFixed(2),
                        revenue: getFinancialStr(item.revenue),
                        netProfitPercentage: (Number(item.netProfitPercentage) || 0).toFixed(2) + "%",
                        fanpRatio: item.FTARevRatio,
                        turnover: item.turnover ? getFinancialStr(item.turnover) : null,
                        turnoverStatus: item.Estimated ? "Estimated" : "Reported",
                        netProfit: item.netProfit ? getFinancialStr(item.netProfit) : item.netAssets ? getFinancialStr(item.netAssets) : null,
                        netProfitEstimate: item.netProfitEstimate ? "Estimated" : "Reported",
                        currentAssets: item.currentAssets ? getFinancialStr(item.currentAssets) : null,
                        fixedAssets: item.fixedAssets ? getFinancialStr(item.fixedAssets) : null,
                        totalAssets: item.totalAssets ? getFinancialStr(item.totalAssets) : null,
                        currentLiabilities: item.currentLiabilities ? getFinancialStr(item.currentLiabilities) : null,
                        longTermLiabilities: item.longTermLiabilities ? getFinancialStr(item.longTermLiabilities) : null,
                        netAssets: item.netAssets ? getFinancialStr(item.netAssets) : null
                    });

                    index++;
                }

                if (item.forename !== null) {
                    if (item.Address_Line1 === "") {
                        let split = item.Address.split(',');
                        if (split.length > 1) {
                            item.Address_Line1 = split[0];
                        }
                    }

                    let emailStatus = item.EmailStatus || "valid";
                    if (emailStatus === "invalid" || emailStatus === "abuse") {
                        item.email = "";
                    }
                    let emailV = item.email || "";
                    if (emailV === "") {
                        emailStatus = "";
                    } else {
                        if (emailStatus !== "valid") {
                            emailStatus = "medium";
                        } else {
                            emailStatus = "high";
                        }
                    }

                    if (item.email !== "" && item.email !== undefined && item.email !== "undefined" && item.email !== "null") {
                        let index = tableList.findIndex((x: any) => x.number === item.companyNumber);
                        tableList[index].emailCount = tableList[index].emailCount + 1;
                        companyList[index].emailCount = companyList[index].emailCount + 1;

                        if (emailStatus !== "medium") {
                            tableList[index].validEmailCount = tableList[index].validEmailCount + 1;
                            companyList[index].validEmailCount = companyList[index].validEmailCount + 1;
                        }
                    }

                    PSCTableList.push({
                        name: item.companyName,
                        number: item.companyNumber,
                        sic: item.SIC,
                        sicName: getSICTitle(item.SIC, SIC_CODES)?.title,
                        title: item.title,
                        firstName: item.forename,
                        surname: item.surname,
                        addressLine1: item.addressLine1,
                        addressLine2: item.addressLine2,
                        town: item.addressPostTown,
                        county: item.addressCounty,
                        postCode: item.postCode,
                        country: item.country,
                        shareholderType: item.shareholderType,
                        shareholderControl: item.shareholderPercentage,
                        age: 2023 - item.yearDOB,
                        email: item.email,
                        emailStatus: emailStatus,
                        linkedIn: item.linkedIn,
                        monthDOB: item.monthDOB,
                        yearDOB: item.yearDOB,
                    });
                    contactList.push({
                        name: item.companyName,
                        number: item.companyNumber,
                        sic: item.SIC,
                        sicName: getSICTitle(item.SIC, SIC_CODES)?.title,
                        title: item.title,
                        firstName: item.forename,
                        surname: item.surname,
                        email: item.email || "",
                        emailStatus: emailStatus,
                        linkedIn: item.linkedIn,
                        shareholderType: item.shareholderType,
                        shareholderControl: item.shareholderPercentage,
                        age: currentYear - item.yearDOB
                    });

                    PSCList.push({
                        name: item.companyName,
                        number: item.companyNumber,
                        sic: item.SIC,
                        sicName: getSICTitle(item.SIC, SIC_CODES)?.title,
                        title: item.title,
                        firstName: item.forename,
                        surname: item.surname,
                        addressType: "Registered",
                        addressLine1: item.RegAdd_Line1,
                        addressLine2: item.RegAdd_Line2,
                        town: item.RegAdd_PostTown,
                        county: item.RegAdd_County,
                        postCode: item.RegAdd_PostCode,
                        country: getCountryGB(item.RegAdd_Country),
                        shareholderType: item.shareholderType,
                        shareholderControl: item.shareholderPercentage,
                        age: currentYear - item.yearDOB,
                        email: item.email,
                        emailStatus: emailStatus,
                    });
                }
            }
        }
    });

    tableList = tableList.sort((a: any, b: any) => b.emailCount - a.emailCount);
    companyList = companyList.sort((a: any, b: any) => b.emailCount - a.emailCount);

    return {
        tableList,
        companyList,
        PSCTableList,
        PSCList,
        contactList,
        financialsList,
    };
};