import React from "react";
import { cn } from "components/lib/utils"

interface Step {
    id: number;
    name: string;
}

interface StepperProps {
    currentStep: number;
    steps: Step[];
}

export default function Stepper({ currentStep = 1, steps }: StepperProps) {
    return (
        <div className="w-full max-w-4xl mx-auto px-4">
            <div className="flex items-center justify-between relative">
                <div
                    className="absolute left-0 top-1/2 h-[2px] w-full -translate-y-1/2 bg-[#EAECF0]"
                    style={{
                        left: `calc(4rem / 2)`,
                        right: `calc(4rem / 2)`,
                        width: `calc(100% - 4rem)`,
                        transform: "translateY(-50%)",
                        top: "14px",
                    }}
                />
                {steps.map((step) => (
                    <div key={step.id} className="relative flex flex-col items-center">
                        <div
                            className={cn(
                                "w-8 h-8 rounded-full flex items-center justify-center z-10 border-2",
                                currentStep >= step.id
                                    ? "bg-[#FFF1F3] border-[#E31B53]"
                                    : "bg-white border-[#EAECF0]"
                            )}
                        >
                            {currentStep > step.id ? (
                                <CheckIcon className="w-5 h-5 text-[#E31B53]" />
                            ) : currentStep === step.id ? (
                                <div className="w-2.5 h-2.5 rounded-full bg-[#E31B53]" />
                            ) : (
                                <div className="w-2.5 h-2.5 rounded-full bg-[#EAECF0]" />
                            )}
                        </div>
                        <span
                            className={cn(
                                "mt-2 text-sm whitespace-nowrap",
                                currentStep === step.id
                                    ? "text-[#C01048] font-medium"
                                    : "text-[#344054]"
                            )}
                        >
                            {step.name}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
}

function CheckIcon({ className }: { className?: string }) {
    return (
        <svg className={className} fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
        </svg>
    );
}
