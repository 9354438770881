const campaign = {
  campaigns: "Campaigns",
  noCampaignsHeading: "No campaigns created yet",
  campaignOptionText: "Start your campaign by choosing campaign type:",
  campaignOptionType: [
    {
      id: 1,
      label: "E-mail campaign ",
      disabled: false,
      subText: "Most Likely to Receive a Reply",
    },
    {
      id: 2,
      label: "Coming Soon : Letter campaign",
      disabled: true,
      subText: "Moderate Chance of a Reply",
    },
    {
      id: 3,
      label: "Coming Soon : Email & Letter campaign",
      disabled: true,
      subText: "Least Likely to Receive a Reply",
    },
  ],
  newCampaign: "New Campaign",
  noCollectionsYet: "No Collections yet",
  noCollectionsSubText:
    "To run a campaign you need to save companies to a collection. ",
  goToSearch: "Go to Search",
  startCampaign: "Start Campaign",
  next: "Next",
  back: "Back",
  createCampaignSubtext: "Create new campaign template",
  edit: "Edit",
  reviewAndRefine: "Review & Refine",
  selectCollection: "Select Collection for Campaign",
  labelForCompanyNAme:
    "What's the name of your Hold Co, Fund or Acquisition Vehicle?",
  phoneNumber: "Phone Number",
  website: "Website",
  headsUp: "Heads up!",
  mailboxAlertDescription:
    "To prevent your mailbox from being flagged as spam, we limit sending to 30 emails per day per email address.",
  mailboxes: "Mailboxes",
  labelPreferredDomain: "What's your preferred domain identity?",
  websiteDomain:
    "Enter your website domain or preferred domain identity e.g. www.mywebsite.com",
  burnerDomain:
    "We have detected the following burner domains for your outreach:",
  outreachMaiboxesHeading:
    "We have detected the following mailboxes for your outreach:",
  mailboxBurnerDomainHeading:
    "New mailboxes with burner domains help isolate outreach campaigns and manage spam risks effectively",
  repliesToEmailSubtext:
    "Replies from your campaigns will be forwarded to email address in your BizCrunch profile.",
  campaignSummary: "Campaign summary",
  campaignSummarySubtext: "Flexible pricing that grows with you.",
  aboutCredits: "About credits",
  warmingUp: "Warming Up",
  warmingUpSubtext:
    "It will take few days to warmup your campaign inbox. Campaign will be sent automatically out as soon as it's ready.",
  emailsToSend: "Emails to send",
  creditsBalance: "Credits balance",
  editNameHeader: "Name your campaign",
  cancel: "Cancel",
  save: "Save",
  doYouOwnTheDomain: "Do you own this domain?",
  domainError:
    "Please enter a valid domain (e.g. www.bizcrunch.com or bizcrunch.com)",
  discardModalHeader: "Are you sure you want to leave?",
  discradModalSubtext: "Campaign progress will be saved as draft",
};

export const lang = {
  campaign,
};

export default lang;
