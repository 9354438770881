import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { CardContent, CardHeader, CardTitle } from 'components/shadcn/ui/card';
import { Input } from 'components/shadcn/ui/input';
import { Label } from 'components/shadcn/ui/label';
import { validationSchema } from 'components/Campaign/validationSchema';
import lang from 'lang';

type FormInputs = Yup.InferType<typeof validationSchema>;

interface SetupCustomisationsProps {
    onFormSubmit: (data: FormInputs) => void;
    setIsFormValid: (isValid: boolean) => void;
}

const SetupCustomisations: React.FC<SetupCustomisationsProps> = ({ onFormSubmit, setIsFormValid }) => {
    const storedData = JSON.parse(localStorage.getItem('user') || '{}');
    const { campaign: camapignCopy } = lang;
    const {
        register,
        watch,
        formState: { errors, isValid },
    } = useForm<FormInputs>({
        resolver: yupResolver(validationSchema),
        mode: 'onChange',
        defaultValues: {
            holdingCompany: storedData.holdingCompany || '',
            phoneNumber: storedData.phoneNumber || '',
            website: storedData.website || ''
        }
    });

    const formValues = watch();
    const prevFormValues = useRef<FormInputs | null>(null);

    useEffect(() => {
        setIsFormValid(isValid);

        if (isValid && JSON.stringify(prevFormValues.current) !== JSON.stringify(formValues)) {
            prevFormValues.current = formValues;
            onFormSubmit(formValues);
        }
    }, [isValid, formValues, setIsFormValid, onFormSubmit]);

    return (
        <div className="w-full text-left shadow-none">
            <CardHeader>
                <CardTitle className="text-xl font-semibold">Setup Customisations</CardTitle>
            </CardHeader>
            <CardContent className="flex flex-col gap-6">
                <div className='flex flex-col gap-2'>
                    <Label htmlFor="companyName" className='text-sm text-[#344054]'>
                        {camapignCopy.labelForCompanyNAme}
                    </Label>
                    <Input
                        id="companyName"
                        {...register('holdingCompany')}
                        placeholder="Acme"
                    />
                    {errors.holdingCompany && (
                        <p className="text-red-500 text-sm">{errors.holdingCompany.message}</p>
                    )}
                </div>

                <div className='flex flex-col gap-2'>
                    <Label htmlFor="phone" className='text-sm text-[#344054]'>{camapignCopy.phoneNumber}</Label>
                    <Input
                        id="phone"
                        type="tel"
                        {...register('phoneNumber')}
                        placeholder="1234 123 123"
                    />
                    {errors.phoneNumber && (
                        <p className="text-red-500 text-sm">{errors.phoneNumber.message}</p>
                    )}
                </div>

                <div className='flex flex-col gap-2'>
                    <Label htmlFor="website" className='text-sm text-[#344054]'>{camapignCopy.website}</Label>
                    <Input
                        id="website"
                        type="url"
                        {...register('website')}
                        placeholder="www.acme.com"
                    />
                    {errors.website && (
                        <p className="text-red-500 text-sm">{errors.website.message}</p>
                    )}
                </div>
            </CardContent>
        </div>
    );
};

export default SetupCustomisations;
