import {
  selectEmailTemplate,
  setEmailTemplates,
} from "components/Campaign/campaignSlice";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export interface EmailTemplate {
  name: string;
  subject: string;
  sendAfterDays: number;
  body: string;
}

export function useEmailTemplates() {
  const emailTemplatesFromRedux = useSelector(selectEmailTemplate);
  const [emails, setEmails] = useState<EmailTemplate[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setEmails(emailTemplatesFromRedux as EmailTemplate[]);
  }, [emailTemplatesFromRedux]);

  const updateEmail = (index: number, updatedEmail: Partial<EmailTemplate>) => {
    setEmails((prevEmails) =>
      prevEmails.map((email, i) =>
        i === index ? { ...email, ...updatedEmail } : email
      )
    );
    dispatch(setEmailTemplates(emails));
    console.log(emails);
  };

  return { emails, updateEmail };
}
