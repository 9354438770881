import * as React from "react"
import * as CheckboxPrimitive from "@radix-ui/react-checkbox"
import { Check } from "lucide-react"

import { cn } from "components/lib/utils"
import { ParentContext } from "components/constants/ParentContext"

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ className, ...props }, ref) => {
  const context = React.useContext(ParentContext);

  const borderColor = context?.isHarbour ? "border-[#3DAEDF]" : "border-[#E31B53]";

  return (
    <CheckboxPrimitive.Root
      ref={ref}
      className={cn(
        `peer h-4 w-4 shrink-0 rounded-sm border ${borderColor} ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-[#FFF1F3] data-[state=checked]:text-[#E31B53]`,
        className
      )}
      {...props}
    >
      <CheckboxPrimitive.Indicator
        className={cn("flex items-center justify-center text-current")}
      >
        <Check className="h-4 w-4" />
      </CheckboxPrimitive.Indicator>
    </CheckboxPrimitive.Root>
  );
});

Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox }
