export const bizcrunchAppLiveUrl = "app.bizcrunch.co";
export const bizCrunchSiteUrl = "https://www.bizcrunch.co";
export const bizCrunchPolicyUrl = "https://www.bizcrunch.co/policies/";
export const bizCrunchchArticleUrl =
  "https://bizcrunch.tawk.help/article/how-do-bizcrunch-credits-work";
export const fundMyDealUrl = "https://bizcrunch.swoopfunding.com";
export const mixpanelApiHost = "https://api-eu.mixpanel.com";

export const lottieAnimationUrl =
  "https://lottie.host/998ea8e9-6e3b-43b9-8fe1-0bd824113069/xWUkOHakRC.json";
export const vimeoBiz4BizUrl = "https://vimeo.com/1022565834?share=copy";
export const youtubeUrl = "https://www.youtube.com/watch?v=OZPI9S0-oNE";
export const vimeoUrl = "https://vimeo.com/video/953994608?h=546257fcd8";

export const showOutreachFeature = false; //flag for show and hide outreach feature
