import { selectCreateCampaignResponse } from "components/Campaign/campaignSlice";
import { getSummaryForCamapign } from "components/Campaign/services";
import { CampaignSummaryDto } from "components/Campaign/types";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const useCampaignSummary = () => {
  const campaignId = useSelector(selectCreateCampaignResponse);
  const [summaryResponse, setSummaryResponse] = useState<CampaignSummaryDto>(
    {} as CampaignSummaryDto
  );
  useEffect(() => {
    const fetchCampaignData = async () => {
      try {
        const campaignData = await getSummaryForCamapign(campaignId?.id || "");
        setSummaryResponse(campaignData);
      } catch (error) {
        console.error("Failed to fetch campaign data:", error);
      }
    };

    if (campaignId) {
      fetchCampaignData();
    }
  }, [campaignId]);

  return {
    summaryResponse,
  };
};

export default useCampaignSummary;
