import { useEffect, useState } from "react";
import { CardContent } from "components/shadcn/ui/card";
import { Input } from "components/shadcn/ui/input";
import { Label } from "components/shadcn/ui/label";
// import { Alert, AlertDescription, AlertTitle } from "components/shadcn/ui/alert";
// import SvgCloseIcon from "components/popUps/CloseIcon";
// import SvgCheckIcon from "components/common/iconComponents/checkIcon";
// import SvgWarningIconWithOutline from "components/common/iconComponents/warningIconWithOutline";
import lang from "lang";
import { Checkbox } from "components/shadcn/ui/checkbox";

interface SetUpMailboxProps {
    setIsFormValid: (isValid: boolean) => void;
    onDataSubmit: (data: any) => void;
}

const SetUpMailbox: React.FC<SetUpMailboxProps> = ({ setIsFormValid, onDataSubmit }) => {
    const userData = JSON.parse(localStorage.getItem("user")!);

    const { campaign: campaignCopy } = lang;
    const [domain, setDomain] = useState<string>('');
    // const [burnerDomains, setBurnerDomains] = useState([{ email: '', status: '' }, { email: '', status: '' }]);
    // const [mailboxes, setMailboxes] = useState([{ email: '', status: '' }, { email: '', status: '' }]);

    const [domainError, setDomainError] = useState<string | null>(null);

    const domainRegex = /^(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;

    const handleDomainChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.trim();

        setDomain(value);

        if (value === "" || !domainRegex.test(value)) {
            setDomainError(campaignCopy.domainError);
            setIsFormValid(false);
        } else {
            setDomainError(null);
            setIsFormValid(true);
            handleBlur(value);
        }
    };

    useEffect(() => {
        if (!domain.trim()) {
            setIsFormValid(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [domain]);

    const handleBlur = (value: string) => {
        // const updatedBurnerDomains = [
        //     { email: 'domain@test.com', status: 'verified' },
        //     { email: 'test@mailbox.com', status: 'verified' }
        // ];

        // const updatedMailboxes = [
        //     { email: 'contact@yourwebsite.com', status: 'verified' },
        //     { email: 'news@yourwebsite.com', status: 'verified' }
        // ];

        //setBurnerDomains(updatedBurnerDomains);
        //setMailboxes(updatedMailboxes);

        // const formattedData = {
        //     burnerDomains: updatedBurnerDomains.map(item => item.email.split('@')[1]),
        //     mailboxes: updatedMailboxes.map(item => item.email),
        //     replyTo: userData?.email,
        //     preferredDomain: value,
        // };
        const formattedData = {
            burnerDomains: '',
            mailboxes: '',
            replyTo: '',
            preferredDomain: value,
        };

        onDataSubmit(formattedData);
    };

    return (
        <div className="w-full h-full min-h-[310px]">
            {/* <CardHeader className="space-y-1">
                <Alert variant="info" className="mb-4">
                    <div className="flex flex-row justify-between">
                        <div className="flex flex-row gap-5">
                            <SvgWarningIconWithOutline className="h-5 w-5 mr-3" />
                            <div className="flex flex-col font-inter text-[14px] font-semibold leading-[20px] text-left text-[#B54708]">
                                <AlertTitle>{campaignCopy.headsUp}</AlertTitle>
                                <AlertDescription>
                                    {campaignCopy.mailboxAlertDescription}
                                </AlertDescription>
                            </div>
                        </div>
                        <SvgCloseIcon className="text-right h-4 w-4 cursor-pointer opacity-70 hover:opacity-100" />
                    </div>
                </Alert>
                <CardTitle className="text-left">{campaignCopy.mailboxes}</CardTitle>
            </CardHeader> */}
            <CardContent className="flex flex-col gap-6">
                <div className="w-[100%]">
                    <div className="flex justify-between items-start flex-row gap-6">
                        <div className="text-left flex flex-col gap-2 w-[100%]">
                            <Label htmlFor="domain">{campaignCopy.labelPreferredDomain}</Label>

                            <div className="flex flex-row gap-6">
                                <Input
                                    id="domain"
                                    placeholder="Type your domain"
                                    value={domain}
                                    onChange={handleDomainChange}
                                />
                                {domainError && (
                                    <div className="text-sm text-red-500 mt-2">{domainError}</div>
                                )}
                                <div className="w-[280px] h-[100px] p-[24px] gap-2 rounded-t-[16px] bg-[#EAECF0] flex flex-col justify-center font-inter text-sm font-medium text-[14px] leading-[20px] text-left  text-gray-700">
                                    {campaignCopy.websiteDomain}
                                </div>
                            </div>
                        </div>


                    </div>
                    <div className="flex items-center gap-2 mt-[-45px] mb-[50px]">
                        <Checkbox id="save-domains" />
                        <Label htmlFor="save-domains" className="text-sm font-medium text-gray-700">{campaignCopy.doYouOwnTheDomain}</Label>
                    </div>
                </div>

                {/* <div className="pt-4 border-t">

                </div> */}
                {/* 
                <div className="space-y-4">
                    <div className="flex flex-col gap-2">
                        <div className="text-sm font-medium mb-2 flex items-center gap-2">
                            {campaignCopy.burnerDomain}
                        </div>
                        <div className="space-y-2">
                            {burnerDomains.map((item: any, index) => (
                                <div key={index} className="flex items-center gap-2">
                                    <Input
                                        value={item.email}
                                        disabled
                                        className="text-sm bg-[white]"
                                    />
                                    <SvgCheckIcon />
                                </div>
                            ))}
                        </div>
                    </div>
                </div> */}
                {/* 
                <div className="space-y-4">
                    <div className="flex justify-between items-start mb-2">
                        <div className="text-sm font-medium flex items-center gap-2">
                            {campaignCopy.outreachMaiboxesHeading}
                        </div>
                    </div>
                    <div className="flex flex-row justify-between w-[100%] gap-6">
                        <div className="flex flex-col gap-6 justify-normal w-[70%]">
                            {mailboxes.map((item, index) => (
                                <div key={index} className="flex flex-row items-center gap-2">
                                    <Input
                                        value={item.email}
                                        disabled
                                        className="text-sm bg-[white]"
                                    />
                                    <SvgCheckIcon />
                                </div>
                            ))}
                        </div>
                        <div className="w-[280px] h-[148px] p-[24px] gap-2 rounded-t-[16px] bg-[#EAECF0] flex flex-col justify-center font-inter text-sm font-medium text-[14px] leading-[20px] text-left  text-[#344054]">
                            {campaignCopy.mailboxBurnerDomainHeading}
                        </div>
                    </div>
                </div> */}

                <div className="flex items-left gap-2 flex-col">
                    <span className="text-sm text-left">
                        {campaignCopy.repliesToEmailSubtext}
                    </span>
                    <Input
                        disabled
                        className="text-sm"
                        value={userData?.email}
                    />
                </div>
            </CardContent>
        </div>
    );
};

export default SetUpMailbox;
