import { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  nextStep,
  prevStep,
  setCampaignName,
  selectCampaignName,
  selectCampaignType,
  selectedCollection,
  selectCurrentStep,
  setCreateCampaignResponse,
  selectCreateCampaignResponse,
  setEmailTemplates,
  selectEmailTemplate,
  selectMailboxData,
  updateMailboxData,
  resetCampaignState,
} from "../campaignSlice";
import { useNavigate } from "react-router-dom";
import {
  createNewCampaign,
  getEmailTemplates,
  saveCampaign,
  saveCollectionId,
  saveCustomisations,
  saveEmailTemplates,
  saveMailBoxDataToApi,
} from "../services";

const useCampaign = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentStep = useSelector(selectCurrentStep);
  const selectedCollectionIds = useSelector(selectedCollection);
  const campaignType = useSelector(selectCampaignType);
  const alreadyCampaignName = useSelector(selectCampaignName);
  const campaignId = useSelector(selectCreateCampaignResponse);
  const emailMessage = useSelector(selectEmailTemplate);
  const mailboxData = useSelector(selectMailboxData);
  const [showCampaignNameModal, setShowCampaignNameModal] = useState(false);

  const [name, setName] = useState(alreadyCampaignName);
  const [isEditing, setIsEditing] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [customisationsData, setCustomisationsData] = useState<any>({});
  const [discardModal, setDiscardModal] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    dispatch(resetCampaignState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (alreadyCampaignName === "New Campaign") {
      setShowCampaignNameModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alreadyCampaignName]);

  useEffect(() => {
    fetchEmailTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchEmailTemplates = async () => {
    try {
      setIsLoading(true);
      const emailTemplateData = await getEmailTemplates();
      if (emailTemplateData) {
        setIsLoading(false);
        dispatch(setEmailTemplates(emailTemplateData));
      }
      console.log(emailTemplateData);
    } catch (error) {
      setIsLoading(false);
      console.error("Error:", error);
    }
  };

  const handleEditCampaignName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleEditClick = () => {
    setIsEditing(true);
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
        inputRef.current.setSelectionRange(0, inputRef.current.value.length);
      }
    }, 0);
  };

  const handleCampaignNameModalClose = () => {
    setShowCampaignNameModal(false);
  };

  const handleSave = async (name: string) => {
    setIsEditing(false);
    dispatch(setCampaignName(name));
    try {
      setIsLoading(true);
      const payload = {
        name: name,
        type: campaignType || "email",
      };
      const data = await createNewCampaign(payload);
      if (data) {
        setIsLoading(false);
        dispatch(setCreateCampaignResponse(data));
        setName(data.name);
      }
      console.log(data);
    } catch (error) {
      setIsLoading(false);
      console.error("Error:", error);
    }
  };

  const handleFormSubmit = (data: any) => {
    console.log("Form Data Submitted:", data);
    setCustomisationsData(data);
  };

  // const handleFormChange = (data: any, isValid: boolean) => {
  //   setIsFormValid(isValid);
  //   //setCustomisationsData(data);
  //   console.log("Form Data:", data);
  // };

  const handleMailboxData = (data: any) => {
    dispatch(updateMailboxData(data));
  };

  const saveCollectionIds = async () => {
    try {
      setIsLoading(true);
      const data = await saveCollectionId(
        {
          collectionIds: selectedCollectionIds,
        },
        campaignId?.id as string
      );
      if (data) {
        setIsLoading(false);
      }
      console.log(data);
    } catch (error) {
      setIsLoading(false);
      console.error("Error:", error);
    }
  };

  const saveCustomisationsData = async () => {
    try {
      setIsLoading(true);

      const data = await saveCustomisations(customisationsData);

      if (data) {
        setIsLoading(false);
      }
      console.log(data);
    } catch (error) {
      setIsLoading(false);
      console.error("Error:", error);
    }
  };

  const saveMessagesForOutreach = async () => {
    try {
      setIsLoading(true);

      const payload = {
        messages: emailMessage.map((template) => ({
          subject: template.subject,
          body: template.body,
          sendAfterDays: template.sendAfterDays,
        })),
      };

      const data = await saveEmailTemplates(payload, campaignId?.id as string);

      if (data) {
        setIsLoading(false);
      }
      console.log(data);
    } catch (error) {
      setIsLoading(false);
      console.error("Error:", error);
    }
  };

  const saveMailBoxData = async () => {
    try {
      setIsLoading(true);

      const data = await saveMailBoxDataToApi(
        mailboxData,
        campaignId?.id as string
      );

      if (data) {
        setIsLoading(false);
      }
      console.log(data);
    } catch (error) {
      setIsLoading(false);
      console.error("Error:", error);
    }
  };

  const saveCampaignData = async () => {
    try {
      setIsLoading(true);
      const data = await saveCampaign(campaignId?.id as string);
      if (data) {
        setIsLoading(false);
        //dispatch(setCreateCampaignResponse(data));
        navigate("../campaigns");
      }
      console.log(data);
    } catch (error) {
      setIsLoading(false);
      console.error("Error", error);
    }
  };

  const handleNextClick = () => {
    if (currentStep === 1) {
      saveCollectionIds();
    }
    if (currentStep === 2) {
      if (!isFormValid) return;
      else {
        saveCustomisationsData();
      }
    }
    if (currentStep === 3) {
      saveMessagesForOutreach();
    }
    if (currentStep === 4) {
      saveMailBoxData();
    }
    if (currentStep === 5) {
      saveCampaignData();
    }
    dispatch(nextStep());
  };

  const handleBackClick = () => {
    dispatch(prevStep());
  };

  const handleCancelClick = () => {
    setDiscardModal(true);
  };

  const handleYesClick = () => {
    navigate("../campaign");
  };

  const isNextButtonDisabled = () => {
    if (currentStep === 1) return selectedCollectionIds?.length === 0;
    if (currentStep === 2) return !isFormValid;
    if (currentStep === 4) return !isFormValid;
    return false;
  };

  const adjustInputWidth = (input: HTMLInputElement) => {
    const tmp = document.createElement("span");
    tmp.style.font = window.getComputedStyle(input).font;
    tmp.style.visibility = "hidden";
    tmp.style.position = "absolute";
    tmp.textContent = input.value || input.placeholder;
    document.body.appendChild(tmp);
    const width = tmp.getBoundingClientRect().width;
    document.body.removeChild(tmp);
    input.style.width = `${width + 10}px`;
  };

  useEffect(() => {
    if (isEditing && inputRef.current) {
      adjustInputWidth(inputRef.current);
    }
  }, [isEditing, name]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, [currentStep]);

  return {
    steps: [
      { id: 1, name: "Select Collection" },
      { id: 2, name: "Customise" },
      { id: 3, name: "Edit Message" },
      { id: 4, name: "Mailboxes" },
      { id: 5, name: "Summary" },
    ],
    currentStep,
    name,
    isEditing,
    isFormValid,
    isLoading,
    inputRef,
    prevStep,
    showCampaignNameModal,
    alreadyCampaignName,
    discardModal,
    setShowCampaignNameModal,
    handleEditCampaignName,
    handleEditClick,
    handleSave,
    handleFormSubmit,
    handleNextClick,
    handleBackClick,
    isNextButtonDisabled,
    adjustInputWidth,
    setIsFormValid,
    setIsLoading,
    dispatch,
    handleMailboxData,
    handleCampaignNameModalClose,
    handleCancelClick,
    setDiscardModal,
    handleYesClick,
  };
};

export default useCampaign;
