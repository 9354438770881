import React from 'react';
import { Button } from 'components/shadcn/ui/button';
import SelectCollection from '../campaign-steps/select-collection/selectCollection';
import EmailEditor from '../campaign-steps/edit-message/editMessage';
import CampaignSummary from '../campaign-steps/summary/summary';
import SvgEditPencilIcon from 'components/common/iconComponents/editPencilIcon';
import Stepper from '../Stepper';
import Loader from 'components/common/loader';
import useCampaign from './useCampaignLayout';
import SetupCustomisations from '../campaign-steps/setup-customisations/setupCustmisations';
import SetUpMailbox from '../campaign-steps/setup-mailbox/setupMailbox';
import lang from 'lang';
import CampaignModal from 'components/popUps/editCampiagnNamePopUp';
import DiscardCampaignSetup from 'components/popUps/discardCampaignSetupPopup';

const CampaignLayout: React.FC = () => {
    const { campaign: campaignCopy } = lang;
    const {
        steps,
        currentStep,
        name,
        isLoading,
        alreadyCampaignName,
        showCampaignNameModal,
        discardModal,
        setShowCampaignNameModal,
        handleSave,
        handleFormSubmit,
        handleNextClick,
        handleBackClick,
        handleCancelClick,
        handleYesClick,
        isNextButtonDisabled,
        setIsFormValid,
        handleMailboxData,
        setDiscardModal,
    } = useCampaign();

    const renderStepContent = () => {
        switch (currentStep) {
            case 1: return <SelectCollection />;
            case 2: return <SetupCustomisations onFormSubmit={handleFormSubmit} setIsFormValid={setIsFormValid} />;
            case 3: return <EmailEditor />;
            case 4: return <SetUpMailbox setIsFormValid={setIsFormValid} onDataSubmit={handleMailboxData} />;
            case 5: return <CampaignSummary />;
            default: return;
        }
    };

    return (
        <div className="fullScreen">
            {isLoading && <Loader />}
            <CampaignModal isOpen={showCampaignNameModal} onClose={() => setShowCampaignNameModal(false)} onSave={(name) => handleSave(name)} value={alreadyCampaignName} />
            <DiscardCampaignSetup isOpen={discardModal} onClose={() => setDiscardModal(false)} onYes={handleYesClick} />
            <div className="container max-w-[80%]">
                <div className={`savedFiltersScreen pb-0`}>
                    <div className="sfTitles flex flex-col gap-5">
                        <div className="display-sm semibold text-gray-900 flex flex-row align-center gap-2">
                            {name}
                            {currentStep === 1 && <button onClick={() => setShowCampaignNameModal(true)}><SvgEditPencilIcon /></button>}
                        </div>
                        <div className="font-inter text-sm font-normal leading-5 text-center text-gray-600">{campaignCopy.createCampaignSubtext}</div>
                    </div>
                    <div className='flex flex-col gap-6 w-full h-full'>
                        <Stepper currentStep={currentStep} steps={steps} />
                        <div className="max-h-[70%] bg-[#F2F4F7] p-14">{renderStepContent()}</div>
                        <div className="flex flex-row justify-between gap-6">
                            <Button variant="secondary" className='bg-[white]' onClick={handleCancelClick}>
                                {campaignCopy.cancel}
                            </Button>
                            <div className="flex flex-row justify-end gap-6">
                                <Button variant="secondary" className='bg-[white]' onClick={handleBackClick} disabled={currentStep === 1}>
                                    {campaignCopy.back}
                                </Button>
                                <Button variant="primary" onClick={handleNextClick} disabled={isNextButtonDisabled()}>
                                    <div className="text-white font-inter text-sm font-semibold leading-5 text-left underline-from-font">
                                        {currentStep === 5 ? campaignCopy.startCampaign : campaignCopy.next}
                                    </div>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default CampaignLayout;
