export const ISIC_CODES = [
    {
        id: "A",
        sicCode: "A",
        title: "Agriculture, forestry and fishing",
        subValues: [
            {
                id: "1",
                sicCode: "1",
                title:
                    "Crop and animal production, hunting and related service activities",
                subValues: [
                    {
                        id: "11",
                        sicCode: "11",
                        title: "Growing of non-perennial crops",
                        subValues: [
                            {
                                id: "0111",
                                sicCode: "111",
                                title:
                                    "Growing of cereals (except rice), leguminous crops and oil seeds",
                                subValues: [],
                            },
                            {
                                id: "0112",
                                sicCode: "112",
                                title: "Growing of rice",
                                subValues: [],
                            },
                            {
                                id: "0113",
                                sicCode: "113",
                                title: "Growing of vegetables and melons, roots and tubers",
                                subValues: [],
                            },
                            {
                                id: "0114",
                                sicCode: "114",
                                title: "Growing of sugar cane",
                                subValues: [],
                            },
                            {
                                id: "0115",
                                sicCode: "115",
                                title: "Growing of tobacco",
                                subValues: [],
                            },
                            {
                                id: "0116",
                                sicCode: "116",
                                title: "Growing of fibre crops",
                                subValues: [],
                            },
                            {
                                id: "0119",
                                sicCode: "119",
                                title: "Growing of other non-perennial crops",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "12",
                        sicCode: "12",
                        title: "Growing of perennial crops",
                        subValues: [
                            {
                                id: "0121",
                                sicCode: "121",
                                title: "Growing of grapes",
                                subValues: [],
                            },
                            {
                                id: "0122",
                                sicCode: "122",
                                title: "Growing of tropical and subtropical fruits",
                                subValues: [],
                            },
                            {
                                id: "0123",
                                sicCode: "123",
                                title: "Growing of citrus fruits",
                                subValues: [],
                            },
                            {
                                id: "0124",
                                sicCode: "124",
                                title: "Growing of pome fruits and stone fruits",
                                subValues: [],
                            },
                            {
                                id: "0125",
                                sicCode: "125",
                                title: "Growing of other tree and bush fruits and nuts",
                                subValues: [],
                            },
                            {
                                id: "0126",
                                sicCode: "126",
                                title: "Growing of oleaginous fruits",
                                subValues: [],
                            },
                            {
                                id: "0127",
                                sicCode: "127",
                                title: "Growing of beverage crops",
                                subValues: [],
                            },
                            {
                                id: "0128",
                                sicCode: "128",
                                title:
                                    "Growing of spices, aromatic, drug and pharmaceutical crops",
                                subValues: [],
                            },
                            {
                                id: "0129",
                                sicCode: "129",
                                title: "Growing of other perennial crops",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "13",
                        sicCode: "13",
                        title: "Plant propagation",
                        subValues: [
                            {
                                id: "0130",
                                sicCode: "130",
                                title: "Plant propagation",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "14",
                        sicCode: "14",
                        title: "Animal production",
                        subValues: [
                            {
                                id: "0141",
                                sicCode: "141",
                                title: "Raising of cattle and buffaloes",
                                subValues: [],
                            },
                            {
                                id: "0142",
                                sicCode: "142",
                                title: "Raising of horses and other equines",
                                subValues: [],
                            },
                            {
                                id: "0143",
                                sicCode: "143",
                                title: "Raising of camels and camelids",
                                subValues: [],
                            },
                            {
                                id: "0144",
                                sicCode: "144",
                                title: "Raising of sheep and goats",
                                subValues: [],
                            },
                            {
                                id: "0145",
                                sicCode: "145",
                                title: "Raising of swine/pigs",
                                subValues: [],
                            },
                            {
                                id: "0146",
                                sicCode: "146",
                                title: "Raising of poultry",
                                subValues: [],
                            },
                            {
                                id: "0149",
                                sicCode: "149",
                                title: "Raising of other animals",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "15",
                        sicCode: "15",
                        title: "Mixed farming",
                        subValues: [
                            {
                                id: "0150",
                                sicCode: "150",
                                title: "Mixed farming",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "16",
                        sicCode: "16",
                        title:
                            "Support activities to agriculture and post-harvest crop activities",
                        subValues: [
                            {
                                id: "0161",
                                sicCode: "161",
                                title: "Support activities for crop production",
                                subValues: [],
                            },
                            {
                                id: "0162",
                                sicCode: "162",
                                title: "Support activities for animal production",
                                subValues: [],
                            },
                            {
                                id: "0163",
                                sicCode: "163",
                                title: "Post-harvest crop activities",
                                subValues: [],
                            },
                            {
                                id: "0164",
                                sicCode: "164",
                                title: "Seed processing for propagation",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "17",
                        sicCode: "17",
                        title:
                            "Hunting, trapping and related service activities",
                        subValues: [
                            {
                                id: "0170",
                                sicCode: "170",
                                title: "Hunting, trapping and related service activities",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "2",
                sicCode: "2",
                title: "Forestry and logging",
                subValues: [
                    {
                        id: "21",
                        sicCode: "21",
                        title: "Silviculture and other forestry activities",
                        subValues: [
                            {
                                id: "0210",
                                sicCode: "210",
                                title: "Silviculture and other forestry activities",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "22",
                        sicCode: "22",
                        title: "Logging",
                        subValues: [
                            { id: "0220", sicCode: "220", title: "Logging", subValues: [] },
                        ],
                    },
                    {
                        id: "23",
                        sicCode: "23",
                        title: "Gathering of non-wood forest products",
                        subValues: [
                            {
                                id: "0230",
                                sicCode: "230",
                                title: "Gathering of non-wood forest products",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "24",
                        sicCode: "24",
                        title: "Support services to forestry",
                        subValues: [
                            {
                                id: "0240",
                                sicCode: "240",
                                title: "Support services to forestry",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "03",
                sicCode: "03",
                title: "Fishing and aquaculture",
                subValues: [
                    {
                        id: "31",
                        sicCode: "31",
                        title: "Fishing",
                        subValues: [
                            {
                                id: "311",
                                sicCode: "311",
                                title: "Marine fishing",
                                subValues: [],
                            },
                            {
                                id: "312",
                                sicCode: "312",
                                title: "Freshwater fishing",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "32",
                        sicCode: "32",
                        title: "Aquaculture",
                        subValues: [
                            {
                                id: "321",
                                sicCode: "321",
                                title: "Marine aquaculture",
                                subValues: [],
                            },
                            {
                                id: "322",
                                sicCode: "322",
                                title: "Freshwater aquaculture",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        id: "B",
        sicCode: "B",
        title: "Mining and quarrying",
        subValues: [

            {
                id: "5",
                sicCode: "5",
                title: "Mining of coal and lignite",
                subValues: [
                    {
                        id: "51",
                        sicCode: "51",
                        title: "Mining of hard coal",
                        subValues: [
                            {
                                id: "510",
                                sicCode: "510",
                                title: "Mining of hard coal",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "52",
                        sicCode: "52",
                        title: "Mining of lignite",
                        subValues: [
                            {
                                id: "520",
                                sicCode: "520",
                                title: "Mining of lignite",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "6",
                sicCode: "6",
                title: "Extraction of crude petroleum and natural gas",
                subValues: [
                    {
                        id: "61",
                        sicCode: "61",
                        title: "Extraction of crude petroleum",
                        subValues: [
                            {
                                id: "610",
                                sicCode: "610",
                                title: "Extraction of crude petroleum",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "62",
                        sicCode: "62",
                        title: "Extraction of natural gas",
                        subValues: [
                            {
                                id: "620",
                                sicCode: "620",
                                title: "Extraction of natural gas",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "7",
                sicCode: "7",
                title: "Mining of metal ores",
                subValues: [
                    {
                        id: "71",
                        sicCode: "71",
                        title: "Mining of iron ores",
                        subValues: [
                            {
                                id: "0710",
                                sicCode: "710",
                                title: "Mining of iron ores",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "72",
                        sicCode: "72",
                        title: "Mining of non-ferrous metal ores",
                        subValues: [
                            {
                                id: "0721",
                                sicCode: "721",
                                title: "Mining of uranium and thorium ores",
                                subValues: [],
                            },
                            {
                                id: "0729",
                                sicCode: "729",
                                title: "Mining of other non-ferrous metal ores",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "8",
                sicCode: "8",
                title: "Other mining and quarrying",
                subValues: [
                    {
                        id: "81",
                        sicCode: "81",
                        title: "Quarrying of stone, sand and clay",
                        subValues: [
                            {
                                id: "0810",
                                sicCode: "810",
                                title: "Quarrying of stone, sand and clay",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "89",
                        sicCode: "89",
                        title: "Mining and quarrying n.e.c.",
                        subValues: [
                            {
                                id: "0891",
                                sicCode: "891",
                                title: "Mining of chemical and fertilizer minerals",
                                subValues: [],
                            },
                            {
                                id: "0892",
                                sicCode: "892",
                                title: "Extraction of peat",
                                subValues: [],
                            },
                            {
                                id: "0893",
                                sicCode: "893",
                                title: "Extraction of salt",
                                subValues: [],
                            },
                            {
                                id: "0899",
                                sicCode: "899",
                                title: "Other mining and quarrying n.e.c.",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "9",
                sicCode: "9",
                title: "Mining support service activities",
                subValues: [
                    {
                        id: "91",
                        sicCode: "91",
                        title:
                            "Support activities for petroleum and natural gas extraction",
                        subValues: [
                            {
                                id: "0910",
                                sicCode: "910",
                                title:
                                    "Support activities for petroleum and natural gas extraction",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "99",
                        sicCode: "99",
                        title: "Support activities for other mining and quarrying",
                        subValues: [
                            {
                                id: "0990",
                                sicCode: "990",
                                title: "Support activities for other mining and quarrying",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        id: "C",
        sicCode: "C",
        title: "Manufacturing",
        subValues: [

            {
                id: "10",
                sicCode: "10",
                title: "Manufacture of food products",
                subValues: [
                    {
                        id: "101",
                        sicCode: "101",
                        title: "Processing and preserving of meat",
                        subValues: [
                            {
                                id: "1010",
                                sicCode: "1010",
                                title: "Processing and preserving of meat",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "102",
                        sicCode: "102",
                        title:
                            "Processing and preserving of fish, crustaceans and molluscs",
                        subValues: [
                            {
                                id: "1020",
                                sicCode: "1020",
                                title:
                                    "Processing and preserving of fish, crustaceans and molluscs",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "103",
                        sicCode: "103",
                        title: "Processing and preserving of fruit and vegetables",
                        subValues: [
                            {
                                id: "1030",
                                sicCode: "1030",
                                title: "Processing and preserving of fruit and vegetables",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "104",
                        sicCode: "104",
                        title: "Manufacture of vegetable and animal oils and fats",
                        subValues: [
                            {
                                id: "1040",
                                sicCode: "1040",
                                title: "Manufacture of vegetable and animal oils and fats",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "105",
                        sicCode: "105",
                        title: "Manufacture of dairy products",
                        subValues: [
                            {
                                id: "1050",
                                sicCode: "1050",
                                title: "Manufacture of dairy products",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "106",
                        sicCode: "106",
                        title:
                            "Manufacture of grain mill products, starches and starch products",
                        subValues: [
                            {
                                id: "1061",
                                sicCode: "1061",
                                title: "Manufacture of grain mill products",
                                subValues: [],
                            },
                            {
                                id: "1062",
                                sicCode: "1062",
                                title: "Manufacture of starches and starch products",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "107",
                        sicCode: "107",
                        title: "Manufacture of other food products",
                        subValues: [
                            {
                                id: "1071",
                                sicCode: "1071",
                                title: "Manufacture of bakery products",
                                subValues: [],
                            },
                            {
                                id: "1072",
                                sicCode: "1072",
                                title: "Manufacture of sugar",
                                subValues: [],
                            },
                            {
                                id: "1073",
                                sicCode: "1073",
                                title:
                                    "Manufacture of cocoa, chocolate and sugar confectionery",
                                subValues: [],
                            },
                            {
                                id: "1074",
                                sicCode: "1074",
                                title:
                                    "Manufacture of macaroni, noodles, couscous and similar farinaceous products",
                                subValues: [],
                            },
                            {
                                id: "1075",
                                sicCode: "1075",
                                title: "Manufacture of prepared meals and dishes",
                                subValues: [],
                            },
                            {
                                id: "1079",
                                sicCode: "1079",
                                title: "Manufacture of other food products n.e.c.",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "108",
                        sicCode: "108",
                        title: "Manufacture of prepared animal feeds",
                        subValues: [
                            {
                                id: "1080",
                                sicCode: "1080",
                                title: "Manufacture of prepared animal feeds",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "11",
                sicCode: "11",
                title: "Manufacture of beverages",
                subValues: [
                    {
                        id: "110",
                        sicCode: "110",
                        title: "Manufacture of beverages",
                        subValues: [
                            {
                                id: "1101",
                                sicCode: "1101",
                                title: "Distilling, rectifying and blending of spirits",
                                subValues: [],
                            },
                            {
                                id: "1102",
                                sicCode: "1102",
                                title: "Manufacture of wines",
                                subValues: [],
                            },
                            {
                                id: "1103",
                                sicCode: "1103",
                                title: "Manufacture of malt liquors and malt",
                                subValues: [],
                            },
                            {
                                id: "1104",
                                sicCode: "1104",
                                title:
                                    "Manufacture of soft drinks; production of mineral waters and other bottled waters",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "12",
                sicCode: "12",
                title: "Manufacture of tobacco products",
                subValues: [
                    {
                        id: "120",
                        sicCode: "120",
                        title: "Manufacture of tobacco products",
                        subValues: [
                            {
                                id: "1200",
                                sicCode: "1200",
                                title: "Manufacture of tobacco products",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "13",
                sicCode: "13",
                title: "Manufacture of textiles",
                subValues: [
                    {
                        id: "131",
                        sicCode: "131",
                        title: "Spinning, weaving and finishing of textiles",
                        subValues: [
                            {
                                id: "1311",
                                sicCode: "1311",
                                title: "Preparation and spinning of textile fibres",
                                subValues: [],
                            },
                            {
                                id: "1312",
                                sicCode: "1312",
                                title: "Weaving of textiles",
                                subValues: [],
                            },
                            {
                                id: "1313",
                                sicCode: "1313",
                                title: "Finishing of textiles",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "139",
                        sicCode: "139",
                        title: "Manufacture of other textiles",
                        subValues: [
                            {
                                id: "1391",
                                sicCode: "1391",
                                title: "Manufacture of knitted and crocheted fabrics",
                                subValues: [],
                            },
                            {
                                id: "1392",
                                sicCode: "1392",
                                title:
                                    "Manufacture of made-up textile articles, except apparel",
                                subValues: [],
                            },
                            {
                                id: "1393",
                                sicCode: "1393",
                                title: "Manufacture of carpets and rugs",
                                subValues: [],
                            },
                            {
                                id: "1394",
                                sicCode: "1394",
                                title: "Manufacture of cordage, rope, twine and netting",
                                subValues: [],
                            },
                            {
                                id: "1399",
                                sicCode: "1399",
                                title: "Manufacture of other textiles n.e.c.",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "14",
                sicCode: "14",
                title: "Manufacture of wearing apparel",
                subValues: [

                    {
                        id: "141",
                        sicCode: "141",
                        title: "Manufacture of wearing apparel, except fur apparel",
                        subValues: [
                            {
                                id: "1410",
                                sicCode: "1410",
                                title: "Manufacture of wearing apparel, except fur apparel",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "142",
                        sicCode: "142",
                        title: "Manufacture of articles of fur",
                        subValues: [
                            {
                                id: "1420",
                                sicCode: "1420",
                                title: "Manufacture of articles of fur",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "143",
                        sicCode: "143",
                        title: "Manufacture of knitted and crocheted apparel",
                        subValues: [
                            {
                                id: "1430",
                                sicCode: "1430",
                                title: "Manufacture of knitted and crocheted apparel",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "15",
                sicCode: "15",
                title: "Manufacture of leather and related products",
                subValues: [
                    {
                        id: "151",
                        sicCode: "151",
                        title:
                            "Tanning and dressing of leather; manufacture of luggage, handbags, saddlery and harness; dressing and dyeing of fur",
                        subValues: [
                            {
                                id: "1511",
                                sicCode: "1511",
                                title:
                                    "Tanning and dressing of leather; dressing and dyeing of fur",
                                subValues: [],
                            },
                            {
                                id: "1512",
                                sicCode: "1512",
                                title:
                                    "Manufacture of luggage, handbags and the like, saddlery and harness",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "152",
                        sicCode: "152",
                        title: "Manufacture of footwear",
                        subValues: [
                            {
                                id: "1520",
                                sicCode: "1520",
                                title: "Manufacture of footwear",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "16",
                sicCode: "16",
                title:
                    "Manufacture of wood and of products of wood and cork, except furniture; manufacture of articles of straw and plaiting materials",
                subValues: [
                    {
                        id: "161",
                        sicCode: "161",
                        title: "Sawmilling and planing of wood",
                        subValues: [
                            {
                                id: "1610",
                                sicCode: "1610",
                                title: "Sawmilling and planing of wood",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "162",
                        sicCode: "162",
                        title: "Manufacture of products of wood, cork, straw and plaiting materials",
                        subValues: [
                            {
                                id: "1621",
                                sicCode: "1621",
                                title: "Manufacture of veneer sheets and wood-based panels",
                                subValues: [],
                            },
                            {
                                id: "1622",
                                sicCode: "1622",
                                title: "Manufacture of builders' carpentry and joinery",
                                subValues: [],
                            },
                            {
                                id: "1623",
                                sicCode: "1623",
                                title: "Manufacture of wooden containers",
                                subValues: [],
                            },
                            {
                                id: "1629",
                                sicCode: "1629",
                                title:
                                    "Manufacture of other products of wood; manufacture of articles of cork, straw and plaiting materials",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "17",
                sicCode: "17",
                title: "Manufacture of paper and paper products",
                subValues: [
                    {
                        id: "170",
                        sicCode: "170",
                        title: "Manufacture of paper and paper products",
                        subValues: [
                            {
                                id: "1701",
                                sicCode: "1701",
                                title: "Manufacture of pulp, paper and paperboard",
                                subValues: [],
                            },
                            {
                                id: "1702",
                                sicCode: "1702",
                                title:
                                    "Manufacture of corrugated paper and paperboard and of containers of paper and paperboard",
                                subValues: [],
                            },
                            {
                                id: "1709",
                                sicCode: "1709",
                                title: "Manufacture of other articles of paper and paperboard",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "18",
                sicCode: "18",
                title: "Printing and reproduction of recorded media",
                subValues: [
                    {
                        id: "181",
                        sicCode: "181",
                        title: "Printing and service activities related to printing",
                        subValues: [
                            { id: "1811", sicCode: "1811", title: "Printing", subValues: [] },
                            {
                                id: "1812",
                                sicCode: "1812",
                                title: "Service activities related to printing",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "182",
                        sicCode: "182",
                        title: "Reproduction of recorded media",
                        subValues: [
                            {
                                id: "1820",
                                sicCode: "1820",
                                title: "Reproduction of recorded media",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "19",
                sicCode: "19",
                title: "Manufacture of coke and refined petroleum products",
                subValues: [
                    {
                        id: "191",
                        sicCode: "191",
                        title: "Manufacture of coke oven products",
                        subValues: [
                            {
                                id: "1910",
                                sicCode: "1910",
                                title: "Manufacture of coke oven products",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "192",
                        sicCode: "192",
                        title: "Manufacture of refined petroleum products",
                        subValues: [
                            {
                                id: "1920",
                                sicCode: "1920",
                                title: "Manufacture of refined petroleum products",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "20",
                sicCode: "20",
                title: "Manufacture of chemicals and chemical products",
                subValues: [
                    {
                        id: "201",
                        sicCode: "201",
                        title:
                            "Manufacture of basic chemicals, fertilizers and nitrogen compounds, plastics and synthetic rubber in primary forms",
                        subValues: [
                            {
                                id: "2011",
                                sicCode: "2011",
                                title: "Manufacture of basic chemicals",
                                subValues: [],
                            },
                            {
                                id: "2012",
                                sicCode: "2012",
                                title: "Manufacture of fertilizers and nitrogen compounds",
                                subValues: [],
                            },
                            {
                                id: "2013",
                                sicCode: "2013",
                                title:
                                    "Manufacture of plastics and synthetic rubber in primary forms",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "202",
                        sicCode: "202",
                        title: "Manufacture of other chemical products",
                        subValues: [
                            {
                                id: "2021",
                                sicCode: "2021",
                                title:
                                    "Manufacture of pesticides and other agrochemical products",
                                subValues: [],
                            },
                            {
                                id: "2022",
                                sicCode: "2022",
                                title:
                                    "Manufacture of paints, varnishes and similar coatings, printing ink and mastics",
                                subValues: [],
                            },
                            {
                                id: "2023",
                                sicCode: "2023",
                                title:
                                    "Manufacture of soap and detergents, cleaning and polishing preparations, perfumes and toilet preparations",
                                subValues: [],
                            },
                            {
                                id: "2029",
                                sicCode: "2029",
                                title: "Manufacture of other chemical products n.e.c.",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "203",
                        sicCode: "203",
                        title: "Manufacture of man-made fibres",
                        subValues: [
                            {
                                id: "2030",
                                sicCode: "2030",
                                title: "Manufacture of man-made fibres",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "21",
                sicCode: "21",
                title: "Manufacture of pharmaceuticals, medicinal chemical and botanical products",
                subValues: [
                    {
                        id: "210",
                        sicCode: "210",
                        title: "Manufacture of pharmaceuticals, medicinal chemical and botanical products",
                        subValues: [
                            {
                                id: "2100",
                                sicCode: "2100",
                                title:
                                    "Manufacture of pharmaceuticals, medicinal chemical and botanical products",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "22",
                sicCode: "22",
                title: "Manufacture of rubber and plastics products",
                subValues: [
                    {
                        id: "221",
                        sicCode: "221",
                        title: "Manufacture of rubber products",
                        subValues: [
                            {
                                id: "2211",
                                sicCode: "2211",
                                title:
                                    "Manufacture of rubber tyres and tubes; retreading and rebuilding of rubber tyres",
                                subValues: [],
                            },
                            {
                                id: "2219",
                                sicCode: "2219",
                                title: "Manufacture of other rubber products",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "222",
                        sicCode: "222",
                        title: "Manufacture of plastics products",
                        subValues: [
                            {
                                id: "2220",
                                sicCode: "2220",
                                title: "Manufacture of plastics products",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "23",
                sicCode: "23",
                title: "Manufacture of other non-metallic mineral products",
                subValues: [

                    {
                        id: "231",
                        sicCode: "231",
                        title: "Manufacture of glass and glass products",
                        subValues: [
                            {
                                id: "2310",
                                sicCode: "2310",
                                title: "Manufacture of glass and glass products",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "239",
                        sicCode: "239",
                        title: "Manufacture of non-metallic mineral products n.e.c.",
                        subValues: [
                            {
                                id: "2391",
                                sicCode: "2391",
                                title: "Manufacture of refractory products",
                                subValues: [],
                            },
                            {
                                id: "2392",
                                sicCode: "2392",
                                title: "Manufacture of clay building materials",
                                subValues: [],
                            },
                            {
                                id: "2393",
                                sicCode: "2393",
                                title: "Manufacture of other porcelain and ceramic products",
                                subValues: [],
                            },
                            {
                                id: "2394",
                                sicCode: "2394",
                                title: "Manufacture of cement, lime and plaster",
                                subValues: [],
                            },
                            {
                                id: "2395",
                                sicCode: "2395",
                                title:
                                    "Manufacture of articles of concrete, cement and plaster",
                                subValues: [],
                            },
                            {
                                id: "2396",
                                sicCode: "2396",
                                title: "Cutting, shaping and finishing of stone",
                                subValues: [],
                            },
                            {
                                id: "2399",
                                sicCode: "2399",
                                title:
                                    "Manufacture of other non-metallic mineral products n.e.c.",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "24",
                sicCode: "24",
                title: "Manufacture of basic metals",
                subValues: [

                    {
                        id: "241",
                        sicCode: "241",
                        title: "Manufacture of basic iron and steel",
                        subValues: [
                            {
                                id: "2410",
                                sicCode: "2410",
                                title: "Manufacture of basic iron and steel",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "242",
                        sicCode: "242",
                        title: "Manufacture of basic precious and other non-ferrous metals",
                        subValues: [
                            {
                                id: "2420",
                                sicCode: "2420",
                                title:
                                    "Manufacture of basic precious and other non-ferrous metals",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "243",
                        sicCode: "243",
                        title: "Casting of metals",
                        subValues: [
                            {
                                id: "2431",
                                sicCode: "2431",
                                title: "Casting of iron and steel",
                                subValues: [],
                            },
                            {
                                id: "2432",
                                sicCode: "2432",
                                title: "Casting of non-ferrous metals",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "25",
                sicCode: "25",
                title:
                    "Manufacture of fabricated metal products, except machinery and equipment",
                subValues: [

                    {
                        id: "251",
                        sicCode: "251",
                        title:
                            "Manufacture of structural metal products, tanks, reservoirs and steam generators",
                        subValues: [
                            {
                                id: "2511",
                                sicCode: "2511",
                                title: "Manufacture of structural metal products",
                                subValues: [],
                            },
                            {
                                id: "2512",
                                sicCode: "2512",
                                title:
                                    "Manufacture of tanks, reservoirs and containers of metal",
                                subValues: [],
                            },
                            {
                                id: "2513",
                                sicCode: "2513",
                                title:
                                    "Manufacture of steam generators, except central heating hot water boilers",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "252",
                        sicCode: "252",
                        title: "Manufacture of weapons and ammunition",
                        subValues: [
                            {
                                id: "2520",
                                sicCode: "2520",
                                title: "Manufacture of weapons and ammunition",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "259",
                        sicCode: "259",
                        title:
                            "Manufacture of other fabricated metal products; metalworking service activities",
                        subValues: [
                            {
                                id: "2591",
                                sicCode: "2591",
                                title:
                                    "Forging, pressing, stamping and roll-forming of metal; powder metallurgy",
                                subValues: [],
                            },
                            {
                                id: "2592",
                                sicCode: "2592",
                                title: "Treatment and coating of metals; machining",
                                subValues: [],
                            },
                            {
                                id: "2593",
                                sicCode: "2593",
                                title:
                                    "Manufacture of cutlery, hand tools and general hardware",
                                subValues: [],
                            },
                            {
                                id: "2599",
                                sicCode: "2599",
                                title: "Manufacture of other fabricated metal products n.e.c.",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "26",
                sicCode: "26",
                title: "Manufacture of computer, electronic and optical products",
                subValues: [

                    {
                        id: "261",
                        sicCode: "261",
                        title: "Manufacture of electronic components and boards",
                        subValues: [
                            {
                                id: "2610",
                                sicCode: "2610",
                                title: "Manufacture of electronic components and boards",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "262",
                        sicCode: "262",
                        title: "Manufacture of computers and peripheral equipment",
                        subValues: [
                            {
                                id: "2620",
                                sicCode: "2620",
                                title: "Manufacture of computers and peripheral equipment",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "263",
                        sicCode: "263",
                        title: "Manufacture of communication equipment",
                        subValues: [
                            {
                                id: "2630",
                                sicCode: "2630",
                                title: "Manufacture of communication equipment",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "264",
                        sicCode: "264",
                        title: "Manufacture of consumer electronics",
                        subValues: [
                            {
                                id: "2640",
                                sicCode: "2640",
                                title: "Manufacture of consumer electronics",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "265",
                        sicCode: "265",
                        title:
                            "Manufacture of measuring, testing, navigating and control equipment; watches and clocks",
                        subValues: [
                            {
                                id: "2651",
                                sicCode: "2651",
                                title:
                                    "Manufacture of measuring, testing, navigating and control equipment",
                                subValues: [],
                            },
                            {
                                id: "2652",
                                sicCode: "2652",
                                title: "Manufacture of watches and clocks",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "266",
                        sicCode: "266",
                        title:
                            "Manufacture of irradiation, electromedical and electrotherapeutic equipment",
                        subValues: [
                            {
                                id: "2660",
                                sicCode: "2660",
                                title:
                                    "Manufacture of irradiation, electromedical and electrotherapeutic equipment",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "267",
                        sicCode: "267",
                        title:
                            "Manufacture of optical instruments and photographic equipment",
                        subValues: [
                            {
                                id: "2670",
                                sicCode: "2670",
                                title:
                                    "Manufacture of optical instruments and photographic equipment",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "268",
                        sicCode: "268",
                        title: "Manufacture of magnetic and optical media",
                        subValues: [
                            {
                                id: "2680",
                                sicCode: "2680",
                                title: "Manufacture of magnetic and optical media",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "27",
                sicCode: "27",
                title: "Manufacture of electrical equipment",
                subValues: [

                    {
                        id: "271",
                        sicCode: "271",
                        title:
                            "Manufacture of electric motors, generators, transformers and electricity distribution and control apparatus",
                        subValues: [
                            {
                                id: "2710",
                                sicCode: "2710",
                                title:
                                    "Manufacture of electric motors, generators, transformers and electricity distribution and control apparatus",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "272",
                        sicCode: "272",
                        title: "Manufacture of batteries and accumulators",
                        subValues: [
                            {
                                id: "2720",
                                sicCode: "2720",
                                title: "Manufacture of batteries and accumulators",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "273",
                        sicCode: "273",
                        title: "Manufacture of wiring and wiring devices",
                        subValues: [
                            {
                                id: "2731",
                                sicCode: "2731",
                                title: "Manufacture of fibre optic cables",
                                subValues: [],
                            },
                            {
                                id: "2732",
                                sicCode: "2732",
                                title:
                                    "Manufacture of other electronic and electric wires and cables",
                                subValues: [],
                            },
                            {
                                id: "2733",
                                sicCode: "2733",
                                title: "Manufacture of wiring devices",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "274",
                        sicCode: "274",
                        title: "Manufacture of electric lighting equipment",
                        subValues: [
                            {
                                id: "2740",
                                sicCode: "2740",
                                title: "Manufacture of electric lighting equipment",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "275",
                        sicCode: "275",
                        title: "Manufacture of domestic appliances",
                        subValues: [
                            {
                                id: "2750",
                                sicCode: "2750",
                                title: "Manufacture of domestic appliances",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "279",
                        sicCode: "279",
                        title: "Manufacture of other electrical equipment",
                        subValues: [
                            {
                                id: "2790",
                                sicCode: "2790",
                                title: "Manufacture of other electrical equipment",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "28",
                sicCode: "28",
                title: "Manufacture of machinery and equipment n.e.c.",
                subValues: [

                    {
                        id: "281",
                        sicCode: "281",
                        title: "Manufacture of general-purpose machinery",
                        subValues: [
                            {
                                id: "2811",
                                sicCode: "2811",
                                title:
                                    "Manufacture of engines and turbines, except aircraft, vehicle and cycle engines",
                                subValues: [],
                            },
                            {
                                id: "2812",
                                sicCode: "2812",
                                title: "Manufacture of fluid power equipment",
                                subValues: [],
                            },
                            {
                                id: "2813",
                                sicCode: "2813",
                                title:
                                    "Manufacture of other pumps, compressors, taps and valves",
                                subValues: [],
                            },
                            {
                                id: "2814",
                                sicCode: "2814",
                                title:
                                    "Manufacture of bearings, gears, gearing and driving elements",
                                subValues: [],
                            },
                            {
                                id: "2815",
                                sicCode: "2815",
                                title: "Manufacture of ovens, furnaces and furnace burners",
                                subValues: [],
                            },
                            {
                                id: "2816",
                                sicCode: "2816",
                                title: "Manufacture of lifting and handling equipment",
                                subValues: [],
                            },
                            {
                                id: "2817",
                                sicCode: "2817",
                                title:
                                    "Manufacture of office machinery and equipment (except computers and peripheral equipment)",
                                subValues: [],
                            },
                            {
                                id: "2818",
                                sicCode: "2818",
                                title: "Manufacture of power-driven hand tools",
                                subValues: [],
                            },
                            {
                                id: "2819",
                                sicCode: "2819",
                                title: "Manufacture of other general-purpose machinery",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "282",
                        sicCode: "282",
                        title: "Manufacture of special-purpose machinery",
                        subValues: [
                            {
                                id: "2821",
                                sicCode: "2821",
                                title: "Manufacture of agricultural and forestry machinery",
                                subValues: [],
                            },
                            {
                                id: "2822",
                                sicCode: "2822",
                                title:
                                    "Manufacture of metal-forming machinery and machine tools",
                                subValues: [],
                            },
                            {
                                id: "2823",
                                sicCode: "2823",
                                title: "Manufacture of machinery for metallurgy",
                                subValues: [],
                            },
                            {
                                id: "2824",
                                sicCode: "2824",
                                title:
                                    "Manufacture of machinery for mining, quarrying and construction",
                                subValues: [],
                            },
                            {
                                id: "2825",
                                sicCode: "2825",
                                title:
                                    "Manufacture of machinery for food, beverage and tobacco processing",
                                subValues: [],
                            },
                            {
                                id: "2826",
                                sicCode: "2826",
                                title:
                                    "Manufacture of machinery for textile, apparel and leather production",
                                subValues: [],
                            },
                            {
                                id: "2829",
                                sicCode: "2829",
                                title: "Manufacture of other special-purpose machinery",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "29",
                sicCode: "29",
                title: "Manufacture of motor vehicles, trailers and semi-trailers",
                subValues: [

                    {
                        id: "291",
                        sicCode: "291",
                        title: "Manufacture of motor vehicles",
                        subValues: [
                            {
                                id: "2910",
                                sicCode: "2910",
                                title: "Manufacture of motor vehicles",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "292",
                        sicCode: "292",
                        title:
                            "Manufacture of bodies (coachwork) for motor vehicles; manufacture of trailers and semi-trailers",
                        subValues: [
                            {
                                id: "2920",
                                sicCode: "2920",
                                title:
                                    "Manufacture of bodies (coachwork) for motor vehicles; manufacture of trailers and semi-trailers",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "293",
                        sicCode: "293",
                        title: "Manufacture of parts and accessories for motor vehicles",
                        subValues: [
                            {
                                id: "2930",
                                sicCode: "2930",
                                title:
                                    "Manufacture of parts and accessories for motor vehicles",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "30",
                sicCode: "30",
                title: "Manufacture of other transport equipment",
                subValues: [

                    {
                        id: "301",
                        sicCode: "301",
                        title: "Building of ships and boats",
                        subValues: [
                            {
                                id: "3011",
                                sicCode: "3011",
                                title: "Building of ships and floating structures",
                                subValues: [],
                            },
                            {
                                id: "3012",
                                sicCode: "3012",
                                title: "Building of pleasure and sporting boats",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "302",
                        sicCode: "302",
                        title: "Manufacture of railway locomotives and rolling stock",
                        subValues: [
                            {
                                id: "3020",
                                sicCode: "3020",
                                title: "Manufacture of railway locomotives and rolling stock",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "303",
                        sicCode: "303",
                        title: "Manufacture of air and spacecraft and related machinery",
                        subValues: [
                            {
                                id: "3030",
                                sicCode: "3030",
                                title:
                                    "Manufacture of air and spacecraft and related machinery",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "304",
                        sicCode: "304",
                        title: "Manufacture of military fighting vehicles",
                        subValues: [
                            {
                                id: "3040",
                                sicCode: "3040",
                                title: "Manufacture of military fighting vehicles",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "309",
                        sicCode: "309",
                        title: "Manufacture of transport equipment n.e.c.",
                        subValues: [
                            {
                                id: "3091",
                                sicCode: "3091",
                                title: "Manufacture of motorcycles",
                                subValues: [],
                            },
                            {
                                id: "3092",
                                sicCode: "3092",
                                title: "Manufacture of bicycles and invalid carriages",
                                subValues: [],
                            },
                            {
                                id: "3099",
                                sicCode: "3099",
                                title: "Manufacture of other transport equipment n.e.c.",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "31",
                sicCode: "31",
                title: "Manufacture of furniture",
                subValues: [
                    {
                        id: "310",
                        sicCode: "310",
                        title: "Manufacture of furniture",
                        subValues: [
                            {
                                id: "3100",
                                sicCode: "3100",
                                title: "Manufacture of furniture",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "32",
                sicCode: "32",
                title: "Other manufacturing",
                subValues: [
                    {
                        id: "321",
                        sicCode: "321",
                        title: "Manufacture of jewellery, bijouterie and related articles",
                        subValues: [
                            {
                                id: "3211",
                                sicCode: "3211",
                                title: "Manufacture of jewellery and related articles",
                                subValues: [],
                            },
                            {
                                id: "3212",
                                sicCode: "3212",
                                title:
                                    "Manufacture of imitation jewellery and related articles",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "322",
                        sicCode: "322",
                        title: "Manufacture of musical instruments",
                        subValues: [
                            {
                                id: "3220",
                                sicCode: "3220",
                                title: "Manufacture of musical instruments",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "323",
                        sicCode: "323",
                        title: "Manufacture of sports goods",
                        subValues: [
                            {
                                id: "3230",
                                sicCode: "3230",
                                title: "Manufacture of sports goods",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "324",
                        sicCode: "324",
                        title: "Manufacture of games and toys",
                        subValues: [
                            {
                                id: "3240",
                                sicCode: "3240",
                                title: "Manufacture of games and toys",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "325",
                        sicCode: "325",
                        title: "Manufacture of medical and dental instruments and supplies",
                        subValues: [
                            {
                                id: "3250",
                                sicCode: "3250",
                                title:
                                    "Manufacture of medical and dental instruments and supplies",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "329",
                        sicCode: "329",
                        title: "Other manufacturing n.e.c.",
                        subValues: [
                            {
                                id: "3290",
                                sicCode: "3290",
                                title: "Other manufacturing n.e.c.",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "33",
                sicCode: "33",
                title: "Repair and installation of machinery and equipment",
                subValues: [
                    {
                        id: "331",
                        sicCode: "331",
                        title:
                            "Repair of fabricated metal products, machinery and equipment",
                        subValues: [
                            {
                                id: "3311",
                                sicCode: "3311",
                                title: "Repair of fabricated metal products",
                                subValues: [],
                            },
                            {
                                id: "3312",
                                sicCode: "3312",
                                title: "Repair of machinery",
                                subValues: [],
                            },
                            {
                                id: "3313",
                                sicCode: "3313",
                                title: "Repair of electronic and optical equipment",
                                subValues: [],
                            },
                            {
                                id: "3314",
                                sicCode: "3314",
                                title: "Repair of electrical equipment",
                                subValues: [],
                            },
                            {
                                id: "3315",
                                sicCode: "3315",
                                title: "Repair of transport equipment, except motor vehicles",
                                subValues: [],
                            },
                            {
                                id: "3319",
                                sicCode: "3319",
                                title: "Repair of other equipment",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "332",
                sicCode: "332",
                title: "Installation of industrial machinery and equipment",
                subValues: [
                    {
                        id: "3320",
                        sicCode: "3320",
                        title: "Installation of industrial machinery and equipment",
                        subValues: [],
                    },
                ],
            },

        ],
    },
    {
        id: "D",
        sicCode: "D",
        title: "Electricity, gas, steam and air conditioning supply",
        subValues: [
            {
                id: "35",
                sicCode: "35",
                title: "Electricity, gas, steam and air conditioning supply",
                subValues: [

                    {
                        id: "351",
                        sicCode: "351",
                        title: "Electric power generation, transmission and distribution",
                        subValues: [
                            {
                                id: "3510",
                                sicCode: "3510",
                                title:
                                    "Electric power generation, transmission and distribution",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "352",
                        sicCode: "352",
                        title:
                            "Manufacture of gas; distribution of gaseous fuels through mains",
                        subValues: [
                            {
                                id: "3520",
                                sicCode: "3520",
                                title:
                                    "Manufacture of gas; distribution of gaseous fuels through mains",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "353",
                        sicCode: "353",
                        title: "Steam and air conditioning supply",
                        subValues: [
                            {
                                id: "3530",
                                sicCode: "3530",
                                title: "Steam and air conditioning supply",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        id: "E",
        sicCode: "E",
        title:
            "Water supply; sewerage, waste management and remediation activities",
        subValues: [

            {
                id: "36",
                sicCode: "36",
                title: "Water collection, treatment and supply",
                subValues: [
                    {
                        id: "360",
                        sicCode: "360",
                        title: "Water collection, treatment and supply",
                        subValues: [
                            {
                                id: "3600",
                                sicCode: "3600",
                                title: "Water collection, treatment and supply",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "37",
                sicCode: "37",
                title: "Sewerage",
                subValues: [
                    {
                        id: "370",
                        sicCode: "370",
                        title: "Sewerage",
                        subValues: [
                            { id: "3700", sicCode: "3700", title: "Sewerage", subValues: [] },
                        ],
                    },
                ],
            },
            {
                id: "38",
                sicCode: "38",
                title:
                    "Waste collection, treatment and disposal activities; materials recovery",
                subValues: [

                    {
                        id: "381",
                        sicCode: "381",
                        title: "Waste collection",
                        subValues: [
                            {
                                id: "3811",
                                sicCode: "3811",
                                title: "Collection of non-hazardous waste",
                                subValues: [],
                            },
                            {
                                id: "3812",
                                sicCode: "3812",
                                title: "Collection of hazardous waste",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "382",
                        sicCode: "382",
                        title: "Waste treatment and disposal",
                        subValues: [
                            {
                                id: "3821",
                                sicCode: "3821",
                                title: "Treatment and disposal of non-hazardous waste",
                                subValues: [],
                            },
                            {
                                id: "3822",
                                sicCode: "3822",
                                title: "Treatment and disposal of hazardous waste",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "383",
                        sicCode: "383",
                        title: "Materials recovery",
                        subValues: [
                            {
                                id: "3830",
                                sicCode: "3830",
                                title: "Materials recovery",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "390",
                        sicCode: "390",
                        title: "Remediation activities and other waste management services",
                        subValues: [
                            {
                                id: "3900",
                                sicCode: "3900",
                                title: "Remediation activities and other waste management services",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        id: "F",
        sicCode: "F",
        title: "CONSTRUCTION",
        subValues: [
            {
                id: "41",
                sicCode: "41",
                title: "Construction of buildings",
                subValues: [
                    {
                        id: "410",
                        sicCode: "410",
                        title: "Construction of buildings",
                        subValues: [
                            {
                                id: "4100",
                                sicCode: "4100",
                                title: "Construction of buildings",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "42",
                sicCode: "42",
                title: "Civil engineering",
                subValues: [
                    {
                        id: "421",
                        sicCode: "421",
                        title: "Construction of roads and railways",
                        subValues: [
                            {
                                id: "4210",
                                sicCode: "4210",
                                title: "Construction of roads and motorways",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "422",
                        sicCode: "422",
                        title: "Construction of utility projects",
                        subValues: [
                            {
                                id: "4220",
                                sicCode: "4220",
                                title:
                                    "Construction of utility projects",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "429",
                        sicCode: "429",
                        title: "Construction of other civil engineering projects",
                        subValues: [
                            {
                                id: "4290",
                                sicCode: "4290",
                                title: "Construction of other civil engineering projects nec",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "43",
                sicCode: "43",
                title: "Specialised construction activities",
                subValues: [
                    {
                        id: "431",
                        sicCode: "431",
                        title: "Demolition and site preparation",
                        subValues: [
                            {
                                id: "4311",
                                sicCode: "4311",
                                title: "Demolition",
                                subValues: [],
                            },
                            {
                                id: "4312",
                                sicCode: "4312",
                                title: "Site preparation",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "432",
                        sicCode: "432",
                        title:
                            "Electrical, plumbing and other construction installation activities",
                        subValues: [
                            {
                                id: "4321",
                                sicCode: "4321",
                                title: "Electrical installation",
                                subValues: [],
                            },
                            {
                                id: "4322",
                                sicCode: "4322",
                                title: "Plumbing, heat and air-conditioning installation",
                                subValues: [],
                            },
                            {
                                id: "4329",
                                sicCode: "4329",
                                title: "Other construction installation",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "433",
                        sicCode: "433",
                        title: "Building completion and finishing",
                        subValues: [
                            {
                                id: "4330",
                                sicCode: "4330",
                                title: "Building completion and finishing",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "439",
                        sicCode: "439",
                        title: "Other specialised construction activities",
                        subValues: [
                            {
                                id: "4390",
                                sicCode: "4390",
                                title:
                                    "Other specialized construction activities",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        id: "G",
        sicCode: "G",
        title:
            "WHOLESALE AND RETAIL TRADE; REPAIR OF MOTOR VEHICLES AND MOTORCYCLES",
        subValues: [
            {
                id: "45",
                sicCode: "45",
                title:
                    "Wholesale and retail trade and repair of motor vehicles and motorcycles",
                subValues: [
                    {
                        id: "451",
                        sicCode: "451",
                        title: "Sale of motor vehicles",
                        subValues: [
                            {
                                id: "4510",
                                sicCode: "4510",
                                title: "Sale of motor vehicles",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "452",
                        sicCode: "452",
                        title: "Maintenance and repair of motor vehicles",
                        subValues: [
                            {
                                id: "4520",
                                sicCode: "4520",
                                title: "Maintenance and repair of motor vehicles",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "453",
                        sicCode: "453",
                        title: "Sale of motor vehicle parts and accessories",
                        subValues: [
                            {
                                id: "4530",
                                sicCode: "4530",
                                title: "Sale of motor vehicle parts and accessories",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "454",
                        sicCode: "454",
                        title:
                            "Sale, maintenance and repair of motorcycles and related parts and accessories",
                        subValues: [
                            {
                                id: "4540",
                                sicCode: "4540",
                                title:
                                    "Sale, maintenance and repair of motorcycles and related parts and accessories",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "46",
                sicCode: "46",
                title: "Wholesale trade, except of motor vehicles and motorcycles",
                subValues: [
                    {
                        id: "461",
                        sicCode: "461",
                        title: "Wholesale on a fee or contract basis",
                        subValues: [
                            {
                                id: "4610",
                                sicCode: "4610",
                                title:
                                    "Wholesale on a fee or contract basis",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "462",
                        sicCode: "462",
                        title: "Wholesale of agricultural raw materials and live animals",
                        subValues: [
                            {
                                id: "4620",
                                sicCode: "4620",
                                title:
                                    "Wholesale of agricultural raw materials and live animals",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "463",
                        sicCode: "463",
                        title: "Wholesale of food, beverages and tobacco",
                        subValues: [
                            {
                                id: "4630",
                                sicCode: "4630",
                                title: "Wholesale of fruit and vegetables",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "464",
                        sicCode: "464",
                        title: "Wholesale of household goods",
                        subValues: [
                            {
                                id: "4641",
                                sicCode: "4641",
                                title: "Wholesale of textiles, clothing and footwear",
                                subValues: [],
                            },
                            {
                                id: "4649",
                                sicCode: "46490",
                                title: "Wholesale of other household goods",
                                subValues: [],
                            },

                        ],
                    },
                    {
                        id: "465",
                        sicCode: "465",
                        title: "Wholesale of machinery, equipment and supplies",
                        subValues: [
                            {
                                id: "4651",
                                sicCode: "4651",
                                title:
                                    "Wholesale of computers, computer peripheral equipment and software",
                                subValues: [],
                            },
                            {
                                id: "4652",
                                sicCode: "4652",
                                title:
                                    "Wholesale of electronic and telecommunications equipment and parts",
                                subValues: [],
                            },
                            {
                                id: "4653",
                                sicCode: "4653",
                                title:
                                    "Wholesale of agricultural machinery, equipment and supplies",
                                subValues: [],
                            },
                            {
                                id: "4659",
                                sicCode: "4659",
                                title:
                                    "Wholesale of other machinery and equipment",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "466",
                        sicCode: "466",
                        title: "Other specialised wholesale",
                        subValues: [
                            {
                                id: "4661",
                                sicCode: "4661",
                                title:
                                    "Wholesale of solid, liquid and gaseous fuels and related products",
                                subValues: [],
                            },
                            {
                                id: "4662",
                                sicCode: "4662",
                                title: "Wholesale of metals and metal ores",
                                subValues: [],
                            },
                            {
                                id: "4663",
                                sicCode: "4663",
                                title:
                                    "Wholesale of construction materials, hardware, plumbing and heating equipment and supplies",
                                subValues: [],
                            },
                            {
                                id: "4669",
                                sicCode: "4669",
                                title: "Wholesale of waste and scrap and other products n.e.c.",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "469",
                        sicCode: "469",
                        title: "Non-specialised wholesale trade",
                        subValues: [
                            {
                                id: "4690",
                                sicCode: "4690",
                                title: "Non-specialised wholesale trade",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "47",
                sicCode: "47",
                title: "Retail trade, except of motor vehicles and motorcycles",
                subValues: [
                    {
                        id: "471",
                        sicCode: "471",
                        title: "Retail sale in non-specialised stores",
                        subValues: [
                            {
                                id: "4711",
                                sicCode: "4711",
                                title:
                                    "Retail sale in non-specialised stores with food, beverages or tobacco predominating",
                                subValues: [],
                            },
                            {
                                id: "4719",
                                sicCode: "4719",
                                title: "Other retail sale in non-specialised stores",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "472",
                        sicCode: "472",
                        title:
                            "Retail sale of food, beverages and tobacco in specialised stores",
                        subValues: [
                            {
                                id: "4721",
                                sicCode: "4721",
                                title:
                                    "Retail sale of food in specialised stores",
                                subValues: [],
                            },
                            {
                                id: "4722",
                                sicCode: "4722",
                                title:
                                    "Retail sale of beverages in specialized stores",
                                subValues: [],
                            },
                            {
                                id: "4723",
                                sicCode: "4723",
                                title:
                                    "Retail sale of tobacco products in specialized stores",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "473",
                        sicCode: "473",
                        title: "Retail sale of automotive fuel in specialised stores",
                        subValues: [
                            {
                                id: "4730",
                                sicCode: "4730",
                                title: "Retail sale of automotive fuel in specialised stores",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "474",
                        sicCode: "474",
                        title:
                            "Retail sale of information and communication equipment in specialised stores",
                        subValues: [
                            {
                                id: "4741",
                                sicCode: "4741",
                                title:
                                    "Retail sale of computers, peripheral units, software and telecommunications equipment in specialized stores",
                                subValues: [],
                            },
                            {
                                id: "4742",
                                sicCode: "4742",
                                title:
                                    "Retail sale of audio and video equipment in specialized stores",
                                subValues: [],
                            },

                        ],
                    },
                    {
                        id: "475",
                        sicCode: "475",
                        title:
                            "Retail sale of other household equipment in specialised stores",
                        subValues: [
                            {
                                id: "4751",
                                sicCode: "4751",
                                title: "Retail sale of textiles in specialised stores",
                                subValues: [],
                            },
                            {
                                id: "4752",
                                sicCode: "4752",
                                title:
                                    "Retail sale of hardware, paints and glass in specialised stores",
                                subValues: [],
                            },
                            {
                                id: "4753",
                                sicCode: "4753",
                                title:
                                    "Retail sale of carpets, rugs, wall and floor coverings in specialised stores",
                                subValues: [],
                            },
                            {
                                id: "4759",
                                sicCode: "4759",
                                title:
                                    "Retail sale of furniture, lighting equipment and other household articles in specialised stores",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "476",
                        sicCode: "476",
                        title:
                            "Retail sale of cultural and recreation goods in specialised stores",
                        subValues: [
                            {
                                id: "4761",
                                sicCode: "4761",
                                title: "Retail sale of books, newspapers and stationary in specialized stores",
                                subValues: [],
                            },
                            {
                                id: "4762",
                                sicCode: "4762",
                                title:
                                    "Retail sale of music and video recordings in specialized stores",
                                subValues: [],
                            },
                            {
                                id: "4763",
                                sicCode: "4763",
                                title:
                                    "Retail sale of sporting equipment in specialized stores",
                                subValues: [],
                            },
                            {
                                id: "4764",
                                sicCode: "4764",
                                title:
                                    "Retail sale of games and toys in specialized stores",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "477",
                        sicCode: "477",
                        title: "Retail sale of other goods in specialised stores",
                        subValues: [
                            {
                                id: "4771",
                                sicCode: "4771",
                                title: "Retail sale of clothing, footwear and leather articles in specialized stores",
                                subValues: [],
                            },
                            {
                                id: "4772",
                                sicCode: "4772",
                                title:
                                    "Retail sale of pharmaceutical and medical goods, cosmetic and toilet articles in specialized stores",
                                subValues: [],
                            },

                            {
                                id: "4773",
                                sicCode: "4773",
                                title: "Other retail sale of new goods in specialized stores",
                                subValues: [],
                            },
                            {
                                id: "4774",
                                sicCode: "4774",
                                title:
                                    "Retail sale of second-hand goods",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "478",
                        sicCode: "478",
                        title: "Retail sale via stalls and markets",
                        subValues: [
                            {
                                id: "4781",
                                sicCode: "4781",
                                title:
                                    "Retail sale via stalls and markets of food, beverages and tobacco products",
                                subValues: [],
                            },
                            {
                                id: "4782",
                                sicCode: "4782",
                                title:
                                    "Retail sale via stalls and markets of textiles, clothing and footwear",
                                subValues: [],
                            },
                            {
                                id: "4789",
                                sicCode: "4789",
                                title: "Retail sale via stalls and markets of other goods",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "479",
                        sicCode: "479",
                        title: "Retail trade not in stores, stalls or markets",
                        subValues: [
                            {
                                id: "4791",
                                sicCode: "4791",
                                title: "Retail sale via mail order houses or via Internet",
                                subValues: [],
                            },
                            {
                                id: "4799",
                                sicCode: "4799",
                                title: "Other retail sale not in stores, stalls or markets",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        id: "H",
        sicCode: "H",
        title: "TRANSPORTATION AND STORAGE",
        subValues: [
            {
                id: "49",
                sicCode: "49",
                title: "Land transport and transport via pipelines",
                subValues: [
                    {
                        id: "491",
                        sicCode: "491",
                        title: "Transport via railways",
                        subValues: [
                            {
                                id: "4911",
                                sicCode: "4911",
                                title: "Passenger rail transport, interurban",
                                subValues: [],
                            },
                            {
                                id: "4912",
                                sicCode: "4912",
                                title: "Freight rail transport",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "492",
                        sicCode: "492",
                        title: "Other land transport",
                        subValues: [
                            {
                                id: "4921",
                                sicCode: "4921",
                                title: "Urban and suburban passenger land transport",
                                subValues: [],
                            },
                            {
                                id: "4922",
                                sicCode: "4922",
                                title: "Other passenger land transport",
                                subValues: [],
                            },
                            {
                                id: "4923",
                                sicCode: "4923",
                                title: "Freight transport by road",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "493",
                        sicCode: "493",
                        title: "Transport via pipeline",
                        subValues: [
                            {
                                id: "4930",
                                sicCode: "4930",
                                title: "Transport via pipeline",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "50",
                sicCode: "50",
                title: "Water transport",
                subValues: [
                    {
                        id: "501",
                        sicCode: "501",
                        title: "Sea and coastal water transport",
                        subValues: [
                            {
                                id: "5011",
                                sicCode: "5011",
                                title: "Sea and coastal passenger water transport",
                                subValues: [],
                            },
                            {
                                id: "5012",
                                sicCode: "5012",
                                title: "Sea and coastal freight water transport",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "502",
                        sicCode: "502",
                        title: "Inland water transport",
                        subValues: [
                            {
                                id: "5021",
                                sicCode: "50200",
                                title: "Inland passenger water transport",
                                subValues: [],
                            },
                            {
                                id: "5022",
                                sicCode: "5022",
                                title: "Inland freight water transport",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "51",
                sicCode: "51",
                title: "Air transport",
                subValues: [
                    {
                        id: "511",
                        sicCode: "511",
                        title: "Passenger air transport",
                        subValues: [
                            {
                                id: "5110",
                                sicCode: "5110",
                                title: "Passenger air transport",
                                subValues: [],
                            },

                        ],
                    },
                    {
                        id: "512",
                        sicCode: "512",
                        title: "Freight air transport",
                        subValues: [
                            {
                                id: "5120",
                                sicCode: "5120",
                                title: "Freight air transport",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "52",
                sicCode: "52",
                title: "Warehousing and support activities for transportation",
                subValues: [
                    {
                        id: "521",
                        sicCode: "521",
                        title: "Warehousing and storage",
                        subValues: [
                            {
                                id: "5210",
                                sicCode: "5210",
                                title: "Warehousing and storage",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "522",
                        sicCode: "522",
                        title: "Support activities for transportation",
                        subValues: [
                            {
                                id: "5221",
                                sicCode: "5221",
                                title: "Service activities incidental to land transportation",
                                subValues: [],
                            },
                            {
                                id: "5222",
                                sicCode: "5222",
                                title: "Service activities incidental to water transportation",
                                subValues: [],
                            },
                            {
                                id: "5223",
                                sicCode: "5223",
                                title: "Service activities incidental to air transportation",
                                subValues: [],
                            },
                            {
                                id: "5224",
                                sicCode: "5224",
                                title: "Cargo handling",
                                subValues: [],
                            },
                            {
                                id: "5229",
                                sicCode: "5229",
                                title: "Other transportation support activities",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "53",
                sicCode: "53",
                title: "Postal and courier activities",
                subValues: [
                    {
                        id: "531",
                        sicCode: "531",
                        title: "Postal activities",
                        subValues: [
                            {
                                id: "5310",
                                sicCode: "5310",
                                title: "Postal activities",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "532",
                        sicCode: "532",
                        title: "Courier activities",
                        subValues: [
                            {
                                id: "5320",
                                sicCode: "5320",
                                title: "Courier activities",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        id: "I",
        sicCode: "I",
        title: "ACCOMMODATION AND FOOD SERVICE ACTIVITIES",
        subValues: [
            {
                id: "55",
                sicCode: "55",
                title: "Accommodation",
                subValues: [
                    {
                        id: "551",
                        sicCode: "551",
                        title: "Short term accommodation activities",
                        subValues: [
                            {
                                id: "5510",
                                sicCode: "5510",
                                title: "Short term accommodation activities",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "552",
                        sicCode: "552",
                        title: "Camping grounds, recreational vehicle parks and trailer parks",
                        subValues: [
                            {
                                id: "5520",
                                sicCode: "5520",
                                title: "Camping grounds, recreational vehicle parks and trailer parks",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "559",
                        sicCode: "559",
                        title: "Other accommodation",
                        subValues: [
                            {
                                id: "5590",
                                sicCode: "5590",
                                title: "Other accommodation",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "56",
                sicCode: "56",
                title: "Food and beverage service activities",
                subValues: [
                    {
                        id: "561",
                        sicCode: "561",
                        title: "Restaurants and mobile food service activities",
                        subValues: [
                            {
                                id: "5610",
                                sicCode: "5610",
                                title: "Restaurants and mobile food service activities",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "562",
                        sicCode: "562",
                        title: "Event catering and other food service activities",
                        subValues: [
                            {
                                id: "5621",
                                sicCode: "5621",
                                title: "Event catering",
                                subValues: [],
                            },
                            {
                                id: "5629",
                                sicCode: "5629",
                                title: "Other food service activities",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "563",
                        sicCode: "563",
                        title: "Beverage serving activities",
                        subValues: [
                            {
                                id: "5630",
                                sicCode: "5630",
                                title: "Beverage serving activities",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        id: "J",
        sicCode: "J",
        title: "INFORMATION AND COMMUNICATION",
        subValues: [
            {
                id: "58",
                sicCode: "58",
                title: "Publishing activities",
                subValues: [
                    {
                        id: "581",
                        sicCode: "581",
                        title:
                            "Publishing of books, periodicals and other publishing activities",
                        subValues: [
                            {
                                id: "5811",
                                sicCode: "5811",
                                title: "Book publishing",
                                subValues: [],
                            },
                            {
                                id: "5812",
                                sicCode: "5812",
                                title: "Publishing of directories and mailing lists",
                                subValues: [],
                            },
                            {
                                id: "5813",
                                sicCode: "5813",
                                title: "Publishing of newspapers, journals and periodicals",
                                subValues: [],
                            },
                            {
                                id: "5819",
                                sicCode: "5819",
                                title: "Other publishing activities",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "582",
                        sicCode: "582",
                        title: "Software publishing",
                        subValues: [
                            {
                                id: "5820",
                                sicCode: "5820",
                                title: "Software publishing",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "59",
                sicCode: "59",
                title:
                    "Motion picture, video and television programme production, sound recording and music publishing activities",
                subValues: [
                    {
                        id: "591",
                        sicCode: "591",
                        title: "Motion picture, video and television programme activities",
                        subValues: [
                            {
                                id: "5911",
                                sicCode: "5911",
                                title: "Motion picture, video and television programme production activities",
                                subValues: [],
                            },
                            {
                                id: "5912",
                                sicCode: "5912",
                                title: "Motion picture, video and television programme post-production activities",
                                subValues: [],
                            },
                            {
                                id: "5913",
                                sicCode: "5913",
                                title: "Motion picture, video and television programme distribution activities",
                                subValues: [],
                            },
                            {
                                id: "5914",
                                sicCode: "5914",
                                title: "Motion picture projection activities",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "592",
                        sicCode: "592",
                        title: "Sound recording and music publishing activities",
                        subValues: [
                            {
                                id: "5920",
                                sicCode: "5920",
                                title: "Sound recording and music publishing activities",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "60",
                sicCode: "60",
                title: "Programming and broadcasting activities",
                subValues: [
                    {
                        id: "601",
                        sicCode: "601",
                        title: "Radio broadcasting",
                        subValues: [
                            {
                                id: "6010",
                                sicCode: "6010",
                                title: "Radio broadcasting",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "602",
                        sicCode: "602",
                        title: "Television programming and broadcasting activities",
                        subValues: [
                            {
                                id: "6020",
                                sicCode: "6020",
                                title: "Television programming and broadcasting activities",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "61",
                sicCode: "61",
                title: "Telecommunications",
                subValues: [
                    {
                        id: "611",
                        sicCode: "611",
                        title: "Wired telecommunications activities",
                        subValues: [
                            {
                                id: "6110",
                                sicCode: "6110",
                                title: "Wired telecommunications activities",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "612",
                        sicCode: "612",
                        title: "Wireless telecommunications activities",
                        subValues: [
                            {
                                id: "6120",
                                sicCode: "6120",
                                title: "Wireless telecommunications activities",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "613",
                        sicCode: "613",
                        title: "Satellite telecommunications activities",
                        subValues: [
                            {
                                id: "6130",
                                sicCode: "6130",
                                title: "Satellite telecommunications activities",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "619",
                        sicCode: "619",
                        title: "Other telecommunications activities",
                        subValues: [
                            {
                                id: "6190",
                                sicCode: "6190",
                                title: "Other telecommunications activities",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "62",
                sicCode: "62",
                title: "Computer programming, consultancy and related activities",
                subValues: [
                    {
                        id: "620",
                        sicCode: "620",
                        title: "Computer programming, consultancy and related activities",
                        subValues: [
                            {
                                id: "6201",
                                sicCode: "6201",
                                title: "Computer programming activities",
                                subValues: [],
                            },
                            {
                                id: "6202",
                                sicCode: "6202",
                                title: "Computer consultancy and computer facilities management activities",
                                subValues: [],
                            },
                            {
                                id: "6209",
                                sicCode: "6209",
                                title:
                                    "Other information technology and computer service activities",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "63",
                sicCode: "63",
                title: "Information service activities",
                subValues: [
                    {
                        id: "631",
                        sicCode: "631",
                        title:
                            "Data processing, hosting and related activities; web portals",
                        subValues: [
                            {
                                id: "6311",
                                sicCode: "6311",
                                title: "Data processing, hosting and related activities",
                                subValues: [],
                            },
                            {
                                id: "6312",
                                sicCode: "6312",
                                title: "Web portals",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "639",
                        sicCode: "639",
                        title: "Other information service activities",
                        subValues: [
                            {
                                id: "6391",
                                sicCode: "6391",
                                title: "News agency activities",
                                subValues: [],
                            },
                            {
                                id: "6399",
                                sicCode: "6399",
                                title: "Other information service activities nec",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        id: " K",
        sicCode: " K",
        title: "FINANCIAL AND INSURANCE ACTIVITIES",
        subValues: [
            {
                id: "64",
                sicCode: "64",
                title:
                    "Financial service activities, except insurance and pension funding",
                subValues: [
                    {
                        id: "641",
                        sicCode: "641",
                        title: "Monetary intermediation",
                        subValues: [
                            {
                                id: "6411",
                                sicCode: "6411",
                                title: "Central banking",
                                subValues: [],
                            },
                            {
                                id: "6419",
                                sicCode: "6419",
                                title: "Other monetary intermediation",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "642",
                        sicCode: "642",
                        title: "Activities of holding companies",
                        subValues: [
                            {
                                id: "6420",
                                sicCode: "6420",
                                title: "Activities of holding companies",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "643",
                        sicCode: "643",
                        title: "Trusts, funds and similar financial entities",
                        subValues: [
                            {
                                id: "6430",
                                sicCode: "6430",
                                title: "Trusts, funds and similar financial entities",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "649",
                        sicCode: "649",
                        title:
                            "Other financial service activities, except insurance and pension funding",
                        subValues: [
                            {
                                id: "6491",
                                sicCode: "6491",
                                title: "Financial leasing",
                                subValues: [],
                            },
                            {
                                id: "6492",
                                sicCode: "6492",
                                title: "Other credit granting",
                                subValues: [],
                            },
                            {
                                id: "6499",
                                sicCode: "6499",
                                title:
                                    "Other financial service activities, except insurance and pension funding, nec",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "65",
                sicCode: "65",
                title:
                    "Insurance, reinsurance and pension funding, except compulsory social security",
                subValues: [
                    {
                        id: "651",
                        sicCode: "651",
                        title: "Insurance",
                        subValues: [
                            {
                                id: "6511",
                                sicCode: "6511",
                                title: "Life insurance",
                                subValues: [],
                            },
                            {
                                id: "6512",
                                sicCode: "6512",
                                title: "Non-life insurance",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "652",
                        sicCode: "652",
                        title: "Reinsurance",
                        subValues: [
                            {
                                id: "6520",
                                sicCode: "6520",
                                title: "Reinsurance",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "653",
                        sicCode: "653",
                        title: "Pension funding",
                        subValues: [
                            {
                                id: "6530",
                                sicCode: "6530",
                                title: "Pension funding",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "66",
                sicCode: "66",
                title:
                    "Activities auxiliary to financial services and insurance activities",
                subValues: [
                    {
                        id: "661",
                        sicCode: "661",
                        title:
                            "Activities auxiliary to financial services, except insurance and pension funding",
                        subValues: [
                            {
                                id: "6611",
                                sicCode: "6611",
                                title: "Administration of financial markets",
                                subValues: [],
                            },
                            {
                                id: "6612",
                                sicCode: "6612",
                                title: "Security and commodity contracts brokerage",
                                subValues: [],
                            },
                            {
                                id: "6619",
                                sicCode: "6619",
                                title:
                                    "Other activities auxiliary to financial services, except insurance and pension funding",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "662",
                        sicCode: "662",
                        title: "Activities auxiliary to insurance and pension funding",
                        subValues: [
                            {
                                id: "6621",
                                sicCode: "6621",
                                title: "Risk and damage evaluation",
                                subValues: [],
                            },
                            {
                                id: "6622",
                                sicCode: "6622",
                                title: "Activities of insurance agents and brokers",
                                subValues: [],
                            },
                            {
                                id: "6629",
                                sicCode: "6629",
                                title:
                                    "Other activities auxiliary to insurance and pension funding",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "663",
                        sicCode: "663",
                        title: "Fund management activities",
                        subValues: [
                            {
                                id: "6630",
                                sicCode: "6630",
                                title: "Fund management activities",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },

        ],
    },
    {
        id: "L",
        sicCode: "L",
        title: "Real estate activities",
        subValues: [
            {
                id: "681",
                sicCode: "681",
                title: "Real estate activities with own or leased property",
                subValues: [
                    {
                        id: "6810",
                        sicCode: "6810",
                        title: "Real estate activities with own or leased property",
                        subValues: [],
                    },
                ],
            },
            {
                id: "682",
                sicCode: "682",
                title: "Real estate activities on a fee or contract basis",
                subValues: [
                    {
                        id: "6820",
                        sicCode: "6820",
                        title: "Real estate activities on a fee or contract basis",
                        subValues: [],
                    },
                ],
            },
        ],

    },
    {
        id: "M",
        sicCode: "M",
        title: "PROFESSIONAL, SCIENTIFIC AND TECHNICAL ACTIVITIES",
        subValues: [
            {
                id: "69",
                sicCode: "69",
                title: "Legal and accounting activities",
                subValues: [
                    {
                        id: "691",
                        sicCode: "691",
                        title: "Legal activities",
                        subValues: [
                            {
                                id: "6910",
                                sicCode: "6910",
                                title: "Legal activities",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "692",
                        sicCode: "692",
                        title:
                            "Accounting, bookkeeping and auditing activities; tax consultancy",
                        subValues: [
                            {
                                id: "6920",
                                sicCode: "6920",
                                title:
                                    "Accounting, bookkeeping and auditing activities; tax consultancy",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "70",
                sicCode: "70",
                title: "Activities of head offices; management consultancy activities",
                subValues: [
                    {
                        id: "701",
                        sicCode: "701",
                        title: "Activities of head offices",
                        subValues: [
                            {
                                id: "7010",
                                sicCode: "7010",
                                title: "Activities of head offices",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "702",
                        sicCode: "702",
                        title: "Management consultancy activities",
                        subValues: [
                            {
                                id: "7020",
                                sicCode: "7020",
                                title:
                                    "Management consultancy activities",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "71",
                sicCode: "71",
                title:
                    "Architectural and engineering activities; technical testing and analysis",
                subValues: [
                    {
                        id: "711",
                        sicCode: "711",
                        title:
                            "Architectural and engineering activities and related technical consultancy",
                        subValues: [
                            {
                                id: "7110",
                                sicCode: "7110",
                                title: "Architectural activities",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "712",
                        sicCode: "712",
                        title: "Technical testing and analysis",
                        subValues: [
                            {
                                id: "7120",
                                sicCode: "7120",
                                title: "Technical testing and analysis",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "72",
                sicCode: "72",
                title: "Scientific research and development",
                subValues: [
                    {
                        id: "721",
                        sicCode: "721",
                        title:
                            "Research and experimental development on natural sciences and engineering",
                        subValues: [
                            {
                                id: "7210",
                                sicCode: "7210",
                                title: "Research and experimental development on natural sciences and engineering",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "722",
                        sicCode: "722",
                        title:
                            "Research and experimental development on social sciences and humanities",
                        subValues: [
                            {
                                id: "7220",
                                sicCode: "7220",
                                title:
                                    "Research and experimental development on social sciences and humanities",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "73",
                sicCode: "73",
                title: "Advertising and market research",
                subValues: [
                    {
                        id: "731",
                        sicCode: "731",
                        title: "Advertising",
                        subValues: [
                            {
                                id: "7310",
                                sicCode: "7310",
                                title: "Advertising",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "732",
                        sicCode: "732",
                        title: "Market research and public opinion polling",
                        subValues: [
                            {
                                id: "7320",
                                sicCode: "7320",
                                title: "Market research and public opinion polling",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "74",
                sicCode: "74",
                title: "Other professional, scientific and technical activities",
                subValues: [
                    {
                        id: "741",
                        sicCode: "741",
                        title: "Specialised design activities",
                        subValues: [
                            {
                                id: "7410",
                                sicCode: "7410",
                                title: "Specialised design activities",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "742",
                        sicCode: "742",
                        title: "Photographic activities",
                        subValues: [
                            {
                                id: "7420",
                                sicCode: "7420",
                                title: "Photographic activities",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "749",
                        sicCode: "749",
                        title:
                            "Other professional, scientific and technical activities nec",
                        subValues: [
                            {
                                id: "7490",
                                sicCode: "7490",
                                title:
                                    "Other professional, scientific and technical activities nec",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "75",
                sicCode: "75",
                title: "Veterinary activities",
                subValues: [
                    {
                        id: "750",
                        sicCode: "750",
                        title: "Veterinary activities",
                        subValues: [
                            {
                                id: "7500",
                                sicCode: "7500",
                                title: "Veterinary activities",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        id: "N",
        sicCode: "N",
        title: "Administrative and support service activities",
        subValues: [
            {
                id: "77",
                sicCode: "77",
                title: "Rental and leasing activities",
                subValues: [
                    {
                        id: "771",
                        sicCode: "771",
                        title: "Renting and leasing of motor vehicles",
                        subValues: [
                            {
                                id: "7710",
                                sicCode: "7710",
                                title: "Renting and leasing of motor vehicles",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "772",
                        sicCode: "772",
                        title: "Renting and leasing of personal and household goods",
                        subValues: [
                            {
                                id: "7721",
                                sicCode: "7721",
                                title: "Renting and leasing of recreational and sports goods",
                                subValues: [],
                            },
                            {
                                id: "7722",
                                sicCode: "7722",
                                title: "Renting of video tapes and disks",
                                subValues: [],
                            },
                            {
                                id: "7729",
                                sicCode: "7729",
                                title:
                                    "Renting and leasing of other personal and household goods",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "773",
                        sicCode: "773",
                        title:
                            "Renting and leasing of other machinery, equipment and tangible goods",
                        subValues: [
                            {
                                id: "7730",
                                sicCode: "7730",
                                title:
                                    "Renting and leasing of other machinery, equipment and tangible goods",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "774",
                        sicCode: "774",
                        title:
                            "Leasing of intellectual property and similar products, except copyrighted works",
                        subValues: [
                            {
                                id: "7740",
                                sicCode: "7740",
                                title:
                                    "Leasing of intellectual property and similar products, except copyrighted works",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "78",
                sicCode: "78",
                title: "Employment activities",
                subValues: [
                    {
                        id: "781",
                        sicCode: "781",
                        title: "Activities of employment placement agencies",
                        subValues: [
                            {
                                id: "7810",
                                sicCode: "7810",
                                title: "Activities of employment placement agencies",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "782",
                        sicCode: "782",
                        title: "Temporary employment agency activities",
                        subValues: [
                            {
                                id: "7820",
                                sicCode: "7820",
                                title: "Temporary employment agency activities",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "783",
                        sicCode: "783",
                        title: "Other human resources provision",
                        subValues: [
                            {
                                id: "7830",
                                sicCode: "7830",
                                title: "Other human resources provision",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "79",
                sicCode: "79",
                title:
                    "Travel agency, tour operator, reservation service and related activities",
                subValues: [
                    {
                        id: "791",
                        sicCode: "791",
                        title: "Travel agency and tour operator activities",
                        subValues: [
                            {
                                id: "7911",
                                sicCode: "7911",
                                title: "Travel agency activities",
                                subValues: [],
                            },
                            {
                                id: "7912",
                                sicCode: "7912",
                                title: "Tour operator activities",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "799",
                        sicCode: "799",
                        title: "Other reservation service and related activities",
                        subValues: [
                            {
                                id: "7990",
                                sicCode: "7990",
                                title: "Other reservation service and related activities",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "80",
                sicCode: "80",
                title: "Security and investigation activities",
                subValues: [
                    {
                        id: "801",
                        sicCode: "801",
                        title: "Private security activities",
                        subValues: [
                            {
                                id: "8010",
                                sicCode: "8010",
                                title: "Private security activities",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "802",
                        sicCode: "802",
                        title: "Security systems service activities",
                        subValues: [
                            {
                                id: "8020",
                                sicCode: "8020",
                                title: "Security systems service activities",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "803",
                        sicCode: "803",
                        title: "Investigation activities",
                        subValues: [
                            {
                                id: "8030",
                                sicCode: "8030",
                                title: "Investigation activities",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "81",
                sicCode: "81",
                title: "Services to buildings and landscape activities",
                subValues: [
                    {
                        id: "811",
                        sicCode: "811",
                        title: "Combined facilities support activities",
                        subValues: [
                            {
                                id: "8110",
                                sicCode: "8110",
                                title: "Combined facilities support activities",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "812",
                        sicCode: "812",
                        title: "Cleaning activities",
                        subValues: [
                            {
                                id: "8121",
                                sicCode: "8121",
                                title: "General cleaning of buildings",
                                subValues: [],
                            },
                            {
                                id: "8129",
                                sicCode: "8129",
                                title: "Other building and industrial cleaning activities",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "813",
                        sicCode: "813",
                        title: "Landscape care and maintenance service activities",
                        subValues: [
                            {
                                id: "8130",
                                sicCode: "8130",
                                title: "Landscape care and maintenance service activities",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "82",
                sicCode: "82",
                title:
                    "Office administrative, office support and other business support activities",
                subValues: [

                    {
                        id: "821",
                        sicCode: "821",
                        title: "Office administrative and support activities",
                        subValues: [
                            {
                                id: "8211",
                                sicCode: "8211",
                                title: "Combined office administrative service activities",
                                subValues: [],
                            },
                            {
                                id: "8219",
                                sicCode: "8219",
                                title:
                                    "Photocopying, document preparation and other specialized office support activities",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "822",
                        sicCode: "822",
                        title: "Activities of call centres",
                        subValues: [
                            {
                                id: "8220",
                                sicCode: "8220",
                                title: "Activities of call centres",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "823",
                        sicCode: "823",
                        title: "Organization of conventions and trade shows",
                        subValues: [
                            {
                                id: "8230",
                                sicCode: "8230",
                                title: "Organization of conventions and trade shows",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "829",
                        sicCode: "829",
                        title: "Business support service activities n.e.c.",
                        subValues: [
                            {
                                id: "8291",
                                sicCode: "8291",
                                title: "Activities of collection agencies and credit bureaus",
                                subValues: [],
                            },
                            {
                                id: "8292",
                                sicCode: "8292",
                                title: "Packaging activities",
                                subValues: [],
                            },
                            {
                                id: "8299",
                                sicCode: "8299",
                                title: "Other business support service activities n.e.c.",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        id: "O",
        sicCode: "O",
        title: "Public administration and defence; compulsory social security",
        subValues: [
            {
                id: "84",
                sicCode: "84",
                title: "Public administration and defence; compulsory social security",
                subValues: [

                    {
                        id: "841",
                        sicCode: "841",
                        title:
                            "Administration of the State and the economic and social policy of the community",
                        subValues: [
                            {
                                id: "8411",
                                sicCode: "8411",
                                title: "General public administration activities",
                                subValues: [],
                            },
                            {
                                id: "8412",
                                sicCode: "8412",
                                title:
                                    "Regulation of the activities of providing health care, education, cultural services and other social services, excluding social security",
                                subValues: [],
                            },
                            {
                                id: "8413",
                                sicCode: "8413",
                                title:
                                    "Regulation of and contribution to more efficient operation of businesses",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "842",
                        sicCode: "842",
                        title: "Provision of services to the community as a whole",
                        subValues: [
                            {
                                id: "8421",
                                sicCode: "8421",
                                title: "Foreign affairs",
                                subValues: [],
                            },
                            {
                                id: "8422",
                                sicCode: "8422",
                                title: "Defence activities",
                                subValues: [],
                            },
                            {
                                id: "8423",
                                sicCode: "8423",
                                title: "Public order and safety activities",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "843",
                        sicCode: "843",
                        title: "Compulsory social security activities",
                        subValues: [
                            {
                                id: "8430",
                                sicCode: "8430",
                                title: "Compulsory social security activities",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },

        ],
    },
    {
        id: "P",
        sicCode: "P",
        title: "Education",
        subValues: [
            {
                id: "85",
                sicCode: "85",
                title: "Education",
                subValues: [
                    {
                        id: "851",
                        sicCode: "851",
                        title: "Pre-primary and primary education",
                        subValues: [
                            {
                                id: "8510",
                                sicCode: "8510",
                                title: "Pre-primary and primary education",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "852",
                        sicCode: "852",
                        title: "Secondary education",
                        subValues: [
                            {
                                id: "8521",
                                sicCode: "8521",
                                title: "General secondary education",
                                subValues: [],
                            },
                            {
                                id: "8522",
                                sicCode: "8522",
                                title: "Technical and vocational secondary education",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "853",
                        sicCode: "853",
                        title: "Higher education",
                        subValues: [
                            {
                                id: "8530",
                                sicCode: "8530",
                                title: "Higher education",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "854",
                        sicCode: "854",
                        title: "Other education",
                        subValues: [
                            {
                                id: "8541",
                                sicCode: "8541",
                                title: "Sports and recreation education",
                                subValues: [],
                            },
                            {
                                id: "8542",
                                sicCode: "8542",
                                title: "Cultural education",
                                subValues: [],
                            },
                            {
                                id: "8549",
                                sicCode: "8549",
                                title: "Other education n.e.c.",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "855",
                        sicCode: "855",
                        title: "Educational support activities",
                        subValues: [
                            {
                                id: "8550",
                                sicCode: "8550",
                                title: "Educational support activities",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        id: "Q",
        sicCode: "Q",
        title: "Human health and social work activities",
        subValues: [

            {
                id: "86",
                sicCode: "86",
                title: "Human health activities",
                subValues: [

                    {
                        id: "861",
                        sicCode: "861",
                        title: "Hospital activities",
                        subValues: [
                            {
                                id: "8610",
                                sicCode: "8610",
                                title: "Hospital activities",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "862",
                        sicCode: "862",
                        title: "Medical and dental practice activities",
                        subValues: [
                            {
                                id: "8620",
                                sicCode: "8620",
                                title: "Medical and dental practice activities",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "869",
                        sicCode: "869",
                        title: "Other human health activities",
                        subValues: [
                            {
                                id: "8690",
                                sicCode: "8690",
                                title: "Other human health activities",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "87",
                sicCode: "87",
                title: "Residential care activities",
                subValues: [

                    {
                        id: "871",
                        sicCode: "871",
                        title: "Residential nursing care facilities",
                        subValues: [
                            {
                                id: "8710",
                                sicCode: "8710",
                                title: "Residential nursing care facilities",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "872",
                        sicCode: "872",
                        title:
                            "Residential care activities for mental retardation, mental health and substance abuse",
                        subValues: [
                            {
                                id: "8720",
                                sicCode: "8720",
                                title:
                                    "Residential care activities for mental retardation, mental health and substance abuse",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "873",
                        sicCode: "873",
                        title: "Residential care activities for the elderly and disabled",
                        subValues: [
                            {
                                id: "8730",
                                sicCode: "8730",
                                title:
                                    "Residential care activities for the elderly and disabled",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "879",
                        sicCode: "879",
                        title: "Other residential care activities",
                        subValues: [
                            {
                                id: "8790",
                                sicCode: "8790",
                                title: "Other residential care activities",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "88",
                sicCode: "88",
                title: "Social work activities without accommodation",
                subValues: [

                    {
                        id: "881",
                        sicCode: "881",
                        title:
                            "Social work activities without accommodation for the elderly and disabled",
                        subValues: [
                            {
                                id: "8810",
                                sicCode: "8810",
                                title:
                                    "Social work activities without accommodation for the elderly and disabled",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "889",
                        sicCode: "889",
                        title:
                            "Other social work activities without accommodation",
                        subValues: [
                            {
                                id: "8890",
                                sicCode: "8890",
                                title:
                                    "Other social work activities without accommodation",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        id: "R",
        sicCode: "R",
        title: "Arts, entertainment and recreation",
        subValues: [

            {
                id: "90",
                sicCode: "90",
                title: "Creative, arts and entertainment activities",
                subValues: [
                    {
                        id: "900",
                        sicCode: "900",
                        title: "Creative, arts and entertainment activities",
                        subValues: [
                            {
                                id: "9000",
                                sicCode: "9000",
                                title: "Creative, arts and entertainment activities",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "91",
                sicCode: "91",
                title: "Libraries, archives, museums and other cultural activities",
                subValues: [
                    {
                        id: "910",
                        sicCode: "910",
                        title:
                            "Libraries, archives, museums and other cultural activities",
                        subValues: [
                            {
                                id: "9101",
                                sicCode: "9101",
                                title: "Library and archives activities",
                                subValues: [],
                            },
                            {
                                id: "9102",
                                sicCode: "9102",
                                title:
                                    "Museums activities and operation of historical sites and buildings",
                                subValues: [],
                            },
                            {
                                id: "9103",
                                sicCode: "9103",
                                title:
                                    "Botanical and zoological gardens and nature reserves activities",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "92",
                sicCode: "92",
                title: "Gambling and betting activities",
                subValues: [
                    {
                        id: "920",
                        sicCode: "920",
                        title: "Gambling and betting activities",
                        subValues: [
                            {
                                id: "9200",
                                sicCode: "9200",
                                title: "Gambling and betting activities",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "93",
                sicCode: "93",
                title: "Sports activities and amusement and recreation activities",
                subValues: [
                    {
                        id: "931",
                        sicCode: "931",
                        title: "Sports activities",
                        subValues: [
                            {
                                id: "9311",
                                sicCode: "9311",
                                title: "Operation of sports facilities",
                                subValues: [],
                            },
                            {
                                id: "9312",
                                sicCode: "9312",
                                title: "Activities of sports clubs",
                                subValues: [],
                            },
                            {
                                id: "9319",
                                sicCode: "9319",
                                title: "Other sports activities",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "932",
                        sicCode: "932",
                        title: "Other amusement and recreation activities",
                        subValues: [
                            {
                                id: "9321",
                                sicCode: "9321",
                                title: "Activities of amusement parks and theme parks",
                                subValues: [],
                            },
                            {
                                id: "9329",
                                sicCode: "9329",
                                title: "Other amusement and recreation activities n.e.c.",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        id: "S",
        sicCode: "S",
        title: "Other service activities",
        subValues: [

            {
                id: "94",
                sicCode: "94",
                title: "Activities of membership organizations",
                subValues: [

                    {
                        id: "941",
                        sicCode: "941",
                        title:
                            "Activities of business, employers and professional membership organizations",
                        subValues: [
                            {
                                id: "9411",
                                sicCode: "9411",
                                title:
                                    "Activities of business and employers membership organizations",
                                subValues: [],
                            },
                            {
                                id: "9412",
                                sicCode: "9412",
                                title: "Activities of professional membership organizations",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "942",
                        sicCode: "942",
                        title: "Activities of trade unions",
                        subValues: [
                            {
                                id: "9420",
                                sicCode: "9420",
                                title: "Activities of trade unions",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "949",
                        sicCode: "949",
                        title: "Activities of other membership organizations",
                        subValues: [
                            {
                                id: "9491",
                                sicCode: "9491",
                                title: "Activities of religious organizations",
                                subValues: [],
                            },
                            {
                                id: "9492",
                                sicCode: "9492",
                                title: "Activities of political organizations",
                                subValues: [],
                            },
                            {
                                id: "9499",
                                sicCode: "9499",
                                title: "Activities of other membership organizations n.e.c.",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "95",
                sicCode: "95",
                title: "Repair of computers and personal and household goods",
                subValues: [

                    {
                        id: "951",
                        sicCode: "951",
                        title: "Repair of computers and communication equipment",
                        subValues: [
                            {
                                id: "9511",
                                sicCode: "9511",
                                title: "Repair of computers and peripheral equipment",
                                subValues: [],
                            },
                            {
                                id: "9512",
                                sicCode: "9512",
                                title: "Repair of communication equipment",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "952",
                        sicCode: "952",
                        title: "Repair of personal and household goods",
                        subValues: [
                            {
                                id: "9521",
                                sicCode: "9521",
                                title: "Repair of consumer electronics",
                                subValues: [],
                            },
                            {
                                id: "9522",
                                sicCode: "9522",
                                title:
                                    "Repair of household appliances and home and garden equipment",
                                subValues: [],
                            },
                            {
                                id: "9523",
                                sicCode: "9523",
                                title: "Repair of footwear and leather goods",
                                subValues: [],
                            },
                            {
                                id: "9524",
                                sicCode: "9524",
                                title: "Repair of furniture and home furnishings",
                                subValues: [],
                            },
                            {
                                id: "9529",
                                sicCode: "9529",
                                title: "Repair of other personal and household goods",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "96",
                sicCode: "96",
                title: "Other personal service activities",
                subValues: [
                    {
                        id: "960",
                        sicCode: "960",
                        title: "Other personal service activities",
                        subValues: [
                            {
                                id: "9601",
                                sicCode: "9601",
                                title:
                                    "Washing and (dry-) cleaning of textile and fur products",
                                subValues: [],
                            },
                            {
                                id: "9602",
                                sicCode: "9602",
                                title: "Hairdressing and other beauty treatment",
                                subValues: [],
                            },
                            {
                                id: "9603",
                                sicCode: "9603",
                                title: "Funeral and related activities",
                                subValues: [],
                            },
                            {
                                id: "9609",
                                sicCode: "9609",
                                title: "Other personal service activities n.e.c.",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        id: "T",
        sicCode: "T",
        title:
            "Activities of households as employers; undifferentiated goods- and services-producing activities of households for own use",
        subValues: [
            {
                id: "97",
                sicCode: "97",
                title: "Activities of households as employers of domestic personnel",
                subValues: [
                    {
                        id: "970",
                        sicCode: "970",
                        title:
                            "Activities of households as employers of domestic personnel",
                        subValues: [
                            {
                                id: "9700",
                                sicCode: "9700",
                                title:
                                    "Activities of households as employers of domestic personnel",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
            {
                id: "98",
                sicCode: "98",
                title:
                    "Undifferentiated goods- and services-producing activities of private households for own use",
                subValues: [
                    {
                        id: "981",
                        sicCode: "981",
                        title:
                            "Undifferentiated goods-producing activities of private households for own use",
                        subValues: [
                            {
                                id: "9810",
                                sicCode: "9810",
                                title:
                                    "Undifferentiated goods-producing activities of private households for own use",
                                subValues: [],
                            },
                        ],
                    },
                    {
                        id: "982",
                        sicCode: "982",
                        title:
                            "Undifferentiated service-producing activities of private households for own use",
                        subValues: [
                            {
                                id: "9820",
                                sicCode: "9820",
                                title:
                                    "Undifferentiated service-producing activities of private households for own use",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        id: "U",
        sicCode: "U",
        title: "Activities of extraterritorial organizations and bodies",
        subValues: [
            {
                id: "99",
                sicCode: "99",
                title: "Activities of extraterritorial organizations and bodies",
                subValues: [
                    {
                        id: "990",
                        sicCode: "990",
                        title: "Activities of extraterritorial organizations and bodies",
                        subValues: [
                            {
                                id: "9900",
                                sicCode: "9900",
                                title:
                                    "Activities of extraterritorial organizations and bodies",
                                subValues: [],
                            },
                        ],
                    },
                ],
            },
        ],
    },
];

