import '../../styling/saved.css'
import React, { useEffect, useState } from 'react';
import axios from "axios";

import { auth } from '../..';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import PaymentComponent from '../PaymentComponent';
import { Mixpanel } from '../Mixpanel/Mixpanel';
import Lottie from 'lottie-react';
import { lottieAnimationUrl } from 'components/utils/network/endpoints';
import axiosWithToken from 'axiosWithToken';

interface BuyCreditsPopUpProps {
  close: any,
  editPayment: any,
  paymentMethod: any,
  complete: any,
  option: any,
  updateCredits?: any,
  user?: any,
}

const BuyCreditsPopUp: React.FC<BuyCreditsPopUpProps> = ({ close, editPayment, paymentMethod, complete, option }) => {

  const [loading, setLoading] = useState(false)

  const [intentID, setIntentID] = useState("")
  const [options, setOptions] = useState<any>(null)
  const [submitCount, setSubmitCount] = useState(0)
  const [animationData, setAnimationData] = useState<any>(null);


  const appearance = {
    variables: {}
  };

  //const baseURL = process.env.REACT_APP_BASEURL;
  const baseURL2 = process.env.REACT_APP_BASEURL2;

  const provider = paymentMethod ? paymentMethod.card.networks.available[0].charAt(0).toUpperCase() + paymentMethod.card.networks.available[0].slice(1) : "";
  const providerImage = paymentMethod ? provider === 'Visa' ? '/assets/visa.png' : '/assets/mastercard.png' : ""
  const stripeLoaded: Promise<Stripe | null> = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY?.toString() || '');

  useEffect(() => {
    const fetchAnimationData = async () => {
      const response = await fetch(lottieAnimationUrl);
      const data = await response.json();
      setAnimationData(data);
    };

    fetchAnimationData();
  }, []);

  if (!animationData) {
    return null;
  }

  const cancelClicked = () => {
    close()
  }

  const delay = (ms: any) => new Promise(res => setTimeout(res, ms));


  const createPaymentIntent = async () => {
    setLoading(true);
    let user = JSON.parse(localStorage.getItem("user") || '{}');

    try {
      Mixpanel.identify(user.uid);
      Mixpanel.people.set({ $name: user.name, $email: user.email, plan: user.plan });
      Mixpanel.track("Feature Usage", {
        "Feature": "Buy Credits",
        "User ID": user.uid,
        "Date": new Date().toISOString(),
        $name: user?.name,
        $email: user?.email,
        "Plan": user?.plan,
        "User Type": "Buyer",
      });

      const response = await axiosWithToken.post(`${baseURL2}api/stripe/payment-intent`, {
        amount: Math.round(option.credits * option.rate * 100) / 100
      });

      if (response.data.error) {
        setLoading(false);
        throw new Error(response.data.error);

      } else {
        let intent = response.data.client_secret;

        setOptions({ clientSecret: intent, appearance: appearance });
        setIntentID(intent);

        await delay(1000);

        setSubmitCount(submitCount + 1);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error creating payment intent:", error);
      setLoading(false);
    }
  };


  const stripeResponse = (response: any) => {

    if (response.success) {
      complete(option.credits)
      addCreditsToAccount()
    }
  }

  const addCreditsToAccount = () => {
    setLoading(true)
    axios.post(baseURL2 + 'addCreditsToAccount', {
      uid: auth.currentUser?.uid,
      amount: option.credits

    }).then((response) => {

      setLoading(false)
    })
  }



  return (

    <div className='tint'>
      <div className='popUp confirmPlan'>
        <div className='row jc-between width100 ai-centre'>
          <span className='text-lg semibold gray-900'>Buy Export credit</span>
          <img className='closeButton pointer' src='/assets/x-close.png' onClick={close} alt='close' />
        </div>


        {/* {!amountP && <div className='sfpuMain inner'>
                    <span className='text-sm medium gray-700'>How many credits do you want to purchase?</span>
                    <input className={`textInput text-md gray-900`} type="number" placeholder={"Enter file name"} value={amount} onChange={(e) => setAmount(e.target.value)} disabled={loading}/>
                </div>} */}


        <div className='column width100 gap12'>
          <div className='row jc-between width100'>
            <span className='text-md semibold gray-600'>{`${option.credits} x £${option.rate}`}</span>
            <span className='text-md regular gray-600'>{`£${(option.credits * option.rate).toFixed(2)}`}</span>
          </div>
          <div className='row jc-between width100'>
            <span className='text-md regular gray-600'>VAT</span>
            <span className='text-md regular gray-600'>{`£${(option.credits * option.rate * .2).toFixed(2)}`}</span>
          </div>

          <div className='greyLine' />

          <div className='row jc-between width100'>
            <span className='text-md semibold gray-600'>Total</span>
            <span className='text-md semibold gray-600'>{`£${(option.credits * option.rate + (option.credits * option.rate * .2)).toFixed(2)}`}</span>
          </div>

          <div className='greyLine' />

        </div>

        <div className='confirmPayment'>
          <div className='cptCardProviderImg'>
            {paymentMethod && <img src={providerImage} alt='cardProvider' />}
          </div>
          <div className='cptCardDetails'>
            {paymentMethod && <span className='text-sm medium gray-700'>{`${provider} ending in ${paymentMethod.card.last4}`}</span>}
            {paymentMethod && <span className='text-sm regular gray-600'>{`Expiry ${paymentMethod.card.exp_month}/${paymentMethod.card.exp_year}`}</span>}
            {!paymentMethod && <span className='text-sm medium gray-700'>No payment method added</span>}
            <span className='text-sm semibold primary-700 pointer' onClick={editPayment}>Edit</span>
          </div>
        </div>

        {intentID && options &&
          <Elements stripe={stripeLoaded} options={options} >
            <PaymentComponent stripeResponse={stripeResponse} submitCount={submitCount} payment={true} intentID={intentID} savedCard={paymentMethod?.id} />
          </Elements>
        }

        {loading ? <div className='loader'>
          <Lottie
            animationData={animationData}
            loop={true}
            autoplay={true}
            style={{ transform: 'scale(0.7)' }}
          />
        </div> : <div className='row width100 jc-centre gap20'>
          <button className='secondaryButton width50' onClick={cancelClicked}>Cancel</button>
          <button className='primaryButton width50' onClick={createPaymentIntent}>Buy Now</button>
        </div>
        }
      </div>
    </div>
  )
}

export default BuyCreditsPopUp;