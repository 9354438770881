import { Dialog, DialogContent, DialogHeader, DialogTitle } from "components/shadcn/ui/dialog"
import { Button } from "components/shadcn/ui/button"
import SvgCloseIcon from "./CloseIcon"
import lang from "lang"
import DiscardModalIcon from "components/common/iconComponents/discardModalIcon"

interface CampaignModalProps {
    isOpen: boolean,
    onClose: () => void,
    onYes: () => void
}

export default function DiscardCampaignSetup({ isOpen, onClose, onYes }: CampaignModalProps) {
    const { campaign: campaignCopy } = lang;

    const handleSave = () => {
        onYes();
    }

    return (
        <div className="flex z-999 rounded-[32px]">
            <Dialog open={isOpen} onOpenChange={onClose}>
                <DialogContent className="sm:max-w-md">
                    <div className="flex flex-row align-center justify-between">
                        <DiscardModalIcon />
                        <button onClick={onClose}><SvgCloseIcon /></button>
                    </div>
                    <DialogHeader className="flex flex-row items-center justify-between">
                        <DialogTitle className="text-lg text-gray-950">{campaignCopy.discardModalHeader}</DialogTitle>
                    </DialogHeader>
                    <div className="text-gray-900 text-sm">{campaignCopy.discradModalSubtext}</div>
                    <div className="flex justify-end gap-4">
                        <Button variant="outline" onClick={onClose}>
                            {campaignCopy.cancel}
                        </Button>
                        <Button onClick={handleSave} variant="primary" className="bg-[#E6007A] hover:bg-[#E6007A]/90 text-white">
                            {campaignCopy.save}
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    )
}
