import "../../../../styling/search.css";
import React, { useEffect, useState } from "react";
import SICFilter from "../../Advanced/FilterTypes/SICFilter";
import AIIcon from "components/constants/AiIcon";
import MultipleTextFilter from "components/Search/Advanced/FilterTypes/MultipleTextFilter";
import { Mixpanel } from "components/Mixpanel/Mixpanel";
import { auth } from "index";

interface IndustrySearchProps {
  appliedFilters: any;
  applyFilter: any;
  remove: any;
  done: any;
}

const IndustrySearch: React.FC<IndustrySearchProps> = ({
  appliedFilters,
  applyFilter,
  remove,
  done,
}) => {
  // #region CONSTANTS & STATE VARIABLES
  const [searchType, setSearchType] = useState("sic");
  let user = JSON.parse(localStorage.getItem("user") || "{}");
  const isAiSearchEnabled = user?.features?.canSearchByAi;

  // #endregion

  // #region SHOW COMPONENTS

  // #endregion

  // #region WEB REQUESTS

  // #endregion

  // #region BUTTONS CLICKED

  // #endregion

  // #region OTHER

  // #endregion

  const clear = () => {
    remove(filter);
    remove(filter2);
    remove(filter3);
  };

  let filter = {
    id: "2C",
    title: "SIC Industry name",
    type: "multiple",
    subType: "sic",
    placeholder: "Enter SIC Code",
  };

  let filter2: any = {
    id: "2C2",
    title: "ISIC Industry name",
    type: "multiple",
    subType: "sic",
    placeholder: "Enter ISIC Code",
  };

  let filter3: any = {
    id: "2I",
    title: "KeyWord",
    type: "multiple",
    placeholder: "Search by company keywords",
    subType: "text",
  };

  useEffect(() => {
    Mixpanel.track("Feature Usage", {
      $name: auth.currentUser?.displayName,
      $email: auth.currentUser?.email,
      "User Type": "Buyer",
      "User ID": auth.currentUser?.uid,
      "Date": new Date().toISOString(),
      "Selected Filter": "Industry",
      "Selected Filter Type": searchType === "sic" ? "SIC and ISIC Code" : "AI",
    });
  }, [searchType]);

  return (
    <div className="searchBubble industry !overflow-y-visible">
      <div className="searchBubbleTitleRow">
        {/* <span className="text-sm medium gray-700">Industry & Keywords</span> */}
        <span className="text-sm medium gray-700">Industry</span>
        <span className="text-sm medium primary-700 pointer" onClick={clear}>
          Clear
        </span>
      </div>

      {isAiSearchEnabled && <div className="row gap20 ai-centre">
        <div className="radioRow">
          <input
            className="radio input-sm"
            type="radio"
            checked={searchType === "sic"}
            onChange={() => setSearchType("sic")}
          />
          <span className="text-sm medium gray-700">{"SIC & ISIC Code"}</span>
        </div>
        <div className="radioRow">
          <input
            className="radio input-sm"
            type="radio"
            checked={searchType === "ai"}
            onChange={() => setSearchType("ai")}
          />
          <AIIcon />
          <span
            className="text-sm medium"
            style={{
              background: `var(--bizcrunch-gradient)`,
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            {"AI Company Search"}
          </span>
        </div>
      </div>
      }

      {searchType === "sic" && (
        <SICFilter
          filter={filter}
          addedFilters={appliedFilters}
          apply={applyFilter}
          clear={() => remove(filter)}
        />
      )}

      {isAiSearchEnabled && searchType === "sic" && (
        <SICFilter
          filter={filter2}
          addedFilters={appliedFilters}
          apply={applyFilter}
          clear={() => remove(filter2)}
          isic={true}
        />
      )}

      {searchType === "ai" && (
        <>
          <MultipleTextFilter
            filter={filter3}
            addedFilters={appliedFilters}
            apply={applyFilter}
            clear={() => remove(filter3)}
            inIndustry={true}
          />
          <div className="text-sm font-normal text-gray-600 text-left">
            Our AI company search pinpoints the exact businesses you need, scouring company websites to find precisely the businesses that matter to you.
          </div>
        </>

      )}

      <div className="loginForgotRow margin24">
        <button onClick={done} className="text-sm medium gray-700">
          Done
        </button>
      </div>
    </div>
  );
}
export default IndustrySearch; 