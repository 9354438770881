import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ParentContext } from './components/constants/ParentContext';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { persistor, store } from 'components/common/store/store';
import { PersistGate } from 'redux-persist/integration/react';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);
let params = new URL(document.location.href).searchParams;
export { analytics, auth };

let isHarbour;
if (localStorage.getItem('isHarbourClub')) {
  isHarbour = localStorage.getItem('isHarbourClub') === 'true' ? true : false;
}
isHarbour = params.get('via') === 'harbour' ? true : false;

let isBiz4Biz;
if (localStorage.getItem('isBiz4Biz')) {
  isBiz4Biz = localStorage.getItem('isBiz4Biz') === 'true' ? true : false;
}
isBiz4Biz = params.get('via') === 'biz4biz' ? true : false;

//Dynamically load Google Maps API script with the API key
const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const script = document.createElement('script');
script.src = `https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&libraries=places`;
script.async = true;
script.defer = true;
document.head.appendChild(script);

// if(window.location.ancestorOrigins.length>0 && !isHarbour)
// {
//   if(window.location.ancestorOrigins[0].includes("https://vcml-7b6cd--bizcrunch-jv3mal71.web.app") || window.location.ancestorOrigins[0].includes("https://harbourclub.vip"))
//   {
//     isHarbour = true
//   }
// }

// const isBiz = !isHarbour;

const rootElement = document.getElementById('root');
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ParentContext.Provider value={{ isHarbour, isBiz4Biz }}>
            <App />
          </ParentContext.Provider>
        </PersistGate>
      </Provider>
    </React.StrictMode>,
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
