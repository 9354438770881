import React from 'react';
import { Table, TableBody, TableCell, TableHeader, TableRow } from 'components/shadcn/ui/table';
import { Checkbox } from 'components/shadcn/ui/checkbox';
import { useReactTable, flexRender, getCoreRowModel, getSortedRowModel } from '@tanstack/react-table';
import SvgChevronSelectorIcon from 'components/common/iconComponents/chevronSelectorIcon';
import { useSelectCollection } from './useSelectCollection';
import lang from 'lang';

const SelectCollection: React.FC = () => {
    const { campaign: campaignCopy } = lang;

    const {
        isAllSelected,
        selectedCollectionIds,
        toggleSelectAll,
        toggleRowSelection,
        sorting,
        setSorting,
        columns,
        data
    } = useSelectCollection();

    const table = useReactTable({
        data,
        columns,
        state: { sorting },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        enableRowSelection: true,
        getRowId: (row: any) => row.id,
    });

    return (
        <div className="border border-gray-200 rounded-[12px] max-h-[100%] overflow-y-auto w-full flex gap-5 flex-col">
            <div className="display-sm semibold text-gray-900 text-left">{campaignCopy.selectCollection}</div>
            <Table className="border-separate border-spacing-0 w-full bg-white">
                <TableHeader>
                    <TableRow>
                        <TableCell className="border-b border-gray-200">
                            <Checkbox
                                checked={isAllSelected}
                                onCheckedChange={(checked: any) => toggleSelectAll(!!checked)}
                                aria-label="Select all collections"
                            />
                        </TableCell>
                        {table.getHeaderGroups().map(headerGroup => (
                            <React.Fragment key={headerGroup.id}>
                                {headerGroup.headers.map((header) => (
                                    <TableCell
                                        key={header.id}
                                        className="cursor-pointer border-b border-gray-200"
                                        onClick={header.column.getToggleSortingHandler()}
                                    >
                                        <div className="flex flex-row gap-2 items-center text-sm font-medium text-gray-600">
                                            {flexRender(header.column.columnDef.header, header.getContext())}
                                            {header.column.getIsSorted() ? (
                                                header.column.getIsSorted() === "asc" ? (
                                                    <SvgChevronSelectorIcon />
                                                ) : (
                                                    <SvgChevronSelectorIcon />
                                                )
                                            ) : (
                                                <SvgChevronSelectorIcon />
                                            )}
                                        </div>
                                    </TableCell>
                                ))}
                            </React.Fragment>
                        ))}
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {table.getRowModel().rows.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell className="border-b border-gray-200">
                                <Checkbox
                                    checked={selectedCollectionIds?.includes(row.id)}
                                    onCheckedChange={() => toggleRowSelection(row.id)}
                                    aria-label={`Select ${row.original.collection}`}
                                />
                            </TableCell>
                            {row.getVisibleCells().map((cell) => (
                                <TableCell key={cell.id} className="border-b border-gray-200 text-left">
                                    {cell.renderValue() as string}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
};

export default SelectCollection;
