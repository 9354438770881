import SvgCollectionExportedIcon from "components/common/iconComponents/CollectionExportedIcon";
import { Card } from "components/shadcn/ui/card";
import useCampaignSummary from "./useSummary";
import lang from "lang";

const CampaignSummary: React.FC = () => {
    const { campaign: campaignCopy } = lang;
    const { summaryResponse } = useCampaignSummary();

    return (
        <div className="max-w-[500px] mx-auto p-6 text-left flex flex-col gap-6">
            <div className="flex justify-between items-center mb-4">
                <div>
                    <div className="text-xl font-semibold">{campaignCopy.campaignSummary}</div>
                    <p className="text-sm text-muted-foreground">{campaignCopy.campaignSummarySubtext}</p>
                </div>
                <button className="text-primary-700 text-sm hover:underline">{campaignCopy.aboutCredits}</button>
            </div>

            <Card className="bg-primary-100 border-0 p-6 rounded-[8px]">
                <div className="flex gap-4 items-center">
                    <SvgCollectionExportedIcon />
                    <div>
                        <div className="font-semibold text-gray-950">{campaignCopy.warmingUp}</div>
                        <div className="text-sm text-gray-950">
                            {campaignCopy.warmingUpSubtext}
                        </div>
                    </div>
                </div>
            </Card>

            <div className="flex justify-between items-center p-6 border border-gray-200 h[80px] bg-[white]">
                <span className="text-sm text-gray-600">{campaignCopy.emailsToSend}</span>
                <span className="font-semibold">{summaryResponse.emailsToSend}</span>
            </div>
            <div className="">
                {/* <div className="flex justify-between items-center border-b p-6 border border-gray-200 h[80px] bg-[white]">
                    <span className="text-sm text-gray-600">Total credits to use:</span>
                    <span className="font-medium text-primary-700">{summaryResponse.creditsBalance}</span>
                </div> */}

                <div className="flex justify-between items-center  p-6 border border-gray-200 h[80px] bg-[white]">
                    <span className="text-sm text-gray-600">{campaignCopy.creditsBalance}</span>
                    <span className="font-semibold">{summaryResponse.creditsBalance}</span>
                </div>

                {/* <div className="flex justify-between items-center border-b p-6 border border-gray-200 h[80px] bg-[white]">
                    <span className="text-sm text-gray-600">Credits left</span>
                    <span className="font-regular text-gray-700">{creditsLeft}</span>
                </div> */}
            </div>
        </div>
    );
};

export default CampaignSummary;
