import React, { useEffect, useState } from "react";
import SavedFilterRow from "../components/SavedFilterRow";
import LoadingPopUp from "../components/popUps/LoadingPopUp";
import SvgFolderIcon from "components/common/iconComponents/FolderIcon";
import SvgFilterIcon from "components/common/iconComponents/FilterIcon";
import { Button } from "components/shadcn/ui/button";
import { useNavigate } from "react-router-dom";
import { selectCollections, setCollections } from "components/collection/collectionSlice";
import { useDispatch, useSelector } from "react-redux";
import axiosWithToken from "axiosWithToken";
import { showOutreachFeature } from "components/utils/network/endpoints";
import { resetCampaignState } from "components/Campaign/campaignSlice";

interface SavedFiltersProps {
  isExports?: boolean;
  isFilters?: boolean;
  updateCredits?: any;
  creditsP?: number;
  hasData?: any;
  isCollection?: boolean;
}

const SavedFilters: React.FC<SavedFiltersProps> = ({
  isExports,
  isFilters,
  updateCredits,
  creditsP,
  hasData,
  isCollection,
}) => {
  const baseURL2 = process.env.REACT_APP_BASEURL2;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const collections = useSelector(selectCollections);

  const [savedFilters, setSavedFilters] = useState([]);
  const [updateVar, setUpdateVar] = useState(0);
  const [loading, setLoading] = useState(true);
  const [collectionData, setCollectionData] = useState<any>([]);

  useEffect(() => {
    if (isCollection) {
      axiosWithToken
        .get(baseURL2 + "api/collections")
        .then((response) => {
          const collectionsData = response.data;
          dispatch(setCollections(collectionsData));
          setCollectionData(collectionsData);
        })
        .catch((error) => {
          console.error("Error fetching collections data:", error);
        });
    }
    // eslint-disable-line react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCollection]);

  const showSaved = () => {
    let compArray: any = [];
    if (isCollection) {
      collections?.forEach((element: any) => {
        compArray.push(
          <SavedFilterRow
            savedFilterP={element}
            deleteFilter={deleteFilter}
            isExports={isExports}
            updateCredits={updateCredits}
            creditsP={creditsP}
            key={element.id}
            collection={element}
            isCollection={isCollection}
            isFilters={isFilters}
          />
        );
      });
    } else {
      savedFilters?.forEach((element: any) => {
        compArray.push(
          <SavedFilterRow
            savedFilterP={element}
            deleteFilter={deleteFilter}
            isExports={isExports}
            updateCredits={updateCredits}
            creditsP={creditsP}
            key={element.id}
            collection={collectionData}
            isCollection={isCollection}
            isFilters={isFilters}
          />
        );
      });
    }
    return compArray;
  };

  // #endregion

  // #region WEB REQUESTS

  const getAccountDetails = async () => {
    try {
      const response = await axiosWithToken.get(`${baseURL2}api/auth/account-dashboard-details`);
      const data = response.data;
      setSavedFilters(data.email);
    } catch (error) {
      console.error('Error fetching account details:', error);
    }
  };


  const deleteFilter = (id: any) => {
    setSavedFilters(savedFilters.filter((x: any) => x.id !== id));
    setUpdateVar(updateVar + 1);
  };

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("user") || "null");

    if (user === null || user === undefined) {
      getAccountDetails();
    } else {
      if (isExports) {
        if (user.exports) {
          setSavedFilters(user.exports);
        } else {
          setSavedFilters([]);
        }
      }
      if (isFilters) {
        setSavedFilters(user.savedFilters || []);
      }
      setLoading(false);
    }
  }, [hasData]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSearchNavigation = () => {
    navigate(`../`, { replace: true });
  };

  const handleCampaignNavigation = () => {
    dispatch(resetCampaignState());
    navigate(`../campaign`, { replace: true });
  }

  return (
    <div className="fullScreen">
      <div className="container max-w-[80%]">
        <div className="savedFiltersScreen">
          <div className="sfTitles flex flex-row items-center justify-between">
            <div className="flex flex-col items-start">
              <span className="display-sm semibold gray-900">
                {isExports ? (showOutreachFeature ? "Collections" : "Exports") : "Saved Filters"}
              </span>
              <span className="text-md regular gray-600">
                {isExports
                  ? showOutreachFeature
                    ? ""
                    : "Past exported companies"
                  : "Track companies or perform new searches based on your saved filter criteria."}
              </span>
            </div>
            {showOutreachFeature && !isFilters && collections.length > 0 && (
              <Button variant="primary" onClick={handleCampaignNavigation}>
                <div className="text-white font-inter text-sm font-semibold leading-5 text-left underline-from-font">
                  New Campaign
                </div>
              </Button>
            )}
          </div>

          {isCollection ? (
            <div className={`flex items-center flex-col gap-8 w-full min-h-[50vh] ${collections.length === 0 ? "justify-center" : ""}`}>
              {collections.length === 0 ? (
                <>
                  <SvgFolderIcon />
                  <div className="flex flex-col gap-2">
                    <p className="text-lg font-semibold font-[InstrumentSans] text-center leading-[28px]">
                      No Collections yet
                    </p>
                    <p className="text-sm font-normal font-[Inter] text-center leading-[20px] decoration-none decoration-[from-font]">
                      Use search to find and collect interesting companies. You can then use your collection to run outreach campaigns.
                    </p>
                  </div>
                  <Button variant="primary" onClick={handleSearchNavigation}>
                    <div className="font-inter text-sm font-semibold leading-5 text-left text-white">Go to Search</div>
                  </Button>
                </>
              ) : (
                showSaved()
              )}
            </div>
          ) : (
            <div className="w-full">
              {savedFilters.length > 0 ? (
                showSaved()
              ) : (
                <div className="flex items-center justify-center flex-col gap-8 w-full min-h-[50vh]">
                  {isExports ? <SvgFolderIcon /> : <SvgFilterIcon />}
                  <div className="flex flex-col gap-2">
                    <p className="text-lg font-semibold font-[InstrumentSans] text-center leading-[28px]">
                      {isExports ? "No exports yet" : "No saved filters yet"}
                    </p>
                    <p className="text-sm font-normal font-[Inter] text-center leading-[20px] decoration-none decoration-[from-font]">
                      {isExports ? "Export companies first" : "Save filters first"}
                    </p>
                  </div>
                  <Button
                    onClick={handleSearchNavigation}
                    variant="primary"
                    className="rounded-[32px] text-lg font-normal text-gray-600 bg-transparent border border-gray-300 px-4 py-2 gap-2 flex items-center"
                  >
                    <div className="font-inter text-sm font-semibold leading-5 text-left text-white">Go to Search</div>
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {loading && <LoadingPopUp />}
    </div>
  );
};

export default SavedFilters;