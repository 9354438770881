import * as React from "react"
import {
    type ColumnDef,
    flexRender,
    getCoreRowModel,
    useReactTable,
    getSortedRowModel,
    type SortingState,
} from "@tanstack/react-table"
import { Button } from "components/shadcn/ui/button"
import { Badge } from "components/shadcn/ui/badge"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "components/shadcn/ui/table"
import { ArrowUp, ArrowDown, ChevronsUpDown } from "lucide-react"
import { CampaignList } from "./types"
import SvgPencilIcon from "components/common/iconComponents/pencilIcon"
import { formatDate } from "helpers"

type CampaignsTableProps = {
    campaignList: CampaignList[];
};
export function CampaignsTable({ campaignList }: CampaignsTableProps) {
    const [sorting, setSorting] = React.useState<SortingState>([])
    const data = campaignList;

    const columns: ColumnDef<CampaignList>[] = [
        {
            accessorKey: "name",
            header: ({ column }) => {
                return (
                    <Button
                        variant="ghost"
                        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                        className="flex items-center pl-0"
                    >
                        Name
                        {column.getIsSorted() === "asc" ? (
                            <ArrowUp className="ml-2 h-4 w-4" />
                        ) : column.getIsSorted() === "desc" ? (
                            <ArrowDown className="ml-2 h-4 w-4" />
                        ) : (
                            <ChevronsUpDown className="ml-2 h-4 w-4" />
                        )}
                    </Button>
                )
            },
        },
        {
            accessorKey: "status",
            header: "Status",
            cell: ({ row }) => {
                const status = row.getValue("status") as string
                return (
                    <Badge
                        variant="secondary"
                        className={`flex flex-row gap-1 w-[75%] ${status === "sent"
                            ? "bg-green-100 text-green-800"
                            : status === "draft"
                                ? "bg-blue-100 text-blue-800"
                                : "bg-pink-100 text-pink-800"
                            }`}
                    >
                        <SvgPencilIcon />
                        {status === "sent" ? "Sent" : status === "draft" ? "Draft" : "Warming up"}
                    </Badge>
                )
            },
        },
        {
            accessorKey: "recipients",
            header: "Recipients",
        },
        {
            accessorKey: "type",
            header: "Type",
            cell: ({ row }) => {
                const type = row.getValue("type") as string
                return (
                    type.charAt(0).toUpperCase() + type.slice(1)
                )
            },
        },
        {
            accessorKey: "replies",
            header: "Replies",
            cell: ({ row }) => {
                const replies = row.getValue("replies") as string
                return (
                    <Badge
                        variant="secondary"
                        className={replies?.includes("No") ? "bg-red-100 text-red-800" : "bg-green-100 text-green-800"}
                    >
                        {replies}
                    </Badge>
                )
            },
        },
        {
            accessorKey: "createdAt",
            header: "Date",
            cell: ({ row }) => {
                const date = row.getValue("createdAt") as string
                return (<div> {formatDate(date)}</div>)
            },
        },
        {
            accessorKey: "actions",
            header: "Actions",
            cell: ({ row }) => {
                const status = row.getValue("status") as string
                return (
                    <Button variant="ghost" className={"text-sm semibold text-primary-700"}>
                        {status === 'draft' ? 'Remove' : 'Duplicate'}
                    </Button>
                )
            },
        },
    ]

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        onSortingChange: setSorting,
        getSortedRowModel: getSortedRowModel(),
        state: {
            sorting,
        },
    })

    return (
        <div className="space-y-4 w-full">
            <div className="rounded-md border">
                <Table>
                    <TableHeader>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <TableRow key={headerGroup.id}>
                                {headerGroup.headers.map((header) => (
                                    <TableHead key={header.id} className="text-sm text-gray-600">
                                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                                    </TableHead>
                                ))}
                            </TableRow>
                        ))}
                    </TableHeader>
                    <TableBody>
                        {table.getRowModel().rows?.length ? (
                            table.getRowModel().rows.map((row) => (
                                <TableRow key={row.id} data-state={row.getIsSelected() && "selected"} className="text-left">
                                    {row.getVisibleCells().map((cell) => (
                                        <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                                    ))}
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={columns.length} className="h-24 text-center">
                                    No results.
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </div>
        </div >
    )
}

