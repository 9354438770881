export const columnDataForCollectionDetails = [
  {
    accessorKey: "companyName",
    header: "Company",
  },
  {
    accessorKey: "industry",
    header: "Industry",
  },
  {
    accessorKey: "revenue",
    header: "Revenue",
  },
  {
    accessorKey: "netProfit",
    header: "Net Profit",
  },
  {
    accessorKey: "netProfitPercentage",
    header: "Net Profit %",
  },
  {
    accessorKey: "debtRatio",
    header: "Debt Ratio",
  },
];
