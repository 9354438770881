import * as Yup from "yup";
export const validationSchema = Yup.object({
  holdingCompany: Yup.string().required("Company name is required"),
  phoneNumber: Yup.string()
    .required("Phone number is required")
    .matches(/^(\+?\d{1,2}[-\s]?)?(\d{10})$/, "Phone number must be valid"),
  website: Yup.string()
    .required("Website is required")
    .matches(
      /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/.*)?$/,
      "Please enter a valid website URL"
    ),
});
