
import * as React from 'react';
import { SVGProps } from 'react';
const SvgDownloadIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.8334 4.3938C23.3205 6.41102 25.6667 10.1814 25.6667 14.4997C25.6667 20.943 20.4434 26.1663 14 26.1663C7.55672 26.1663 2.33337 20.943 2.33337 14.4997C2.33337 10.1814 4.67953 6.41102 8.16671 4.3938M9.33337 14.4997L14 19.1663M14 19.1663L18.6667 14.4997M14 19.1663V2.83301" stroke="#344054" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>



);
export default SvgDownloadIcon;
