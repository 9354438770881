export default function AIIcon() {
  const primary700 = getComputedStyle(document.body).getPropertyValue(
    "--primary-700"
  );
  const primary500 = getComputedStyle(document.body).getPropertyValue(
    "--primary-500"
  );
  const primary400 = getComputedStyle(document.body).getPropertyValue(
    "--primary-400"
  );

  return (
    <svg
      width="15"
      height="13"
      viewBox="0 0 15 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.66667 0L8.82279 3.00591C9.01079 3.49473 9.1048 3.73914 9.25098 3.94472C9.38054 4.12693 9.53974 4.28613 9.72194 4.41569C9.92753 4.56187 10.1719 4.65587 10.6608 4.84388L13.6667 6L10.6608 7.15612C10.1719 7.34413 9.92753 7.43813 9.72194 7.58431C9.53974 7.71387 9.38054 7.87307 9.25098 8.05528C9.1048 8.26086 9.01079 8.50527 8.82279 8.99409L7.66667 12L6.51055 8.99409C6.32254 8.50527 6.22854 8.26086 6.08235 8.05528C5.95279 7.87307 5.7936 7.71387 5.61139 7.58431C5.40581 7.43813 5.16139 7.34413 4.67257 7.15612L1.66667 6L4.67257 4.84388C5.16139 4.65587 5.4058 4.56187 5.61139 4.41569C5.7936 4.28613 5.95279 4.12693 6.08235 3.94472C6.22854 3.73914 6.32254 3.49473 6.51055 3.00591L7.66667 0Z"
        fill="url(#bizcrunch_linear_gradient)"
      />
      <path
        d="M13 0L13.3486 0.697297C13.4666 0.933287 13.5256 1.05128 13.6045 1.15353C13.6744 1.24426 13.7557 1.3256 13.8465 1.39554C13.9487 1.47436 14.0667 1.53336 14.3027 1.65135L15 2L14.3027 2.34865C14.0667 2.46664 13.9487 2.52564 13.8465 2.60446C13.7557 2.6744 13.6744 2.75574 13.6045 2.84647C13.5256 2.94872 13.4666 3.06671 13.3486 3.3027L13 4L12.6514 3.3027C12.5334 3.06671 12.4744 2.94872 12.3955 2.84647C12.3256 2.75574 12.2443 2.6744 12.1535 2.60446C12.0513 2.52564 11.9333 2.46664 11.6973 2.34865L11 2L11.6973 1.65135C11.9333 1.53336 12.0513 1.47436 12.1535 1.39554C12.2443 1.3256 12.3256 1.24426 12.3955 1.15353C12.4744 1.05128 12.5334 0.933287 12.6514 0.697296L13 0Z"
        fill="url(#bizcrunch_linear_gradient)"
      />
      <path
        d="M2.5 8L2.93581 8.87162C3.0833 9.16661 3.15705 9.3141 3.25557 9.44192C3.343 9.55533 3.44467 9.657 3.55808 9.74443C3.6859 9.84295 3.83339 9.9167 4.12838 10.0642L5 10.5L4.12838 10.9358C3.83339 11.0833 3.6859 11.1571 3.55809 11.2556C3.44467 11.343 3.343 11.4447 3.25557 11.5581C3.15705 11.6859 3.0833 11.8334 2.93581 12.1284L2.5 13L2.06419 12.1284C1.9167 11.8334 1.84295 11.6859 1.74443 11.5581C1.657 11.4447 1.55533 11.343 1.44192 11.2556C1.3141 11.1571 1.16661 11.0833 0.87162 10.9358L0 10.5L0.87162 10.0642C1.16661 9.9167 1.3141 9.84295 1.44191 9.74443C1.55533 9.657 1.657 9.55533 1.74443 9.44192C1.84295 9.3141 1.9167 9.16661 2.06419 8.87162L2.5 8Z"
        fill="url(#bizcrunch_linear_gradient)"
      />
      <path
        d="M2 0L2.34865 0.697297C2.46664 0.933287 2.52564 1.05128 2.60446 1.15353C2.6744 1.24426 2.75574 1.3256 2.84647 1.39554C2.94872 1.47436 3.06671 1.53336 3.3027 1.65135L4 2L3.3027 2.34865C3.06671 2.46664 2.94872 2.52564 2.84647 2.60446C2.75574 2.6744 2.6744 2.75574 2.60446 2.84647C2.52564 2.94872 2.46664 3.06671 2.34865 3.3027L2 4L1.65135 3.3027C1.53336 3.06671 1.47436 2.94872 1.39554 2.84647C1.3256 2.75574 1.24426 2.6744 1.15353 2.60446C1.05128 2.52564 0.933287 2.46664 0.697296 2.34865L0 2L0.697296 1.65135C0.933287 1.53336 1.05128 1.47436 1.15353 1.39554C1.24426 1.3256 1.3256 1.24426 1.39554 1.15353C1.47436 1.05128 1.53336 0.933287 1.65135 0.697296L2 0Z"
        fill="url(#bizcrunch_linear_gradient)"
      />
      <defs>
        <linearGradient
          id="bizcrunch_linear_gradient"
          x1="14.9996"
          y1="4.875"
          x2="3.65516"
          y2="-9.13988"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={primary700} />
          <stop offset="0.505208" stopColor={primary500} />
          <stop offset="1" stopColor={primary400} />
        </linearGradient>
      </defs>
    </svg>
  );
}
