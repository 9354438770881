import React, { useState, useEffect, useRef, useCallback } from "react";
import { Button } from "components/shadcn/ui/button";
import { Input } from "components/shadcn/ui/input";
import { Card, CardContent } from "components/shadcn/ui/card";
import {
    Bold,
    Italic,
    Underline,
    List,
    ListOrdered,
    AlignLeft,
    AlignCenter,
    AlignRight,
    Link,
    Type,
} from "lucide-react";
import { EmailTemplate, useEmailTemplates } from "./useEditMessage";
import lang from "lang";

const EmailEditor: React.FC = () => {
    const { campaign: campaignCopy } = lang;
    const { emails, updateEmail } = useEmailTemplates();
    const [activeEmailIndex, setActiveEmailIndex] = useState(0);
    const editorRef = useRef<HTMLDivElement>(null);
    const [editorContent, setEditorContent] = useState("");

    useEffect(() => {
        const observer = new MutationObserver(() => {
            if (editorRef.current) {
                setEditorContent(editorRef.current.innerHTML);
            }
        });

        if (editorRef.current) {
            observer.observe(editorRef.current, {
                childList: true,
                characterData: true,
                subtree: true,
            });
        }

        return () => observer.disconnect();
    }, []);

    const getTimingText = (sendAfterDays: number) => {
        if (sendAfterDays === 0) return "Send instantly";
        if (sendAfterDays === 7) return "Week later";
        if (sendAfterDays === 14) return "Two weeks later";
        return `Send after ${sendAfterDays} days`;
    };

    const executeCommand = (command: string, value: string | undefined = undefined) => {
        document.execCommand(command, false, value);
        updateEditorContent();
    };

    const highlightText = () => {
        const selection = window.getSelection();
        if (selection && selection.rangeCount > 0) {
            const range = selection.getRangeAt(0);
            const span = document.createElement("span");
            span.className = "highlighted";
            span.textContent = `{${range.toString()}}`;
            range.deleteContents();
            range.insertNode(span);
            updateEditorContent();
        }
    };

    const saveCursor = () => {
        const selection = window.getSelection();
        if (!selection || selection.rangeCount === 0) return null;

        const range = selection.getRangeAt(0);
        return { node: range.startContainer, offset: range.startOffset };
    };

    const restoreCursor = (savedRange: { node: Node; offset: number } | null) => {
        if (!savedRange) return;

        const selection = window.getSelection();
        if (!selection) return;

        const range = document.createRange();
        range.setStart(savedRange.node, Math.min(savedRange.offset, savedRange.node.textContent?.length || 0));
        range.collapse(true);

        selection.removeAllRanges();
        selection.addRange(range);
    };


    const updateEditorContent = () => {
        const savedCursor = saveCursor();
        if (emails[activeEmailIndex]) {
            updateEmail(activeEmailIndex, { body: editorRef.current?.innerHTML || "" });
        }
        setEditorContent(editorRef.current?.innerHTML || "");
        setTimeout(() => restoreCursor(savedCursor), 0);
        // console.log("updateEditorContent", editorRef.current?.innerHTML);
    };

    const handleEditorInput = useCallback(() => {
        updateEditorContent();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeEmailIndex, updateEmail]);

    const processContent = (content: string) => {
        content = content.replace(/\n/g, "<br>");
        content = content.replace(/\{([^}]+)\}/g, '<span class="highlighted">{$1}</span>');
        return content;
    };

    useEffect(() => {
        const processedContent = processContent(emails[activeEmailIndex]?.body || "");
        setEditorContent(processedContent);
    }, [activeEmailIndex, emails]);

    useEffect(() => {
        if (editorRef.current && editorRef.current.innerHTML !== editorContent) {
            editorRef.current.innerHTML = editorContent;
        }
    }, [editorContent]);

    const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
        e.preventDefault();
        const text = e.clipboardData.getData("text/plain");
        const processedText = processContent(text);
        document.execCommand("insertHTML", false, processedText);
        updateEditorContent();
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "{") {
            e.preventDefault();
            document.execCommand("insertHTML", false, '<span class="highlighted">{</span>');
            updateEditorContent();
        } else if (e.key === "}") {
            const selection = window.getSelection();
            if (selection && selection.rangeCount > 0) {
                const range = selection.getRangeAt(0);
                const startNode = range.startContainer;
                if (startNode.parentElement?.classList.contains("highlighted")) {
                    e.preventDefault();
                    document.execCommand("insertHTML", false, "}");
                    updateEditorContent();
                }
            }
        }
    };

    const renderEmailEditor = (email: EmailTemplate, index: number) => (
        <Card className="w-full">
            <CardContent className="p-6">
                <div className="flex justify-between items-center mb-4">
                    <span className="text-lg font-bold text-gray-700">Send email {index + 1}</span>
                    <div className="font-inter text-base font-normal leading-[24px] text-left text-gray-500 underline-from-font decoration-none">
                        {getTimingText(email.sendAfterDays)}
                    </div>
                </div>

                <Input
                    placeholder="Subject line..."
                    value={email.subject}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateEmail(index, { subject: e.target.value })}
                    className="mb-4"
                />

                <div className="border rounded-md">
                    <div className="flex items-center gap-1 border-b p-2">
                        <Button variant="ghost" size="sm" className="p-2 h-8 w-8" onClick={() => executeCommand("bold")}>
                            <Bold className="h-4 w-4" />
                        </Button>
                        <Button variant="ghost" size="sm" className="p-2 h-8 w-8" onClick={() => executeCommand("italic")}>
                            <Italic className="h-4 w-4" />
                        </Button>
                        <Button variant="ghost" size="sm" className="p-2 h-8 w-8" onClick={() => executeCommand("underline")}>
                            <Underline className="h-4 w-4" />
                        </Button>
                        <div className="h-4 w-px bg-gray-200 mx-2" />
                        <Button
                            variant="ghost"
                            size="sm"
                            className="p-2 h-8 w-8"
                            onClick={() => executeCommand("insertUnorderedList")}
                        >
                            <List className="h-4 w-4" />
                        </Button>
                        <Button
                            variant="ghost"
                            size="sm"
                            className="p-2 h-8 w-8"
                            onClick={() => executeCommand("insertOrderedList")}
                        >
                            <ListOrdered className="h-4 w-4" />
                        </Button>
                        <div className="h-4 w-px bg-gray-200 mx-2" />
                        <Button variant="ghost" size="sm" className="p-2 h-8 w-8" onClick={() => executeCommand("justifyLeft")}>
                            <AlignLeft className="h-4 w-4" />
                        </Button>
                        <Button variant="ghost" size="sm" className="p-2 h-8 w-8" onClick={() => executeCommand("justifyCenter")}>
                            <AlignCenter className="h-4 w-4" />
                        </Button>
                        <Button variant="ghost" size="sm" className="p-2 h-8 w-8" onClick={() => executeCommand("justifyRight")}>
                            <AlignRight className="h-4 w-4" />
                        </Button>
                        <div className="h-4 w-px bg-gray-200 mx-2" />
                        <Button
                            variant="ghost"
                            size="sm"
                            className="p-2 h-8 w-8"
                            onClick={() => {
                                const url = prompt("Enter URL:");
                                if (url) executeCommand("createLink", url);
                            }}
                        >
                            <Link className="h-4 w-4" />
                        </Button>
                        <Button variant="ghost" size="sm" className="p-2 h-8 w-8" onClick={highlightText}>
                            <Type className="h-4 w-4" />
                        </Button>
                    </div>
                    <div
                        ref={editorRef}
                        className="p-4 min-h-[200px] focus:outline-none text-left"
                        contentEditable
                        onInput={handleEditorInput}
                        onPaste={handlePaste}
                        onKeyDown={handleKeyDown}
                    />
                </div>
            </CardContent>
        </Card >
    );

    const renderEmailCard = (email: EmailTemplate, index: number) => (
        <Card
            className="w-full max-w-md mx-auto cursor-pointer transition-colors"
            onClick={() => setActiveEmailIndex(index)}
        >
            <CardContent className="p-4">
                <div className="flex items-start flex-col justify-start gap-4">
                    <div className="flex flex-col gap-1 justify-start">
                        <h3 className="font-[InstrumentSans] text-lg font-bold leading-[28px] text-left underline-from-font decoration-none"
                        >{email.name}</h3>
                        <p className="text-md text-gray-500 text-start">{getTimingText(email.sendAfterDays)}</p>
                    </div>
                    <Button variant="ghost" size="sm" className="text-primary-700 hover:backgrond-none" onClick={() => setActiveEmailIndex(index)}>
                        {campaignCopy.edit}
                    </Button>
                </div>
            </CardContent>
        </Card>
    );

    return (
        <div className="w-full">
            <div className="text-xl font-semibold mb-6 text-left">{campaignCopy.reviewAndRefine}</div>

            <div className="space-y-6">
                {emails?.map((email, index) => (
                    <div key={index}>
                        {activeEmailIndex === index ? renderEmailEditor(email, index) : renderEmailCard(email, index)}
                        {index < emails.length - 1 && <div className="w-px h-12 bg-[#89123E] mx-auto" />}
                    </div>
                ))}
            </div>
            <style>{`
        .highlighted {
          color: #C01048;
        }
      `}</style>
        </div>
    );
};

export default EmailEditor;