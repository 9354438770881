import { auth } from '..';
import '../styling/saved.css'
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { SIC_CODES } from './Search/Advanced/FilterTypes/siccodes';
import EditFiltersPopUp from './popUps/EditFilterPopUp';
import Moment from 'moment';
import { formatDate, getFinancialStr, getSICTitle } from './Search/Basic/SearchBar';
import { createExcel } from '../localFunctions/exportSheet';
import ExportUpdatesPopUp from './popUps/ExportUpdatesPopUp';
import { Mixpanel } from './Mixpanel/Mixpanel';

interface SavedFilterRowProps {
    savedFilterP: any;
    deleteFilter?: any;
    isExports: any;
    updateCredits: any;
    creditsP: any;
    collection?: any;
    isCollection?: boolean;
    isFilters?: boolean;
}

const SavedFilterRow: React.FC<SavedFilterRowProps> = ({
    savedFilterP,
    deleteFilter,
    isExports,
    updateCredits,
    creditsP,
    collection,
    isCollection,
    isFilters }) => {

    const navigate = useNavigate();

    const [savedFilter, setSavedFilter] = useState(savedFilterP);
    const [showPopUp, setShowPopUp] = useState(false);
    const [showTools, setShowTools] = useState(false);
    const [showExportPopUp, setShowExportPopUp] = useState(false);
    const primary200 = getComputedStyle(document.body).getPropertyValue('--primary-200');
    const primary500 = getComputedStyle(document.body).getPropertyValue('--primary-500');

    const baseURL2 = process.env.REACT_APP_BASEURL2;

    // #region SHOW COMPONENTS

    const showFilters = () => {

        let compArray: any = []

        var index = 0

        savedFilter?.filters?.forEach((element: any) => {
            if (index < 4) {
                var icon
                var text
                var filter = element

                if (filter.subType === 'location') {
                    icon = '/assets/singleBuilding.png'
                    text = filter.value.locations.join(', ')
                }
                else if (filter.type === 'range') {
                    icon = '/assets/singleBuilding.png'
                    text = getFinancialStrLocal(filter.value.min, "") + '-' + getFinancialStrLocal(filter.value.max, "")
                }
                else if (filter.type === 'slider') {
                    icon = '/assets/singleBuilding.png'
                    text = filter.value.min + '-' + filter.value.max + "%"
                }
                else if (filter.type === 'multiple') {

                    if (filter.id === "2I") {
                        icon = '/assets/singleBuilding.png'
                        text = filter.value.inputs.join(', ')
                    }
                    else if (filter.id === "2A" && filter.subType === 'companyName') {
                        icon = '/assets/singleBuilding.png'
                        text = filter?.value?.names.join(', ')
                    }
                    else if (filter.title === "SIC Industry name" || filter.title === "ISIC Industry name") {
                        icon = '/assets/singleBuilding.png'
                        text = filter.value[0].title
                    }
                    else {
                        icon = '/assets/singleBuilding.png'
                        text = filter.value.join(', ')
                    }
                }

                compArray.push(<div className='sfrFilters item'>
                    <img className='sfrItemIcon' src={icon} alt='filterIcon' />
                    <span className='text-sm regular gray-600 sfrItemText'>{text}</span>
                </div>)
            }
            index++
        });

        compArray.push(
            <div className='seeAllFilters' onClick={() => setShowPopUp(true)}>
                <span className='text-sm medium gray-600'>See all filters</span>
                <img className='safRightArrow' src='/assets/chevron-right.png' alt='rightArrow' />
            </div>
        )

        return compArray

    }

    const showExportTime = () => {

        let compArray: any = []

        if (isExports) {

            compArray.push(
                <div className='sfrTitleColumn time'>
                    <span className='text-xs regular gray-600'>{`Exported on ${Moment(savedFilter.time).format('MMM DD, YYYY')}`}</span>
                    {savedFilter.lastUpdate && <span className='text-xs regular gray-600'>{`Last updated on ${Moment(savedFilter.lastUpdate).format('MMM DD, YYYY')}`}</span>}
                    <div className='sfrUpdatesRow'>
                        {savedFilter.newCompanies && savedFilter.newCompanies.length > 0 &&
                            <div className='sfrUpdateBubble'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                                    <circle cx="6" cy="6.55859" r="4.5" fill={primary500} stroke={primary200} strokeWidth="3" />
                                </svg>
                                <span className='text-xs medium primary-700'>{`${savedFilter.newCompanies.length} New Records`}</span>
                                yert
                            </div>
                        }
                        {savedFilter.updatedCompanies && savedFilter.updatedCompanies.length > 0 &&
                            <div className='sfrUpdateBubble blue'>
                                <span className='text-xs medium blue-700'>{`${savedFilter.updatedCompanies.length} Changes`}</span>
                            </div>
                        }
                    </div>
                </div>
            )

        }

        return compArray

    }



    const getFinancialStrLocal = (turnover: any, turnoverStatus: any) => {

        var str = ""

        if (turnover !== null) {
            str += "£"

            if (turnover >= 1000000 || turnover <= -1000000) {
                let millions = (turnover / 1000000)
                str += millions + "M"
            }
            else {
                str += turnover.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }

            if (turnoverStatus === "Estimated") {
                str = "~" + str
            }

            return str
        }
        else {
            return str
        }
    }



    // #endregion


    // #region WEB REQUESTS



    // #endregion


    // #region BUTTONS CLICKED

    // #endregion


    // #region OTHER

    // #endregion


    useEffect(() => {

        setSavedFilter(savedFilterP)

    }, [savedFilterP.id]); // eslint-disable-line react-hooks/exhaustive-deps


    const goToSearch = () => {
        Mixpanel.track("Load Search", {
            "User ID": auth.currentUser?.uid,
            "Date": new Date().toISOString(),
            $email: auth?.currentUser?.email,
            $name: auth?.currentUser?.displayName,
            "User Type": "Buyer",
        });
        localStorage.setItem("savedFilter", JSON.stringify(savedFilter))
        navigate(`../`, { replace: true });

    }

    const deleteFunc = () => {

        return new Promise(async (resolve, reject) => {

            axios.post(baseURL2 + 'deleteSavedFiltersFromAccount', {

                uid: auth.currentUser?.uid,
                id: savedFilter.id

            }).then((response) => {
                updateLocally();
                deleteFilter(savedFilter.id)
            });
        })
    }


    const updateLocally = () => {

        let user = JSON.parse(localStorage.getItem("user") || '{}')
        user.savedFilters = user.savedFilters.filter((x: any) => x.id !== savedFilter.id)
        localStorage.setItem("user", JSON.stringify(user))
    }

    const redownload = () => {

        Mixpanel.track("FeatureUsage", {
            "User ID": auth.currentUser?.uid,
            "Date": new Date().toISOString(),
            $email: auth?.currentUser?.email,
            $name: auth?.currentUser?.displayName,
            "User Type": "Buyer",
            "Feature": "Redownload Export"
        });

        axios.post(baseURL2 + 'reExportSameBusinesses', {

            companies: savedFilter.companies,
            timestamp: savedFilter.time

        }).then((response) => {

            convertResponseReExport(response.data.companies, savedFilter.name)
        });
    }

    const shareEmail = () => {

    }


    const exported = (optionSelected: number) => {

        if (optionSelected < 3) {
            savedFilter.companies.push(...savedFilter.newCompanies || [])
            savedFilter.newCompanies = []
        }
        if (optionSelected !== 2) {
            savedFilter.companies.push(...savedFilter.updatedCompanies || [])
            savedFilter.updatedCompanies = []
        }

        setSavedFilter(savedFilter)

        setShowExportPopUp(false)

    }

    const handleCollectionClick = (collectionId: string) => {
        navigate(`/collectionDetail`, { state: { collectionId, collection } });
    };

    return (
        <div className="savedFilterRow" style={{ justifyContent: isCollection ? 'space-between' : '' }} onMouseLeave={() => setShowTools(false)}>
            <div className='sfrTitle'>
                <img className='sfrTitleIcon' src={isExports ? '/assets/exportDoc.png' : '/assets/greyBuilding.png'} alt='titleIcon' />
                {!isCollection && <div className='sfrTitleColumn'>
                    <span className='text-sm medium gray-900 alignLeft'>{savedFilter.name}</span>
                    {isExports && <span className='text-sm regular gray-600 sfrItemText'>{(savedFilter.companies ? savedFilter.companies.length : 0) + " Companies"}</span>}
                </div>
                }
                {isCollection && <div className='sfrTitleColumn cursor-pointer' onClick={() => handleCollectionClick(collection.id)}>
                    <span className='text-sm medium gray-900 alignLeft'>{collection.name}</span>
                    {isExports && <span className='text-sm regular gray-600 sfrItemText'>{`${collection.companyCount} Companies`}</span>}
                </div>
                }

            </div>
            {!isCollection && <div className='sfrFilters'>
                {showFilters()}
            </div>
            }
            {!isCollection && (isExports || isFilters) && <div className='row gap8 ai-centre'>
                {showExportTime()}
                {<img className='sfrDots pointer' src='/assets/threeDots.png' onClick={() => setShowTools(true)} alt='threeDots' />}
            </div>
            }

            {showPopUp && <EditFiltersPopUp savedFilter={savedFilter} close={() => setShowPopUp(false)} isExports={isExports} />}

            {(showTools && !isExports) && <div className='searchBubble savedFilterTool'>
                <div className='sftRow' onClick={goToSearch}>
                    <img className='sftIcon' src='/assets/magnifyBlack.png' alt='magnify' />
                    <span className='text-sm medium gray-700'>Load search</span>
                </div>
                <div className='sftRow' onClick={deleteFunc}>
                    <img className='sftIcon' src='/assets/trash.png' alt='trash' />
                    <span className='text-sm medium gray-700'>Delete</span>
                </div>
            </div>}


            {(showTools && isExports) && <div className='searchBubble savedFilterTool export'>
                <div className='sftRow' onClick={redownload}>
                    <img className='sftIcon' src='/assets/magnifyBlack.png' alt='magnify' />
                    <span className='text-sm medium gray-700'>Redownload CSV</span>
                </div>
                <div className='sftRow' onClick={shareEmail}>
                    <svg className='sftIcon' xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                        <path d="M4.66667 7.89193C4.04669 7.89193 3.7367 7.89193 3.48236 7.96008C2.79218 8.14501 2.25308 8.68411 2.06815 9.37429C2 9.62862 2 9.93861 2 10.5586V11.3586C2 12.4787 2 13.0388 2.21799 13.4666C2.40973 13.8429 2.71569 14.1489 3.09202 14.3406C3.51984 14.5586 4.07989 14.5586 5.2 14.5586H10.8C11.9201 14.5586 12.4802 14.5586 12.908 14.3406C13.2843 14.1489 13.5903 13.8429 13.782 13.4666C14 13.0388 14 12.4787 14 11.3586V10.5586C14 9.93861 14 9.62862 13.9319 9.37429C13.7469 8.68411 13.2078 8.14501 12.5176 7.96008C12.2633 7.89193 11.9533 7.89193 11.3333 7.89193M10.6667 5.22526L8 2.55859M8 2.55859L5.33333 5.22526M8 2.55859V10.5586" stroke="#344054" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    <span className='text-sm medium gray-700'>Share via Email</span>
                </div>

            </div>}

            {showExportPopUp && <ExportUpdatesPopUp close={() => setShowExportPopUp(false)} savedExport={savedFilter} updateCredits={updateCredits} creditsP={creditsP} exportSuccessful={exported} />}

        </div>
    )

}



export const convertResponseReExport = (list: any, name: any) => {
    var companyList: any = []
    var PSCList: any = []
    var contactList: any = []
    var financialsList: any = []

    var compNum = ""
    var financialYear = ""
    var addPSCs: any;

    list?.forEach((item: any) => {

        if (item.CompanyNumber !== compNum) {
            compNum = item.CompanyNumber
            financialYear = item.FinancialYear
            addPSCs = true

            companyList.push({
                name: item.CompanyName,
                number: item.CompanyNumber,
                sic: item.SIC,
                sicName: getSICTitle(item.SIC, SIC_CODES)?.title,
                registeredAddress: item.RegisteredAddress,
                tradingAddress: item.TradingAddress,
                website: item.Website,
                incorporation: formatDate(item.IncorporationDate),
                type: item.CompanyType,
                status: item.CompanyStatus,
                amountPSCs: item.AmountPSCs,
                debtRatio: (item.DebtRatio / 100).toFixed(2),
                netProfitPercentage: item.NetProfitPercentage,
                fanpRatio: item.FTARevRatio,// || item.calculatedFinancials.fatr2,
                turnover: item.Turnover ? getFinancialStr(item.Turnover) : null,
                turnoverStatus: item.Estimated ? "Estimated" : "Reported",
                netProfit: item.NetProfit ? getFinancialStr(item.NetProfit) : null,
                currentAssets: item.CurrentAssets ? getFinancialStr(item.CurrentAssets) : null,
                fixedAssets: item.FixedAssets ? getFinancialStr(item.FixedAssets) : null,
                totalAssets: item.TotalAssets ? getFinancialStr(item.TotalAssets) : null,
                currentLiabilities: item.CurrentLiabilities ? getFinancialStr(item.CurrentLiabilities) : null,
                longTermLiabilities: item.LongTermLiabilities ? getFinancialStr(item.LongTermLiabilities) : null,
                netAssets: item.NetAssets ? getFinancialStr(item.NetAssets) : null,
                premium: item.Premium ? "Premium" : "Standard"
            })


            financialsList.push({
                compNumber: item.CompanyNumber,
                compName: item.CompanyName,
                sic: item.SIC,
                sicName: getSICTitle(item.SIC, SIC_CODES)?.title,

                registeredAddress: item.RegisteredAddress,
                tradingAddress: item.TradingAddress,
                website: item.website,
                incorporation: formatDate(item.IncorporationDate),
                type: item.CompanyType,
                status: item.CompanyStatus,
                filingDate: item.FilingDate,
                year: item.FinancialYear,
                debtRatio: item.DebtRatio,
                netProfitPercentage: item.NetProfitPercentage,
                fanpRatio: item.FTARevRatio,// || item.calculatedFinancials.fatr2,
                turnover: item.Turnover,
                turnoverStatus: item.Estimated ? "Estimated" : "Reported",
                netProfit: item.NetProfit,
                currentAssets: item.CurrentAssets,
                fixedAssets: item.FixedAssets,
                totalAssets: item.TotalAssets,
                currentLiabilities: item.CurrentLiabilities,
                longTermLiabilities: item.LongTermLiabilities,
                netAssets: item.NetAssets

            })
        }
        else if (item.FinancialYear !== financialYear) {
            financialYear = item.FinancialYear

            addPSCs = false

            financialsList.push({
                compNumber: item.CompanyNumber,
                compName: item.CompanyName,
                sic: item.SIC,
                sicName: getSICTitle(item.SIC, SIC_CODES)?.title,

                registeredAddress: item.RegisteredAddress,
                tradingAddress: item.TradingAddress,
                website: item.website,
                incorporation: formatDate(item.IncorporationDate),
                type: item.CompanyType,
                status: item.CompanyStatus,
                filingDate: item.FilingDate,
                year: item.FinancialYear,
                debtRatio: item.DebtRatio,
                netProfitPercentage: item.NetProfitPercentage,
                fanpRatio: item.FTARevRatio,// || item.calculatedFinancials.fatr2,
                turnover: item.Turnover,
                turnoverStatus: item.Estimated ? "Estimated" : "Reported",
                netProfit: item.NetProfit,
                currentAssets: item.CurrentAssets,
                fixedAssets: item.FixedAssets,
                totalAssets: item.TotalAssets,
                currentLiabilities: item.CurrentLiabilities,
                longTermLiabilities: item.LongTermLiabilities,
                netAssets: item.NetAssets

            })
        }


        if (addPSCs) {
            contactList.push({
                name: item.CompanyName,
                number: item.CompanyNumber,
                sic: item.SIC,
                sicName: getSICTitle(item.SIC, SIC_CODES)?.title,
                title: item.Title,
                firstName: item.Forename,
                surname: item.Surname,
                email: item.Email,
                linkedIn: item.LinkedIn,
                shareholderType: item.ShareholderType,
                shareholderControl: item.ShareholderPercentage,
                age: 2023 - item.YearDOB
            })

            PSCList.push({
                name: item.CompanyName,
                number: item.CompanyNumber,
                sic: item.SIC,
                sicName: getSICTitle(item.SIC, SIC_CODES)?.title,
                title: item.Title,
                firstName: item.Forename,
                surname: item.Surname,
                addressLine1: item.Address_Line1,
                addressLine2: item.Address_Line2,
                town: item.Address_PostTown,
                county: item.Address_County,
                postCode: item.PostCode,
                country: item.Country,
                shareholderType: item.ShareholderType,
                shareholderControl: item.ShareholderPercentage,
                age: 2023 - item.YearDOB,
                // email:item.Email
            })
        }



    });

    createExcel(name, companyList, contactList, PSCList, financialsList)
}

export default SavedFilterRow;